import { dispatch as storeDispatch } from '@store';
import { actions } from '@store/network';
import {
  GasPriceResponse,
  NetworkResponse,
} from '@store/network/network.types';

import { ApiService } from '@services';

import { ApiError, AsyncDispatch, AxiosResponse } from '@types';

/**
 * Network controller.
 *
 * @author Ihar Kazlouski
 * @const NetworkServiceClass
 * @category Services
 * @subcategory Network
 */
class NetworkServiceClass {
  /**
   * Networks get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<NetworkResponse>} promise.
   */
  async apiNetworksGet (): Promise<ApiError | AxiosResponse<NetworkResponse>> {
    return ApiService.apiGet(`/public/networks`);
  }

  /**
   * Gas price get api function.
   *
   * @author Ilya Khachikyan
   * @return {Promise<AxiosResponse<GasPriceResponse>} promise.
   */
  async apiGasPriceGet (): Promise<AxiosResponse<GasPriceResponse>> {
    return (await ApiService.apiGet(
      `/shared/eth_gas_price`,
    )) as unknown as AxiosResponse<GasPriceResponse>;
  }

  /**
   * Get networks list.
   *
   * @author Ihar Kazlouski
   * @return {Promise<NetworkResponse | ApiError>} networks response promise.
   */
  async getNetworksList (): Promise<NetworkResponse | ApiError> {
    const dispatch: AsyncDispatch<NetworkResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchNetworksRequest());
  }

  /**
   * Set current network.
   *
   * @author Ihar Kazlouski
   * @return {void} void.
   */
  setCurrentNetwork (networkId: string): void {
    storeDispatch(actions.setCurrentNetwork(networkId));
  }
}

const NetworkService = new NetworkServiceClass();

export { NetworkService };
