import {
  Box,
  Menu,
  menuClasses,
  MenuItem,
  MenuProps,
  Stack,
  styled,
} from '@mui/material';

import { isDefined } from '@helpers';

import { SearchBar, SearchBarProps } from '@elements';

export const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => prop !== 'containerWidth',
})<MenuProps & { containerWidth?: number }>(({ theme, containerWidth }) => {
  return {
    [`& .${menuClasses.paper}`]: {
      color:           theme.palette.white.main,
      background:      theme.palette.mirage.main,
      border:          `1px solid ${theme.palette.white10.main}`,
      borderRadius:    theme.spacing(1.5),
      margin:          theme.spacing(0.5, 0, 0.5),
      width:           isDefined(containerWidth) ? `${containerWidth}px` : undefined,
      maxHeight:       '376px',
      msOverflowStyle: 'none',
      scrollbarWidth:  'none',
      padding:         theme.spacing(0.5),

      [`&::-webkit-scrollbar`]: {
        display: 'none',
      },
    },

    [`& .${menuClasses.list}`]: {
      padding: 0,
    },
  };
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  return {
    borderRadius: theme.spacing(1),
    padding:      theme.spacing(1.5),

    '&:hover': {
      background: theme.palette.white8.main,
    },
  };
});

export const StyledPairInfoItem = styled(Stack)(({ theme }) => {
  return {
    padding:      theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.white50.main}`,
  };
});

export const StyledNotFoundContainer = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(5.5, 1.5),
  };
});

export const StyledSearchBar = styled(SearchBar, {
  shouldForwardProp: (prop) => prop !== 'menuOpen',
})<SearchBarProps & { menuOpen: boolean }>(({ theme, menuOpen }) => {
  return {
    zIndex: menuOpen ? theme.zIndex.snackbar : 'unset',
  };
});
