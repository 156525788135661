import { createSelector } from 'reselect';

import { ApiError, Wallet } from '@types';

import { AppState } from '../root.reducer';

/**
 * Get pending.
 *
 * @author Ihar Kazlouski
 * @function getPending
 * @category Selectors
 * @subcategory Wallet
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getPending = (state: AppState): boolean => state.wallet.pending;

/**
 * Get wallets.
 *
 * @author Ihar Kazlouski
 * @function getWallets
 * @category Selectors
 * @subcategory Wallet
 * @param {AppState} state state.
 * @return {User | null} current user.
 */
const getWallets = (state: AppState): Wallet[] => state.wallet.wallets;

/**
 * Get error.
 *
 * @author Ihar Kazlouski
 * @function getError
 * @category Selectors
 * @subcategory Wallet
 * @param {AppState} state state.
 * @return {string | null} error.
 */
const getError = (state: AppState): ApiError | null => state.wallet.error;

export const getWalletsSelector = createSelector(
  getWallets,
  (wallets) => wallets,
);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending,
);
export const getErrorSelector = createSelector(getError, (error) => error);
