import {
  all,
  AllEffect,
  CallEffect,
  fork,
  ForkEffect,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { ApiError, AuthUser, AxiosResponse, PayloadAction, User } from '@types';

import {
  FETCH_USER_REQUEST,
  LOGIN_USER_REQUEST,
  LOGOUT_USER_REQUEST,
} from './auth.action-types';
import { FetchUserRequestPayload, LoginUserRequestPayload } from './auth.types';

/**
 * Fetch user saga worker.
 *
 * @author Ihar Kazlouski
 * @function fetchUserSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<User>>} data.
 */
function* fetchUserSagaWorker (
  action: PayloadAction<FetchUserRequestPayload>,
): Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<User>> {
  // try {
  //   const { data } = yield call(AuthService.apiCurrentUser);
  //
  //   yield put(
  //     actions.fetchUserSuccess({
  //       user: data,
  //     }),
  //   );
  //
  //   yield put({
  //     type:    `${FETCH_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
  //     payload: data,
  //   });
  // } catch (error) {
  //   yield put(
  //     actions.fetchUserFailure({
  //       error: error as ApiError,
  //     }),
  //   );
  //
  //   yield put({
  //     type:    `${FETCH_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
  //     payload: error as ApiError,
  //   });
  //
  //   AuthService.removeTokenSessionStorage();
  // }
}

/**
 * Fetch user saga.
 *
 * @author Ihar Kazlouski
 * @function fetchUserSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* fetchUserSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FETCH_USER_REQUEST, fetchUserSagaWorker)]);
}

/**
 * Login user saga worker.
 *
 * @author Ihar Kazlouski
 * @function loginUserSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<User>>} data.
 */
function* loginUserSagaWorker (
  action: PayloadAction<LoginUserRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<AuthUser> & AxiosResponse<User>
  > {
  // try {
  //   const { values } = action.payload;
  //   const { data } = yield call(AuthService.apiLogin, values);
  //   AuthService.setTokenSessionStorage(data.access_token);
  //   const { data: user } = yield call(AuthService.apiCurrentUser);
  //   yield put(
  //     actions.loginUserSuccess({
  //       ...data,
  //       user,
  //     }),
  //   );
  // } catch (err) {
  //   const error = err as ApiError;
  //
  //   yield put(
  //     actions.loginUserFailure({
  //       error: error,
  //     }),
  //   );
  //
  //   try {
  //     yield call(PopupsService.open, Popups.Error, {
  //       header: error.name,
  //       body:   error.message,
  //     });
  //   } catch (error) {}
  // }
}

/**
 * Login user saga.
 *
 * @author Ihar Kazlouski
 * @function loginUserSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* loginUserSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(LOGIN_USER_REQUEST, loginUserSagaWorker)]);
}

/**
 * Logout user saga worker.
 *
 * @author Ihar Kazlouski
 * @function logoutUserSagaWorker
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* logoutUserSagaWorker (): Generator<
PutEffect | CallEffect,
void,
ApiError & AxiosResponse<User>
> {
  // try {
  //   yield call(AuthService.apiLogout);
  //
  //   yield put(actions.logoutUserSuccess());
  //   yield call(AuthService.removeTokenSessionStorage);
  //   yield call(GridsService.closeAll);
  //   yield call(TablesService.closeAll);
  //   yield call(AuthService.goToAuthRoute);
  // } catch (error) {
  //   yield put(
  //     actions.logoutUserFailure({
  //       error: error as ApiError,
  //     }),
  //   );
  // }
}

/**
 * Logout user saga.
 *
 * @author Ihar Kazlouski
 * @function logoutUserSagaWatcher
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* logoutUserSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(LOGOUT_USER_REQUEST, logoutUserSagaWorker)]);
}

/**
 * Auth saga.
 *
 * @author Ihar Kazlouski
 * @function authSaga
 * @category Sagas
 * @subcategory Auth
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* authSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(fetchUserSagaWatcher),
    fork(loginUserSagaWatcher),
    fork(logoutUserSagaWatcher),
  ]);
}
