import React from 'react';
import { Outlet } from 'react-router-dom';

import { useDeviceSize } from '@hooks';

import { AuthHeaderComponent } from '@components';

import { StyledAuthWrapper, StyledMain } from './auth-layout.styles';

/**
 * Page hoc component.
 *
 * @author Ilya Khachikyan
 * @function AuthLayout
 * @category Hocs
 * @return {JSX.Element} wrapped component.
 */
export const AuthLayout: React.FC = (): JSX.Element => {
  const { desktop } = useDeviceSize();

  return (
    <StyledAuthWrapper>
      {desktop && <AuthHeaderComponent />}
      <StyledMain component='main'>
        <Outlet />
      </StyledMain>
    </StyledAuthWrapper>
  );
};
