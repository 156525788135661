import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

import authSaga from './auth/auth.sagas';
import formsSaga from './forms/forms.sagas';
import modalSaga from './modals/modals.sagas';
import networkSaga from './network/network.sagas';
import popupSaga from './popups/popups.sagas';
import snackbarsSaga from './snackbars/snackbars.sagas';
import statisticSaga from './statistic/statistic.sagas';
import terminalSaga from './terminal/terminal.sagas';
import walletSaga from './wallet/wallet.sagas';

export function* rootSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(formsSaga),
    fork(modalSaga),
    fork(authSaga),
    fork(popupSaga),
    fork(snackbarsSaga),
    fork(walletSaga),
    fork(networkSaga),
    fork(statisticSaga),
    fork(terminalSaga),
  ]);
}
