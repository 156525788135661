import { Routes } from '@enums';

export const navigationMenuLinks = [
  {
    path:         Routes.Main,
    i18nLabelKey: 'common.navigation.main',
  },
  {
    path:         Routes.Trends,
    i18nLabelKey: 'common.navigation.trends',
    disabled:     true,
  },
  {
    path:         Routes.Terminal,
    i18nLabelKey: 'common.navigation.terminal',
  },
  {
    path:         Routes.Strategies,
    i18nLabelKey: 'common.navigation.strategies',
    disabled:     true,
  },
  {
    path:         Routes.Sniping,
    i18nLabelKey: 'common.navigation.sniping',
    disabled:     true,
  },
  {
    path:         Routes.CopyTrading,
    i18nLabelKey: 'common.navigation.copyTrading',
    disabled:     true,
  },
  {
    path:         Routes.Futures,
    i18nLabelKey: 'common.navigation.futures',
    disabled:     true,
  },
];
