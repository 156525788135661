import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Link, Stack, Typography } from '@mui/material';

import { copyToClipboardUtil } from '@utils';
import {
  formatDateToDatetimeStr,
  getBlockExplorerAccountUrl,
  getBlockExplorerTxUrl,
  getNetworkInfo,
  getOrderSideInfo,
  getOrderStatusInfo,
  getShortBlockchainHash,
  isDefined,
} from '@helpers';

import { OrderSide, OrderStatus } from '@enums';

import { Icon } from '@elements';

import { StyledContainer, StyledDataCell } from './order-card.styles';

import chevronRightIcon from '@images/chevron-right.svg';
import copyIcon from '@images/copy.svg';
import editIcon from '@images/edit.svg';
import deleteIcon from '@images/trash.svg';

import { OrderCardProps } from './order-card.types';

export const OrderCard: React.FC<OrderCardProps> = ({ network, order }) => {
  const { t, i18n } = useTranslation();

  const sideInfo = getOrderSideInfo(order.side);
  const statusInfo = getOrderStatusInfo(order.status);

  return (
    <StyledContainer spacing={1.5}>
      <Stack
        direction='row'
        justifyContent='space-between'
        paddingX={1.5}
        alignItems='start'
      >
        <Stack gap={0.5}>
          <Stack>
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant='body2' fontWeight='500' color='white'>
                {order.token_info.name}
              </Typography>
              <Icon
                src={chevronRightIcon}
                width={20}
                height={20}
                color='white'
              />
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              sx={{ cursor: 'pointer' }}
            >
              {isDefined(network) && (
                <Typography
                  component={Link}
                  variant='body3'
                  color='blue'
                  fontWeight={500}
                  href={getBlockExplorerAccountUrl(
                    network.name,
                    order.token_info.address,
                  )}
                >
                  {getShortBlockchainHash(order.token_info.address)}
                </Typography>
              )}
              <IconButton color='blue'>
                <Icon
                  src={copyIcon}
                  width={12}
                  height={12}
                  onClick={(): void =>
                    copyToClipboardUtil(order.token_info.address)
                  }
                />
              </IconButton>
            </Stack>
          </Stack>

          <Typography variant='body3' fontWeight={500} color='white50'>
            {formatDateToDatetimeStr(i18n.language, order.created_at)}
          </Typography>

          <Typography variant='body3' fontWeight={500} color='white50'>
            {order.wallet.name}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={1}>
          {isDefined(order.transaction) && (
            <Link
              href={getBlockExplorerTxUrl(
                network.name,
                order.transaction.transaction_hash,
              )}
              target='_blank'
              rel='noreferrer noopener'
            >
              <IconButton color='white50'>
                <Icon
                  src={getNetworkInfo(network.name).blockExplorer.icon}
                  width={20}
                  height={20}
                />
              </IconButton>
            </Link>
          )}
          <IconButton color='white50'>
            <Icon src={editIcon} width={18} height={18} />
          </IconButton>
          <IconButton color='white50'>
            <Icon src={deleteIcon} width={18} height={18} />
          </IconButton>
        </Stack>
      </Stack>

      <Stack spacing={0.5}>
        <Stack direction='row' spacing={0.5}>
          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.orders.columns.direction')}
            </Typography>
            <Typography
              variant='body2'
              fontWeight={500}
              color={
                order.side === OrderSide.Buy ? 'mediumSeaGreen' : 'lightcoral'
              }
            >
              {t(sideInfo.i18nLabelKey)}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.orders.columns.type')}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {order.type}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.orders.columns.instrument')}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {order.tool}
            </Typography>
          </StyledDataCell>
        </Stack>

        <Stack direction='row' spacing={0.5}>
          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.amount')}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {order.amount} {order.token_info.symbol}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.orders.columns.progress')}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {[
                OrderStatus.Cancelled,
                OrderStatus.Completed,
                OrderStatus.Failed,
              ].includes(order.status)
                ? 100
                : 0}
              %
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.orders.columns.status')}
            </Typography>
            <Typography
              variant='body2'
              fontWeight={500}
              color={statusInfo.color}
            >
              {t(statusInfo.i18nLabelKey)}
            </Typography>
          </StyledDataCell>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};
