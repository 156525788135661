import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@mui/material';

import { Popup, PopupProps } from '@elements';

import { StyledContent, StyledHeader } from './error.styles';
/**
 * ErrorPopup component.
 *
 * @author Ihar Kazlouski
 * @function ErrorPopup
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const ErrorPopup: FC = ({ rejectPopup, popUp }: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  const header =
    (popUp && popUp.data && popUp.data.header) ||
    t('components.popups.error.title');
  const body =
    (popUp && popUp.data && popUp.data.body) ||
    t('components.popups.error.description');

  return (
    <Popup width={422} fullWidth popUp={popUp}>
      <Popup.Title>
        <StyledHeader>
          <ErrorIcon
            fontSize='large'
            color='error'
            sx={{
              height: 48,
              width:  48,
            }}
          />
        </StyledHeader>
      </Popup.Title>
      <Popup.Content>
        <StyledContent flexDirection='column'>
          {header && (
            <Typography color='textPrimary' variant='h4' textAlign='center'>
              {header}
            </Typography>
          )}
          {body && (
            <Typography
              color='textPrimary'
              variant='body1'
              textAlign='center'
              pt={header ? 2 : undefined}
            >
              {body}
            </Typography>
          )}
        </StyledContent>
      </Popup.Content>
    </Popup>
  );
};

export { ErrorPopup };
