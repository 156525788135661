import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { getCurrentNetworkSelector } from '@store/network/network.selectors';

import { PopupsService, WalletService } from '@services';

import { Popups, WalletOption } from '@enums';

import { Button, Popup, PopupProps, TextField } from '@elements';

/**
 * WalletActionPopUp component.
 *
 * @author Ihar Kazlouski
 * @function WalletActionPopUp
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const WalletActionPopUp: FC = ({
  rejectPopup,
  popUp,
}: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  const header = popUp && popUp.data && popUp.data.header;
  const body = popUp && popUp.data && popUp.data.body;
  const elementId = popUp && popUp.data && popUp.data.elementId;
  const [value, setValue] = useState<string>('');
  const currentNetwork = useSelector(getCurrentNetworkSelector);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const handleSubmit = (): void => {
    if (body === WalletOption.Add) {
      WalletService.createWallet(currentNetwork?.id || '', value).catch(() => {
        // Todo: error handling
      });
    }

    if (body === WalletOption.Update) {
      WalletService.updateWallet({
        networkId: currentNetwork?.id || '',
        walletId:  elementId || '',
        name:      value,
      }).catch(() => {
        // Todo: error handling
      });
    }

    if (body === WalletOption.Delete) {
      WalletService.deleteWallet(
        currentNetwork?.id || '',
        elementId || '',
      ).catch(() => {
        // Todo: error handling
      });
    }

    PopupsService.close(Popups.WalletAction);
  };

  return (
    <Popup width={400} fullWidth popUp={popUp}>
      <Popup.Title>
        <Typography variant='body1' color='white'>
          {header}
        </Typography>
      </Popup.Title>
      <Popup.Content>
        {body === WalletOption.Delete ? (
          <></>
        ) : (
          <TextField
            fullWidth
            label='Название'
            onChange={handleChange}
            value={value}
          />
        )}
      </Popup.Content>
      <Popup.Footer>
        <Box display='flex' gap={1} width='100%' mt={2.5}>
          <Button
            variant='outlined'
            fullWidth
            onClick={(): void => PopupsService.close(Popups.WalletAction)}
          >
            {t('pages.wallet.cancel')}
          </Button>
          <Button
            variant={body === WalletOption.Delete ? 'outlined' : 'contained'}
            fullWidth
            disabled={body === WalletOption.Delete ? false : !value}
            color={body === WalletOption.Delete ? 'lightCoral' : undefined}
            onClick={handleSubmit}
          >
            {body === WalletOption.Delete
              ? t('pages.wallet.delete')
              : t('pages.wallet.save')}
          </Button>
        </Box>
      </Popup.Footer>
    </Popup>
  );
};

export { WalletActionPopUp };
