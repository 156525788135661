import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { getShortBlockchainHash, isDefined } from '@helpers';

import { Pair } from '@types';

import { Icon } from '@elements';

import {
  StyledMenu,
  StyledMenuItem,
  StyledNotFoundContainer,
  StyledPairInfoItem,
  StyledSearchBar,
} from './pairs-search.styles';

import alertIcon from '@assets/images/alert-circle.svg';

import { PairsSearchProps } from './pairs-search.types';

export const PairsSearchComponent: React.FC<PairsSearchProps> = ({
  pairs,
  isLoading,
  onSelect,
  query,
  onQueryChange,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onQueryChange(event.target.value);
    },
    [onQueryChange],
  );

  const handleSelect = useCallback(
    (pair: Pair) => {
      onSelect(pair);
      handleClose();
      onQueryChange('');
    },
    [handleClose, onQueryChange, onSelect],
  );

  return (
    <Box position='relative' ref={containerRef}>
      <StyledSearchBar
        fullWidth
        onFocus={handleOpen}
        onBlur={handleClose}
        value={query}
        onChange={handleInputChange}
        menuOpen={isOpen}
      />
      <StyledMenu
        anchorEl={containerRef.current}
        containerWidth={containerRef.current?.clientWidth}
        open={isOpen}
        onClose={handleClose}
        disableAutoFocus
        autoFocus={false}
        elevation={0}
        anchorOrigin={{
          vertical:   'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical:   'top',
        }}
      >
        {isLoading &&
          Array(5)
            .fill(null)
            .map((_, index) => (
              <Box padding={1.5} key={index}>
                <Skeleton height={164} variant='rounded' />
              </Box>
            ))}

        {isDefined(pairs) && !isLoading && pairs.length === 0 && (
          <StyledNotFoundContainer>
            <Stack spacing={2} alignItems='center'>
              <Icon src={alertIcon} width={84} height={84} color='white' />
              <Typography variant='body2' fontWeight={500} textAlign='center'>
                {t('components.pairsSearch.notFound')}
              </Typography>
            </Stack>
          </StyledNotFoundContainer>
        )}

        {isDefined(pairs) &&
          !isLoading &&
          pairs.map((pair) => (
            <StyledMenuItem
              onClick={(): void => handleSelect(pair)}
              key={pair.id}
            >
              <Stack width='100%'>
                <Stack spacing={0.5}>
                  <Typography variant='body2' fontWeight={500}>
                    {pair.token0.symbol}/{pair.token1.symbol}
                  </Typography>
                  <Typography variant='body3' color='white50' fontWeight={500}>
                    ${pair.last_reserves.reserve0}
                  </Typography>
                </Stack>

                <StyledPairInfoItem
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography variant='body3' color='white50' fontWeight={500}>
                    {t('components.pairsSearch.liquidity')}
                  </Typography>
                  <Typography variant='body3' fontWeight={500}>
                    ${pair.last_reserves.reserve0}
                  </Typography>
                </StyledPairInfoItem>
                <StyledPairInfoItem
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography variant='body3' color='white50' fontWeight={500}>
                    {t('components.pairsSearch.createdAt')}
                  </Typography>
                  <Typography variant='body3' fontWeight={500}>
                    {pair.created_at}
                  </Typography>
                </StyledPairInfoItem>
                <StyledPairInfoItem
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography variant='body3' color='white50' fontWeight={500}>
                    {t('components.pairsSearch.pair')}
                  </Typography>
                  <Typography variant='body3' fontWeight={500}>
                    {getShortBlockchainHash(pair.address)}
                  </Typography>
                </StyledPairInfoItem>
                <StyledPairInfoItem
                  direction='row'
                  justifyContent='space-between'
                >
                  <Typography variant='body3' color='white50' fontWeight={500}>
                    {t('components.pairsSearch.token')}
                  </Typography>
                  <Typography variant='body3' fontWeight={500}>
                    {getShortBlockchainHash(pair.token0.address)}
                  </Typography>
                </StyledPairInfoItem>
              </Stack>
            </StyledMenuItem>
          ))}
      </StyledMenu>
    </Box>
  );
};
