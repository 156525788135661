import { FC } from 'react';
import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledCheckboxBase,
  StyledCheckboxLabel,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyleFromHelperWrapper,
} from './checkbox-field.styles';

import checkedIcon from '@images/check.svg';
import uncheckedIcon from '@images/uncheked.svg';

import { CheckboxFieldProps } from './checkbox-field.types';

/**
 * CheckboxField component.
 *
 * @author Ihar Kazlouski
 * @function CheckboxField
 * @category components
 * @return {JSX.Element} checkbox component.
 */
const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  fullWidth,
  color = 'white',
  label,
  title,
  subtitle,
  bottomLabel,
  error,
  deviceSize,
  focused,
  inputRef,
  value,
  labelAlign,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-checkboxfield`;

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      {label && (
        <StyledInputLabel shrink htmlFor={literalProps.htmlFor} $color={color}>
          {label}
        </StyledInputLabel>
      )}
      <StyledCheckboxLabel
        labelAlign={labelAlign}
        control={
          <StyledCheckboxBase
            inputProps={{
              type: 'checkbox',
              id:   literalProps.id,
            }}
            error={error || ''}
            id={literalProps.id}
            aria-describedby={inputName}
            name={name}
            fullWidth={fullWidth}
            icon={<Icon src={uncheckedIcon} width={20} height={20} />}
            checkedIcon={<Icon src={checkedIcon} width={20} height={20} />}
            {...restProps}
            type='checkbox'
          />
        }
        label={
          <Box>
            <Typography variant='body1' fontWeight={500}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant='caption1' color='abandonedSpaceship'>
                {subtitle}
              </Typography>
            )}
          </Box>
        }
      />
      {isError && (
        <StyleFromHelperWrapper>
          <StyledFormHelperErrorText id={literalProps.errorId}>
            {error}
          </StyledFormHelperErrorText>
        </StyleFromHelperWrapper>
      )}
      {bottomLabel && !isError ? (
        <StyleFromHelperWrapper>
          <StyledFormHelperText $color={color}>
            {bottomLabel}
          </StyledFormHelperText>
        </StyleFromHelperWrapper>
      ) : null}
    </StyledFormControl>
  );
};

export { CheckboxField };
