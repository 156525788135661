import { produce } from 'immer';

import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
} from './auth.action-types';
import { ActionsTypes, AuthState } from './auth.types';

const initialState: AuthState = {
  pending: false,
  user:    null,
  error:   null,
};

/**
 * Auth reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Auth
 * @param {AuthState} state state.
 * @param {ActionsTypes} action action.
 * @return {AuthState} auth state.
 */
export default (state = initialState, action: ActionsTypes): AuthState => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case FETCH_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.user = action.payload.user;
        draft.error = null;
      });
    }

    case FETCH_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.user = null;
        draft.error = action.payload.error;
      });
    }

    case LOGIN_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case LOGIN_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.user = action.payload.user;
        draft.pending = false;
        draft.error = null;
      });
    }

    case LOGIN_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.user = null;
        draft.error = action.payload.error;
      });
    }

    case LOGOUT_USER_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case LOGOUT_USER_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.user = null;
        draft.error = null;
      });
    }

    case LOGOUT_USER_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
