import React from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton, Typography } from '@mui/material';

import { isDefined } from '@helpers';

import { SwapTransactionCardComponent } from '@components/swap-transaction-card/swap-transaction-card.component';

import { StyledContainer } from './swap-transaction-cards-list.styles';

import { SwapTransactionCardsListProps } from './swap-transaction-cards-list.types';

export const SwapTransactionCardsListComponent: React.FC<
SwapTransactionCardsListProps
> = ({ className, network, transactions, isLoading, perPage }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer className={className}>
      {isLoading &&
        Array(perPage)
          .fill(null)
          .map((_, index) => (
            <Skeleton variant='rounded' height={195} width='100%' key={index} />
          ))}
      {!isLoading && isDefined(transactions) && transactions.length === 0 && (
        <Typography width='100%' align='center' padding={5} variant='body1'>
          {t('tables.swapTransactions.noData')}
        </Typography>
      )}
      {!isLoading &&
        isDefined(transactions) &&
        transactions.length > 0 &&
        isDefined(network) &&
        transactions?.map((transaction) => (
          <SwapTransactionCardComponent
            transaction={transaction}
            network={network}
            key={transaction.id}
          />
        ))}
    </StyledContainer>
  );
};
