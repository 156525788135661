import { ExoticComponent, lazy } from 'react';

import { Retry } from '../retry';

/**
 * Lazy loading page component.
 *
 * @author Ihar Kazlouski
 * @function LazyPage
 * @category Hocs
 * @component
 * @param {string} folderName folder name.
 * @param {string} className class name.
 * @return {ExoticComponent} loaded component.
 */
const LazyPage = (folderName: string, className: string): ExoticComponent =>
  lazy(() =>
    Retry(async () => {
      const exportedData = (await import(
        `@pages/${folderName}`
      )) as ExoticComponent & { default: ExoticComponent };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      exportedData.default = exportedData[className];
      return exportedData;
    }),
  );

export { LazyPage };
