import { Box, BoxProps, Container, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPrivateWrapper = styled(Box)<BoxProps>(({ theme }) => {
  const styles: OverridesStyleRules = {
    minHeight:     '100vh',
    maxWidth:      '100%',
    display:       'flex',
    flexDirection: 'column',
  };
  return styles;
});

const StyledMain = styled(Box)<BoxProps & { theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      flexGrow:      1,
      display:       'flex',
      flexDirection: 'column',
      flex:          '1 1',
      width:         '100%',
    };
    return styles;
  },
);
const StyledContainerWrapper = styled(Box)<BoxProps>(({ theme }) => {
  const styles: OverridesStyleRules = {
    maxWidth:      '100vw',
    flex:          '1 1',
    display:       'flex',
    flexDirection: 'column',
  };
  return styles;
});

const StyledContainer = styled(Container)(({ theme }) => {
  const styles: OverridesStyleRules = {
    flex:          '1 1',
    display:       'flex',
    flexDirection: 'column',
  };
  return styles;
});
export {
  StyledContainer,
  StyledContainerWrapper,
  StyledMain,
  StyledPrivateWrapper,
};
