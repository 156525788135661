import { produce } from 'immer';

import {
  CLOSE_SNACKBAR,
  CLOSING_SNACKBAR_WITH_ANSWER,
  SET_SNACKBAR,
} from './snackbars.action-types';
import { ActionsTypes, SnackbarsState } from './snackbars.types';

const initialState: SnackbarsState[] = [];

/**
 * Snackbars reducer.
 *
 * @author Mihail Siutsou
 * @category Reducers
 * @subcategory Snackbars
 * @param {SnackbarsState} state state.
 * @param {ActionsTypes} action action.
 * @return {SnackbarsState[]} snackbars state.
 */
const snackbarsReducer = (
  state = initialState,
  action: ActionsTypes,
): SnackbarsState[] => {
  switch (action.type) {
    case SET_SNACKBAR: {
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (snackbar) => snackbar.snackbarId === action.payload.snackbarId,
        );
        if (idx >= 0) {
          draft[idx] = {
            snackbarId: action.payload.snackbarId,
            isOpen:     action.payload.isOpen,
            data:       action.payload.data,
            accepted:   false,
            rejected:   false,
          };
        } else {
          draft.push({
            snackbarId: action.payload.snackbarId,
            isOpen:     action.payload.isOpen,
            data:       action.payload.data,
            accepted:   false,
            rejected:   false,
          });
        }
      });
    }

    case CLOSE_SNACKBAR: {
      return produce(state, (draft) => {
        const snackbar = draft.find(
          (snackbar) => snackbar.snackbarId === action.payload.snackbarId,
        );

        if (snackbar) {
          snackbar.isOpen = false;
        }
      });
    }

    case CLOSING_SNACKBAR_WITH_ANSWER: {
      return produce(state, (draft) => {
        const index = draft.findIndex(
          (snackbar) => snackbar.snackbarId === action.payload.snackbarId,
        );
        if (index !== -1) draft.splice(index, 1);
      });
    }

    default: {
      return state;
    }
  }
};

export default snackbarsReducer;
