import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  FETCH_NETWORKS_FAILURE,
  FETCH_NETWORKS_REQUEST,
  FETCH_NETWORKS_SUCCESS,
  SET_CURRENT_NETWORK,
} from './network.action.types';
import {
  FetchNetworksFailurePayload,
  FetchNetworksSuccessPayload,
} from './network.types';

export const actions = {
  /**
   * Fetch networks request action.
   *
   * @author Ihar Kazlouski
   * @function fetchNetworksRequest
   * @category Actions
   * @subcategory Network
   * @return {fetchNetworksRequest} fetch network request.
   */
  fetchNetworksRequest: () =>
    ({
      type:              FETCH_NETWORKS_REQUEST,
      [WAIT_FOR_ACTION]: `${FETCH_NETWORKS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${FETCH_NETWORKS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Fetch network request success action.
   *
   * @author Ihar Kazlouski
   * @function fetchNetworksSuccess
   * @category Actions
   * @subcategory Network
   * @param {FetchNetworksSuccessPayload} payload payload.
   * @return {fetchNetworksSuccess} fetch network success.
   */
  fetchNetworksSuccess: (payload: FetchNetworksSuccessPayload) =>
    ({
      type: FETCH_NETWORKS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch network request failure action.
   *
   * @author Ihar Kazlouski
   * @function fetchNetworksFailure
   * @category Actions
   * @subcategory Network
   * @param {FetchNetworksFailurePayload} payload payload.
   * @return {fetchNetworksFailure} fetch network request failure.
   */
  fetchNetworksFailure: (payload: FetchNetworksFailurePayload) =>
    ({
      type: FETCH_NETWORKS_FAILURE,
      payload,
    } as const),

  /**
   * Set current network action.
   *
   * @author Ihar Kazlouski
   * @function setCurrentNetwork
   * @category Actions
   * @subcategory Network
   * @param {string} networkId networkId.
   * @return {setCurrentNetwork}
   */
  setCurrentNetwork: (networkId: string) =>
    ({
      type:    SET_CURRENT_NETWORK,
      payload: { networkId },
    } as const),
};
