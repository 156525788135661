import { produce } from 'immer';

import { OrderStatusFilter, SortingOrderDirection } from '@enums';

import {
  FETCH_TERMINAL_DEFAULT_PAIR_FAILURE,
  FETCH_TERMINAL_ORDERS_FAILURE,
  FETCH_TERMINAL_ORDERS_REQUEST,
  FETCH_TERMINAL_ORDERS_SUCCESS,
  FETCH_TERMINAL_PAIRS_FAILURE,
  FETCH_TERMINAL_PAIRS_REQUEST,
  FETCH_TERMINAL_PAIRS_SUCCESS,
  RESET_TERMINAL_ORDERS,
  RESET_TERMINAL_PAIR,
  RESET_TERMINAL_PAIRS,
  SELECT_TERMINAL_PAIR,
} from './terminal.action-types';
import { ActionsTypes, TerminalState } from './terminal.types';

const initialState: TerminalState = {
  pairs: {
    selected: null,
    list:     null,
    pending:  {
      getList: false,
    },
    errors: {
      getList:    null,
      getDefault: null,
    },
  },
  orders: {
    list: {
      items:      null,
      page:       1,
      perPage:    10,
      total:      0,
      statusList: [
        OrderStatusFilter.Active,
        OrderStatusFilter.Completed,
        OrderStatusFilter.Failed,
      ],
      sortingOrder: {
        direction: SortingOrderDirection.Descending,
        field:     'created_at',
      },
    },
    pending: {
      getList: false,
    },
    errors: {
      getList: null,
    },
  },
};

/**
 * Terminal reducer.
 *
 * @author Ilya Khachikyan
 * @category Reducers
 * @subcategory Terminal
 * @param {TerminalState} state state.
 * @param {ActionsTypes} action action.
 * @return {TerminalState} Terminal state.
 */
export default (state = initialState, action: ActionsTypes): TerminalState => {
  switch (action.type) {
    case FETCH_TERMINAL_PAIRS_REQUEST: {
      return produce(state, (draft) => {
        draft.pairs.pending.getList = true;
      });
    }

    case FETCH_TERMINAL_PAIRS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pairs.pending.getList = false;
        draft.pairs.list = action.payload.pairs.results;
        draft.pairs.errors.getList = null;
      });
    }

    case FETCH_TERMINAL_PAIRS_FAILURE: {
      return produce(state, (draft) => {
        draft.pairs.pending.getList = false;
        draft.pairs.list = null;
        draft.pairs.errors.getList = action.payload.error;
      });
    }

    case RESET_TERMINAL_PAIRS: {
      return produce(state, (draft) => {
        draft.pairs.list = null;
      });
    }

    case FETCH_TERMINAL_DEFAULT_PAIR_FAILURE: {
      return produce(state, (draft) => {
        draft.pairs.selected = null;
        draft.pairs.errors.getDefault = action.payload.error;
      });
    }

    case SELECT_TERMINAL_PAIR: {
      return produce(state, (draft) => {
        draft.pairs.selected = action.payload.pair;
      });
    }

    case RESET_TERMINAL_PAIR: {
      return produce(state, (draft) => {
        draft.pairs.selected = null;
      });
    }

    case FETCH_TERMINAL_ORDERS_REQUEST: {
      return produce(state, (draft) => {
        draft.orders.pending.getList = true;
        draft.orders.list.page = action.payload.page;
        draft.orders.list.perPage = action.payload.perPage;
        draft.orders.list.sortingOrder = action.payload.sortingOrder;
        draft.orders.list.statusList = action.payload.statusList;
      });
    }

    case FETCH_TERMINAL_ORDERS_SUCCESS: {
      return produce(state, (draft) => {
        draft.orders.pending.getList = false;
        draft.orders.list.items = action.payload.orders.results;
        draft.orders.list.total = action.payload.orders.count;
        draft.orders.errors.getList = null;
      });
    }

    case FETCH_TERMINAL_ORDERS_FAILURE: {
      return produce(state, (draft) => {
        draft.orders.pending.getList = false;
        draft.orders.list.items = null;
        draft.orders.errors.getList = action.payload.error;
      });
    }

    case RESET_TERMINAL_ORDERS: {
      return produce(state, (draft) => {
        draft.orders.list.items = null;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
