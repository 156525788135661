import { IMask } from 'react-imask';

import moment from 'moment';

/**
 * Masks util.
 *
 * @author Ihar Kazlouski
 * @const MaskUtil
 * @category Utils
 */
const MaskUtil = {
  url: {
    mask: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  },
  email: {
    mask: /^\S*@?\S*$/,
  },
  positiveNumber: {
    mask:        Number,
    definitions: {
      '#': /[\d\\.]/,
    },
    lazy:               true,
    scale:              0,
    signed:             false,
    thousandsSeparator: ' ',
    normalizeZeros:     true,
    min:                0,
    max:                100000000,
  },
  positiveNumberFloat: {
    mask:        Number,
    definitions: {
      '#': /[\d\\.]/,
    },
    lazy:               true,
    scale:              2,
    signed:             false,
    thousandsSeparator: ' ',
    normalizeZeros:     true,
    radix:              ',',
    min:                0,
    max:                100000000,
  },
  time: {
    mask:    Date,
    pattern: 'HH:mm',
    lazy:    false,
    min:     new Date(1970, 0, 1),
    max:     new Date(2030, 0, 1),
    format:  (date: string): string => {
      return moment(date).format('HH:mm');
    },
    parse: (str: string): moment.Moment => {
      return moment(str, 'HH:mm');
    },
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to:   23,
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to:   59,
      },
    },
  },
};

export { MaskUtil };
