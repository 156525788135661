import { ConstantsUtil } from '@utils';

import { ApiResponseStatusText } from '@enums';
import { ApiErrorObject } from '@types';

/**
 * get Error popup message helper.
 *
 * @author Ihar Kazlouski
 * @function getPopupErrorMessage
 * @category helpers
 * @param {ApiErrorObject} object error
 * @return {ApiErrorObject} new error.
 */
const getPopupErrorMessage = (error: ApiErrorObject): ApiErrorObject => {
  const errorObject = { ...error };

  switch (error.name) {
    case ApiResponseStatusText.invalid_grant:
      errorObject.name = ConstantsUtil.errors.INVALID_GRANT;
      errorObject.message = ConstantsUtil.errors.INVALID_GRANT_MESSAGE;

      return errorObject;
    case ApiResponseStatusText.insufficient_scope:
      errorObject.name = ConstantsUtil.errors.INSUFFICIENT_SCOPE;
      errorObject.message = ConstantsUtil.errors.INSUFFICIENT_SCOPE_MESSAGE;

      return errorObject;
    case ApiResponseStatusText.invalid_request:
      errorObject.name = ConstantsUtil.errors.INVALID_REQUEST;
      errorObject.message = ConstantsUtil.errors.INVALID_REQUEST_MESSAGE;

      return errorObject;
    case ApiResponseStatusText.unauthorized:
    case ApiResponseStatusText.invalid_token:
      errorObject.name = ConstantsUtil.errors.INVALID_TOKEN;
      errorObject.message = ConstantsUtil.errors.INVALID_TOKEN_MESSAGE;

      return errorObject;
    case ApiResponseStatusText.server_error:
      errorObject.name = ConstantsUtil.errors.SERVER_ERROR;
      errorObject.message = ConstantsUtil.errors.SERVER_ERROR_MESSAGE;

      return errorObject;
    default:
      return errorObject;
  }
};

export { getPopupErrorMessage };
