import React, { Children, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { getValidChild } from '@helpers';

import { Popups } from '@enums';

import { StyledCloseButton, StyledPopupContainer } from './popup.styles';

import { PopupContent, PopupContentProps } from './slots/popup-content';
import { PopupFooter, PopupFooterProps } from './slots/popup-footer';
import { PopupTitle, PopupTitleProps } from './slots/popup-title';
import { PopupProps } from './popup.types';

/**
 * PopupSlot component.
 *
 * @author Ihar Kazlouski
 * @function Popup
 * @category components
 * @param {PopupProps} popup props.
 * @return {JSX.Element} popup component.
 */
const Popup = ({
  children,
  handleClose,
  acceptPopup,
  rejectPopup,
  popupId,
  popUp,
}: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  const childrenArray = Children.toArray(children);

  const title = useMemo(
    () => childrenArray.find(getValidChild<PopupTitleProps>(PopupTitle)),
    [childrenArray],
  );
  const content = useMemo(
    () => childrenArray.find(getValidChild<PopupContentProps>(PopupContent)),
    [childrenArray],
  );
  const footer = useMemo(
    () => childrenArray.find(getValidChild<PopupFooterProps>(PopupFooter)),
    [childrenArray],
  );

  /**
   * Reject popup.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleRejectPopup = (): void => {
    rejectPopup && rejectPopup();
  };

  /**
   * Accept popup.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleAcceptPopup = (): void => {
    acceptPopup && acceptPopup();
  };

  return (
    <StyledPopupContainer padded={popupId !== Popups.Profile}>
      {title && (
        <PopupTitle
          acceptPopup={acceptPopup}
          rejectPopup={rejectPopup}
          handleClose={handleClose}
          popupId={popupId}
          popUp={popUp}
        >
          {title.props.children}
        </PopupTitle>
      )}
      {content && (
        <PopupContent
          acceptPopup={acceptPopup}
          rejectPopup={rejectPopup}
          handleClose={handleClose}
          popupId={popupId}
          popUp={popUp}
        >
          {content.props.children}
        </PopupContent>
      )}
      {footer && (
        <PopupFooter
          acceptPopup={acceptPopup}
          rejectPopup={rejectPopup}
          handleClose={handleClose}
          popupId={popupId}
          popUp={popUp}
        >
          {footer.props.children}
        </PopupFooter>
      )}
      {popUp && popUp.data && popUp.data.acceptButton && (
        <Box display='flex' justifyContent='center' pt={1}>
          <StyledCloseButton variant='contained' onClick={handleAcceptPopup}>
            {t('components.popups.ok')}
          </StyledCloseButton>
        </Box>
      )}
      {popUp && popUp.data && popUp.data.rejectButton && (
        <Box display='flex' justifyContent='center' pt={1}>
          <StyledCloseButton onClick={handleRejectPopup}>
            {popUp && popUp.data && popUp.data.rejectButton}
          </StyledCloseButton>
        </Box>
      )}
    </StyledPopupContainer>
  );
};

Popup.Title = PopupTitle;
Popup.Content = PopupContent;
Popup.Footer = PopupFooter;

export { Popup };
