import { Box, Button, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPopupContainer = styled(Box)<{
  theme: DefaultTheme;
  padded: boolean;
}>(({ theme, padded }) => {
  const styles: OverridesStyleRules = {
    padding:                        padded ? theme.spacing(3.5) : theme.spacing(3, 0, 0, 0),
    borderRadius:                   theme.typography.pxToRem(24),
    minHeight:                      !padded ? '70vh' : 'unset',
    [theme.breakpoints.down('md')]: {
      padding: padded ? theme.spacing(3, 2) : theme.spacing(3, 0, 0, 0),
    },
  };

  return styles;
});

const StyledCloseButton = styled(Button)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    minWidth: theme.typography.pxToRem(130),
  };

  return styles;
});

export { StyledCloseButton, StyledPopupContainer };
