import { dispatch as storeDispatch } from '@store';
import { actions, TransactionsResponse } from '@store/statistic';
import { PairResponse } from '@store/statistic/statistic.types';

import { ApiService } from '@services';

import { ApiError, AsyncDispatch, AxiosResponse } from '@types';

/**
 * Statistic controller.
 *
 * @author Ihar Kazlouski
 * @const NetworkServiceClass
 * @category Services
 * @subcategory Statistic
 */
class StatisticServiceClass {
  /**
   * Pairs Statistic get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<PairResponse>} promise.
   */
  async apiPairsGet ({
    networkId,
    limit,
    offset,
  }: {
    networkId: string;
    limit: number;
    offset?: number;
  }): Promise<ApiError | AxiosResponse<PairResponse>> {
    return ApiService.apiGet(`/public/networks/${networkId}/pairs`, {
      params: { limit, offset },
    });
  }

  /**
   * Get Pairs Statistic list.
   *
   * @author Ihar Kazlouski
   * @return {Promise<PairResponse | ApiError>} Pairs response promise.
   */
  async getPairsList ({
    networkId,
    offset,
    limit,
  }: {
    networkId: string;
    offset?: number;
    limit: number;
  }): Promise<PairResponse | ApiError> {
    const dispatch: AsyncDispatch<PairResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchPairsRequest({ networkId, offset, limit }));
  }

  /**
   * Transactions get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<TransactionsResponse>} promise.
   */
  async apiTransactionsGet ({
    networkId,
    limit,
  }: {
    networkId: string;
    limit: number;
  }): Promise<ApiError | AxiosResponse<TransactionsResponse>> {
    return ApiService.apiGet(`/public/networks/${networkId}/transactions`, {
      params: { limit },
    });
  }

  /**
   * Get Transactions list.
   *
   * @author Ihar Kazlouski
   * @return {Promise<TransactionsResponse | ApiError>} Pairs response promise.
   */
  async getTransactionsList (
    networkId: string,
  ): Promise<TransactionsResponse | ApiError> {
    const dispatch: AsyncDispatch<TransactionsResponse | ApiError> =
      storeDispatch;

    return dispatch(actions.fetchTransactionsRequest(networkId));
  }
}

const StatisticService = new StatisticServiceClass();

export { StatisticService };
