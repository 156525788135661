import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { StyledTab, StyledTabsContainer } from './tabs.styles';

import { TabsProps } from './tabs.types';

const TabsComponent: FC<TabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <StyledTabsContainer direction='row'>
        {tabs.map((tab) => (
          <StyledTab
            key={tab.value}
            active={selectedTab === tab.value}
            onClick={(): void => {
              setSelectedTab(tab.value);
            }}
          >
            <Typography variant='body2' fontWeight={500} color='inherit'>
              {t(tab.i18nLabelKey)}
            </Typography>
          </StyledTab>
        ))}
      </StyledTabsContainer>
    </Stack>
  );
};

export { TabsComponent };
