import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  IconButton,
  Menu,
  menuClasses,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

export const StyledContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      borderTop:  `1px solid ${theme.palette.white10.main}`,
      background: theme.palette.mirage.main,
      position:   'fixed',
      bottom:     0,
      left:       0,
      width:      '100%',
      padding:    theme.spacing(1.5, 2.5, 2.5),
      height:     theme.spacing(10),
    };

    return styles;
  },
);

export const StyledNavLink = styled(ButtonBase, {
  shouldForwardProp: (propName) => propName !== 'active',
})<ButtonBaseProps & { to?: string; active?: boolean }>(({ theme, active }) => {
  const styles: OverridesStyleRules = {
    width:          '100%',
    color:          active ? theme.palette.white.main : theme.palette.white50.main,
    padding:        theme.spacing(0),
    gap:            theme.spacing(0.5),
    display:        'flex',
    flexDirection:  'column',
    alignItems:     'center',
    textDecoration: 'none',

    ['&.active']: {
      color: theme.palette.white.main,
    },
  };

  return styles;
});

export const StyledMenu = styled(Menu)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      [`& .${menuClasses.paper}`]: {
        background:   theme.palette.mirage.main,
        color:        theme.palette.white.main,
        border:       `1px solid ${theme.palette.white10.main}`,
        borderRadius: pxToRem(24),
        maxWidth:     '340px',
        width:        '100%',
        overflow:     'visible',
      },

      [`& .${menuClasses.list}`]: {
        padding: theme.spacing(0),
      },
    };

    return styles;
  },
);

export const StyledMenuNavLink = styled(ButtonBase)<
ButtonBaseProps & { to?: string; disabled?: boolean }
>(({ theme, disabled }) => {
  const styles: OverridesStyleRules = {
    display:        'flex',
    padding:        theme.spacing(2, 2.5),
    width:          '100%',
    justifyContent: 'space-between',

    ['&:not(:last-of-type)']: {
      borderBottom: `1px solid ${theme.palette.white10.main}`,
    },

    ['&.active']: {
      color: theme.palette.blue.main,
    },

    ...(disabled
      ? {
          pointerEvents: 'none',
          color:         theme.palette.white50.main,
        }
      : {}),
  };

  return styles;
});

export const StyledMenuCloseButton = styled(IconButton)<{
  theme?: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:     theme.palette.white.main,
    position:  'absolute',
    bottom:    theme.spacing(-7),
    left:      '50%',
    transform: 'translateX(-50%)',
  };

  return styles;
});
