import { createSelector } from 'reselect';

import { ApiError, Network } from '@types';

import { AppState } from '../root.reducer';

/**
 * Get pending.
 *
 * @author Ihar Kazlouski
 * @function getPending
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getPending = (state: AppState): boolean => state.network.pending;

/**
 * Get networks.
 *
 * @author Ihar Kazlouski
 * @function getWallets
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {Network[] | null} networks.
 */
const getNetworks = (state: AppState): Network[] | null =>
  state.network.networks;

/**
 * Get current network.
 *
 * @author Ihar Kazlouski
 * @function getWallets
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {Network | null} network.
 */
const getCurrentNetwork = (state: AppState): Network | null =>
  state.network.currentNetwork;

/**
 * Get error.
 *
 * @author Ihar Kazlouski
 * @function getError
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {string | null} error.
 */
const getError = (state: AppState): ApiError | null => state.network.error;

export const getNetworksSelector = createSelector(
  getNetworks,
  (networks) => networks,
);

export const getCurrentNetworkSelector = createSelector(
  getCurrentNetwork,
  (network) => network,
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending,
);
export const getErrorSelector = createSelector(getError, (error) => error);
