import { NetworkResponse } from '@store/network';

import { FeaturesService, NetworkService, WalletService } from '@services';
import { ConstantsUtil } from '@utils';

import { Feature, Networks } from '@enums';

/**
 * Init service.
 *
 * @author Ihar Kazlouski
 * @class InitServiceClass
 * @category Services
 */
class InitServiceClass {
  /**
   * Is app init.
   *
   * @property {boolean} isInit is app init.
   * @private
   */
  private isInit = false;

  /**
   * Set service workers.
   *
   * @author Ihar Kazlouski
   * @private
   * @static
   * @return {Promise<ServiceWorkerRegistration | undefined>} set service workers promise.
   */
  private static async setServiceWorkers (): Promise<
  ServiceWorkerRegistration | undefined
  > {
    if (
      'serviceWorker' in navigator &&
      FeaturesService.available(Feature.ServiceWorker)
    ) {
      return await navigator.serviceWorker.register('/service.worker.js');
    }
  }

  /**
   * Init method.
   *
   * @author Ihar Kazlouski
   * @return {Promise<void>} app init promise.
   */
  async init (): Promise<void> {
    if (!this.isInit) {
      await InitServiceClass.setServiceWorkers();
      const networks =
        (await NetworkService.getNetworksList()) as NetworkResponse;
      const defaultNetwork = networks.results.find(
        (network) => network.name === Networks.Ethereum,
      );
      await WalletService.getWalletsList(defaultNetwork?.id || '');
      this.isInit = true;
    } else {
      throw new Error(ConstantsUtil.errors.APP_ALREADY_INIT_ERROR);
    }
  }
}

const InitService = new InitServiceClass();

export { InitService };
