export enum Popups {
  Success = 'Success',
  Info = 'Info',
  Error = 'Error',
  Registration = 'Registration',
  FollowInstructions = 'FollowInstructions',
  SavedSeed = 'SavedSeed',
  WalletAction = 'WalletAction',
  Profile = 'Profile',
}
