import { dispatch as storeDispatch } from '@store';
import { actions, SnackbarData, SnackbarPayload } from '@store/snackbars';

import { AsyncDispatch } from '@types';

/**
 * Snackbars controller.
 *
 * @author Ihar Kazlouski
 * @const SnackbarsServiceClass
 * @category Services
 * @subcategory Snackbars
 */
class SnackbarsServiceClass {
  /**
   * Open snackbar.
   *
   * @author Ihar Kazlouski
   * @param {string} snackbarId snackbarId.
   * @param {T} data snackbar data.
   * @return {void}
   */
  open (
    snackbarId: string,
    data?: SnackbarData,
  ): Promise<SnackbarPayload | Error> {
    const dispatch: AsyncDispatch<SnackbarPayload | Error> = storeDispatch;

    const snackbarAction = dispatch(
      actions.snackbarInit({
        snackbarId,
        data,
        isOpen: true,
      }),
    );

    snackbarAction.catch((error: Error): Error => error);

    return snackbarAction;
  }

  /**
   * Close snackbar.
   *
   * @author Ihar Kazlouski
   * @param {string} snackbarId snackbarId.
   * @param {Dispatch} dispatch dispatch.
   * @return {void}
   */
  close (snackbarId: string): void {
    storeDispatch(actions.snackbarInitClosing({ snackbarId }));
  }

  /**
   * Close snackbar with answer.
   *
   * @author Ihar Kazlouski
   * @param {string} snackbarId snackbarId.
   * @param {Dispatch} dispatch dispatch.
   * @param {object} answer answer.
   * @return {void}
   */
  closeWithAnswer (
    snackbarId: string,
    { accepted = false, rejected = false },
  ): void {
    storeDispatch(
      actions.snackbarInitClosingWithAnswer({
        snackbarId,
        accepted,
        rejected,
      }),
    );
  }
}

const SnackbarsService = new SnackbarsServiceClass();

export { SnackbarsService };
