import { Link, Stack, styled } from '@mui/material';

export const StyledContainer = styled(Stack)(({ theme }) => {
  return {
    padding:      theme.spacing(2.5, 1, 1),
    borderRadius: theme.spacing(2),
    background:   theme.palette.white4.main,
  };
});

export const StyledTxLink = styled(Link)(({ theme }) => {
  return {
    color:      theme.palette.white50.main,
    transition: 'all 0.3s ease-in-out',

    '&:active': {
      color: theme.palette.white.main,
    },
  };
});

export const StyledDataCell = styled(Stack)(({ theme }) => {
  return {
    flex:         1,
    padding:      theme.spacing(1.5),
    background:   theme.palette.white4.main,
    borderRadius: theme.spacing(1.25),
  };
});
