import { useMediaQuery } from '@mui/material';

import { DefaultTheme, DeviceSize } from '@types';

/**
 * Device size hook.
 *
 * @author Ihar Kazlouski
 * @function useDeviceSize
 * @category helper
 * @return {{ mobile: boolean; tablet: boolean; desktop: boolean; }} return device sizes.
 */
export const useDeviceSize = (): DeviceSize => {
  return {
    mobile: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.down('tablet'),
    ),
    tablet: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.between('tablet', 'desktop'),
    ),
    desktop: useMediaQuery((theme: DefaultTheme) => theme.breakpoints.up('desktop')),
  };
};
