import {
  FETCH_TERMINAL_DEFAULT_PAIR_FAILURE,
  FETCH_TERMINAL_DEFAULT_PAIR_REQUEST,
  FETCH_TERMINAL_ORDERS_FAILURE,
  FETCH_TERMINAL_ORDERS_REQUEST,
  FETCH_TERMINAL_ORDERS_SUCCESS,
  FETCH_TERMINAL_PAIRS_FAILURE,
  FETCH_TERMINAL_PAIRS_REQUEST,
  FETCH_TERMINAL_PAIRS_SUCCESS,
  RESET_TERMINAL_ORDERS,
  RESET_TERMINAL_PAIR,
  RESET_TERMINAL_PAIRS,
  SELECT_TERMINAL_PAIR,
} from './terminal.action-types';
import {
  FetchTerminalOrdersFailurePayload,
  FetchTerminalOrdersRequestPayload,
  FetchTerminalOrdersSuccessPayload,
  FetchTerminalPairsFailurePayload,
  FetchTerminalPairsRequestPayload,
  FetchTerminalPairsSuccessPayload,
  SelectTerminalPairPayload,
} from './terminal.types';

export const actions = {
  /**
   * Fetch pairs request action.
   *
   * @author Ilya Khachikyan
   * @function fetchPairsRequest
   * @category Actions
   * @subcategory Terminal
   * @return {FETCH_TERMINAL_PAIRS_REQUEST} fetch pairs request.
   */
  fetchPairsRequest: (payload: FetchTerminalPairsRequestPayload) =>
    ({
      type: FETCH_TERMINAL_PAIRS_REQUEST,
      payload,
    } as const),

  /**
   * Fetch pairs request success action.
   *
   * @author Ilya Khachikyan
   * @function fetchPairsSuccess
   * @category Actions
   * @subcategory Terminal
   * @param {FetchTerminalPairsSuccessPayload} payload payload.
   * @return {FETCH_TERMINAL_PAIRS_SUCCESS} fetch pairs success.
   */
  fetchPairsSuccess: (payload: FetchTerminalPairsSuccessPayload) =>
    ({
      type: FETCH_TERMINAL_PAIRS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch pairs request failure action.
   *
   * @author Ilya Khachikyan
   * @function fetchPairsFailure
   * @category Actions
   * @subcategory Terminal
   * @param {FetchTerminalPairsFailurePayload} payload payload.
   * @return {FETCH_TERMINAL_PAIRS_FAILURE} fetch pairs request failure.
   */
  fetchPairsFailure: (payload: FetchTerminalPairsFailurePayload) =>
    ({
      type: FETCH_TERMINAL_PAIRS_FAILURE,
      payload,
    } as const),

  /**
   * Reset pairs list.
   *
   * @author Ilya Khachikyan
   * @function fetchPairsFinished
   * @category Actions
   * @subcategory Terminal
   * @return {RESET_TERMINAL_PAIRS} reset pairs.
   */
  resetPairs: () =>
    ({
      type: RESET_TERMINAL_PAIRS,
    } as const),

  /**
   * Fetch default pair request action.
   *
   * @author Ilya Khachikyan
   * @function fetchDefaultPairRequest
   * @category Actions
   * @subcategory Terminal
   * @return {FETCH_TERMINAL_DEFAULT_PAIR_REQUEST} fetch default pair request.
   */
  fetchDefaultPairRequest: (payload: FetchTerminalPairsRequestPayload) =>
    ({
      type: FETCH_TERMINAL_DEFAULT_PAIR_REQUEST,
      payload,
    } as const),

  /**
   * Fetch default pair request failure action.
   *
   * @author Ilya Khachikyan
   * @function fetchDefaultPairFailure
   * @category Actions
   * @subcategory Terminal
   * @param {FetchTerminalPairsFailurePayload} payload payload.
   * @return {FETCH_TERMINAL_DEFAULT_PAIR_FAILURE} fetch default pair request failure.
   */
  fetchDefaultPairFailure: (payload: FetchTerminalPairsFailurePayload) =>
    ({
      type: FETCH_TERMINAL_DEFAULT_PAIR_FAILURE,
      payload,
    } as const),

  /**
   * Select pair.
   *
   * @author Ilya Khachikyan
   * @function fetchPairsFinished
   * @category Actions
   * @subcategory Terminal
   * @param {SelectTerminalPairPayload} payload payload.
   * @return {SELECT_TERMINAL_PAIR} select pair.
   */
  selectPair: (payload: SelectTerminalPairPayload) =>
    ({
      type: SELECT_TERMINAL_PAIR,
      payload,
    } as const),

  /**
   * Reset pair.
   *
   * @author Ilya Khachikyan
   * @function resetPair
   * @category Actions
   * @subcategory Terminal
   * @return {RESET_TERMINAL_PAIR} reset pair.
   */
  resetPair: () =>
    ({
      type: RESET_TERMINAL_PAIR,
    } as const),

  /**
   * Fetch orders request action.
   *
   * @author Ilya Khachikyan
   * @function fetchOrdersRequest
   * @category Actions
   * @subcategory Terminal
   * @return {FETCH_TERMINAL_ORDERS_REQUEST} fetch orders request.
   */
  fetchOrdersRequest: (payload: FetchTerminalOrdersRequestPayload) =>
    ({
      type: FETCH_TERMINAL_ORDERS_REQUEST,
      payload,
    } as const),

  /**
   * Fetch orders request success action.
   *
   * @author Ilya Khachikyan
   * @function fetchOrdersSuccess
   * @category Actions
   * @subcategory Terminal
   * @param {FetchTerminalOrdersSuccessPayload} payload payload.
   * @return {FETCH_TERMINAL_ORDERS_SUCCESS} fetch orders success.
   */
  fetchOrdersSuccess: (payload: FetchTerminalOrdersSuccessPayload) =>
    ({
      type: FETCH_TERMINAL_ORDERS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch orders request failure action.
   *
   * @author Ilya Khachikyan
   * @function fetchOrdersFailure
   * @category Actions
   * @subcategory Terminal
   * @param {FetchTerminalOrdersFailurePayload} payload payload.
   * @return {FETCH_TERMINAL_ORDERS_FAILURE} fetch orders request failure.
   */
  fetchOrdersFailure: (payload: FetchTerminalOrdersFailurePayload) =>
    ({
      type: FETCH_TERMINAL_ORDERS_FAILURE,
      payload,
    } as const),

  /**
   * Reset orders list.
   *
   * @author Ilya Khachikyan
   * @function resetOrders
   * @category Actions
   * @subcategory Terminal
   * @return {RESET_TERMINAL_ORDERS} reset orders.
   */
  resetOrders: () =>
    ({
      type: RESET_TERMINAL_ORDERS,
    } as const),
};
