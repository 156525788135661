import {
  FETCH_PAIRS_FAILURE,
  FETCH_PAIRS_REQUEST,
  FETCH_PAIRS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
} from './statistic.action.types';
import {
  FetchPairsFailurePayload,
  FetchPairsRequestPayload,
  FetchPairsSuccessPayload,
  FetchTransactionsFailurePayload,
  FetchTransactionsSuccessPayload,
} from './statistic.types';

export const actions = {
  /**
   * Fetch pairs request action.
   *
   * @author Ihar Kazlouski
   * @function fetchPairsRequest
   * @category Actions
   * @subcategory Statistic
   * @return {FETCH_PAIRS_REQUEST} fetch pairs request.
   */
  fetchPairsRequest: (payload: FetchPairsRequestPayload) =>
    ({
      type: FETCH_PAIRS_REQUEST,
      payload,
    } as const),

  /**
   * Fetch pairs request success action.
   *
   * @author Ihar Kazlouski
   * @function fetchPairsSuccess
   * @category Actions
   * @subcategory Statistic
   * @param {FetchPairsSuccessPayload} payload payload.
   * @return {FETCH_PAIRS_SUCCESS} fetch pairs success.
   */
  fetchPairsSuccess: (payload: FetchPairsSuccessPayload) =>
    ({
      type: FETCH_PAIRS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch pairs request failure action.
   *
   * @author Ihar Kazlouski
   * @function fetchPairsFailure
   * @category Actions
   * @subcategory Statistic
   * @param {FetchPairsFailurePayload} payload payload.
   * @return {FETCH_PAIRS_FAILURE} fetch pairs request failure.
   */
  fetchPairsFailure: (payload: FetchPairsFailurePayload) =>
    ({
      type: FETCH_PAIRS_FAILURE,
      payload,
    } as const),

  /**
   * Fetch transactions request action.
   *
   * @author Ihar Kazlouski
   * @function fetchTransactionsRequest
   * @category Actions
   * @subcategory Statistic
   * @return {FETCH_TRANSACTIONS_REQUEST} fetch transactions request.
   */
  fetchTransactionsRequest: (networkId: string) =>
    ({
      type:    FETCH_TRANSACTIONS_REQUEST,
      payload: { networkId },
    } as const),

  /**
   * Fetch transactions request success action.
   *
   * @author Ihar Kazlouski
   * @function fetchTransactionsSuccess
   * @category Actions
   * @subcategory Statistic
   * @param {FetchTransactionsSuccessPayload} payload payload.
   * @return {FETCH_TRANSACTIONS_SUCCESS} fetch transactions success.
   */
  fetchTransactionsSuccess: (payload: FetchTransactionsSuccessPayload) =>
    ({
      type: FETCH_TRANSACTIONS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch transactions request failure action.
   *
   * @author Ihar Kazlouski
   * @function fetchPairsFailure
   * @category Actions
   * @subcategory Statistic
   * @param {FetchTransactionsFailurePayload} payload payload.
   * @return {FETCH_TRANSACTIONS_FAILURE} fetch transactions request failure.
   */
  fetchTransactionsFailure: (payload: FetchTransactionsFailurePayload) =>
    ({
      type: FETCH_TRANSACTIONS_FAILURE,
      payload,
    } as const),
};
