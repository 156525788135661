import { Link, styled } from '@mui/material';

export const StyledTxLink = styled(Link)(({ theme }) => {
  return {
    color:      theme.palette.white50.main,
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      color: theme.palette.white.main,
    },
  };
});
