import { AppBar, styled, Toolbar } from '@mui/material';

import { DefaultTheme } from '@types';

import { Button } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledAppBar = styled(AppBar)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:      theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.white10.main}`,
  };

  return styles;
});

const StyledToolbar = styled(Toolbar)(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:        'flex',
    minHeight:      theme.spacing(7.5),
    width:          '100%',
    gap:            theme.spacing(2.5),
    justifyContent: 'space-between',
  };

  return styles;
});

const StyledLogo = styled('img')(() => {
  const styles: OverridesStyleRules = {
    width:  pxToRem(67),
    height: 'auto',
  };

  return styles;
});

const StyledLogoutButton = styled(Button)(({ theme }) => {
  const styles: OverridesStyleRules = {
    gap: theme.spacing(1),
  };

  return styles;
});

export { StyledAppBar, StyledLogo, StyledLogoutButton, StyledToolbar };
