import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { PublicLayoutProps } from '@hocs/layouts/public-layout/public-layout.types';
import { useDeviceSize } from '@hooks';

import { BottomNavigationComponent, PublicHeaderComponent } from '@components';

import { StyledMain, StyledPublicWrapper } from './public-layout.styles';

/**
 * Page hoc component.
 *
 * @author Ihar Kazlouski
 * @function PublicLayout
 * @category Hocs
 * @return {JSX.Element} wrapped component.
 */
const PublicLayout: FC<PublicLayoutProps> = ({ inline }): JSX.Element => {
  const { desktop } = useDeviceSize();

  return (
    <StyledPublicWrapper>
      {desktop && <PublicHeaderComponent />}
      <StyledMain component='main' isMobile={!desktop}>
        {inline ? <Outlet /> : <Outlet />}
      </StyledMain>
      {!desktop && <BottomNavigationComponent />}
    </StyledPublicWrapper>
  );
};

export { PublicLayout };
