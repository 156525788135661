import { ProfileHelperType } from '@popups/profile/profile.types';

import { ProfileTabs } from '@enums';

import arrow from '@images/arrow-right.svg';
import direction from '@images/direction.svg';
import logOut from '@images/log-out.svg';
export const profileHelpers: ProfileHelperType[] = [
  {
    id:       ProfileTabs.Friends,
    name:     'Пригласить друзей',
    disabled: true,
    icon:     direction,
  },
  {
    id:       ProfileTabs.Education,
    name:     'Пройти обучение',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Language,
    name:     'Язык',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Trade,
    name:     'Торговать на другой версии',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Security,
    name:     'Безопасность',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Notifications,
    name:     'Уведомления',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Theme,
    name:     'Тема оформления',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.TradeSettings,
    name:     'Настройки параметров торговли',
    disabled: false,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Help,
    name:     'Документация и поддержка',
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.About,
    disabled: true,
    name:     'О нас',
    icon:     arrow,
  },
  {
    id:       ProfileTabs.Logout,
    name:     'Выйти из аккаунта',
    disabled: false,
    icon:     logOut,
  },
];

export const tradingSettingsHelpers: ProfileHelperType[] = [
  {
    id:       ProfileTabs.TerminalTradingSettings,
    name:     ProfileTabs.TerminalTradingSettings,
    disabled: false,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.SnipingSettings,
    name:     ProfileTabs.SnipingSettings,
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.StrategySettings,
    name:     ProfileTabs.StrategySettings,
    disabled: true,
    icon:     arrow,
  },
  {
    id:       ProfileTabs.CopyTradingSettings,
    name:     ProfileTabs.CopyTradingSettings,
    disabled: true,
    icon:     arrow,
  },
];
