import React, { FC } from 'react';

import { profileHelpers } from '@popups/profile/profile.helpers';
import { ProfileHelperType } from '@popups/profile/profile.types';

import { AccountCircle } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { ProfileTabs } from '@enums';

import { Icon } from '@elements';

const DefaultTab: FC<{
  handleClick: (tab: ProfileHelperType) => () => void;
}> = ({ handleClick }) => {
  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <AccountCircle
          color='white'
          fontSize='inherit'
          sx={{ width: '100px', height: '100px', margin: '0 auto' }}
        />
        <Stack margin='0 auto'>
          <Typography variant='body1' color='white' textAlign='center'>
            Username_13294
          </Typography>
          <Typography variant='body3' color='cornflowerBlue' textAlign='center'>
            Готово
          </Typography>
        </Stack>
      </Stack>
      <Stack pb={1}>
        <List>
          {profileHelpers.map((item, index) => (
            <ListItemButton
              sx={{
                borderBottom:
                  profileHelpers.length - 1 === index
                    ? 'none'
                    : '1px solid #FFFFFF1A',
              }}
              key={item.id}
              onClick={handleClick(item)}
              disabled={item.disabled}
            >
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{
                  fontSize:   '16px',
                  fontWeight: '500',
                  color:      item.id === ProfileTabs.Logout ? '#E55555' : '#FFF',
                  lineHeight: '20px',
                }}
              />
              <ListItemIcon sx={{ minWidth: 'unset' }}>
                <Icon src={item.icon} width={18} height={18} />
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Stack>
    </Stack>
  );
};

export { DefaultTab };
