import { ButtonBase, ButtonBaseProps, Stack, styled } from '@mui/material';

export const StyledTabsContainer = styled(Stack)(({ theme }) => {
  return {
    display:         'flex',
    width:           '100%',
    borderBottom:    `1px solid ${theme.palette.white10.main}`,
    overflow:        'auto',
    msOverflowStyle: 'none',
    scrollbarWidth:  'none',

    [`&::-webkit-scrollbar`]: {
      display: 'none',
    },
  };
});

export const StyledTab = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ButtonBaseProps & { active?: boolean }>(({ theme, active }) => {
  return {
    whiteSpace:  'nowrap',
    padding:     theme.spacing(1.5, 2),
    borderRight: `1px solid ${
      active ? theme.palette.white8.main : theme.palette.white10.main
    }`,
    color:      active ? theme.palette.white.main : theme.palette.white50.main,
    background: active ? theme.palette.white8.main : 'transparent',
    transition: 'all 0.3s ease-in-out',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1),
    },
  };
});
