import { useSelector } from 'react-redux';

import { getPopupsSelector } from '@store/popups/popups.selectors';

import { PopupComponent } from './popup.component';

/**
 * Popups component.
 *
 * @author Ihar Kazlouski
 * @function Popups
 * @category components
 * @return {JSX.Element} popups component.
 */
const Popups = (): JSX.Element => {
  const popups = useSelector(getPopupsSelector);

  return (
    <>
      {popups?.map((popup) => (
        <PopupComponent key={popup.popupId} popupId={popup.popupId} />
      ))}
    </>
  );
};

export { Popups };
