import {
  Box,
  FormHelperText,
  styled,
  Switch as MuiSwitch,
  switchClasses,
  Typography,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledSwitch = styled(MuiSwitch)(({ theme }) => {
  const styles: OverridesStyleRules = {
    width:   theme.spacing(5),
    height:  theme.spacing(3),
    padding: theme.spacing(0),

    [`& .${switchClasses.track}`]: {
      borderRadius: theme.spacing(10),
      background:   theme.palette.white8.main,
      opacity:      1,
    },

    [`& .${switchClasses.switchBase}`]: {
      width:   theme.spacing(2.5),
      height:  theme.spacing(2.5),
      margin:  theme.spacing(0.25),
      padding: theme.spacing(0),
      color:   theme.palette.white.main,

      [`&.${switchClasses.checked}`]: {
        transform: `translateX(${theme.spacing(2)})`,
        color:     theme.palette.white.main,

        [`& + .${switchClasses.track}`]: {
          background: theme.palette.blue.main,
          opacity:    1,
        },
      },
    },
  };

  return styles;
});
const StyledSwitchLabel = styled(Typography)<{
  component: React.ElementType;
  htmlFor?: string;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color:      theme.palette.white.main,
    fontWeight: 500,
  };

  return styles;
});

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:   'absolute',
    left:       0,
    right:      0,
    top:        '100%',
    marginTop:  theme.typography.pxToRem(4),
    fontSize:   theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex:     1,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:  'block',
    padding:  theme.spacing(0),
    margin:   'unset',
    maxWidth: '100%',
    color:    theme.palette.lightCoral.main,
  };

  return styles;
});

export {
  StyledFormHelperErrorText,
  StyledSwitch,
  StyledSwitchLabel,
  StyleFormHelperWrapper,
};
