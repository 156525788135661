export const FETCH_TERMINAL_PAIRS_REQUEST = 'FETCH_TERMINAL_PAIRS_REQUEST';
export const FETCH_TERMINAL_PAIRS_SUCCESS = 'FETCH_TERMINAL_PAIRS_SUCCESS';
export const FETCH_TERMINAL_PAIRS_FAILURE = 'FETCH_TERMINAL_PAIRS_FAILURE';
export const RESET_TERMINAL_PAIRS = 'RESET_TERMINAL_PAIRS';

export const FETCH_TERMINAL_DEFAULT_PAIR_REQUEST =
  'FETCH_TERMINAL_DEFAULT_PAIR_REQUEST';
export const FETCH_TERMINAL_DEFAULT_PAIR_FAILURE =
  'FETCH_TERMINAL_DEFAULT_PAIR_FAILURE';

export const SELECT_TERMINAL_PAIR = 'SELECT_TERMINAL_PAIR';
export const RESET_TERMINAL_PAIR = 'RESET_TERMINAL_PAIR';

export const FETCH_TERMINAL_ORDERS_REQUEST = 'FETCH_TERMINAL_ORDERS_REQUEST';
export const FETCH_TERMINAL_ORDERS_SUCCESS = 'FETCH_TERMINAL_ORDERS_SUCCESS';
export const FETCH_TERMINAL_ORDERS_FAILURE = 'FETCH_TERMINAL_ORDERS_FAILURE';
export const RESET_TERMINAL_ORDERS = 'RESET_TERMINAL_ORDERS';
