import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';

import { getFormIdSelector } from '@store/forms/forms.selectors';
import { FormStateUpdatePayload } from '@store/forms/forms.types';

import { FormsService } from '@services';

import { FormReduxProps } from './form-redux.types';

/**
 * FormRedux component.
 *
 * @author Ihar Kazlouski
 * @function Form
 * @category components
 * @return {JSX.Element} button group component.
 */
const FormRedux = ({ formId, ...restProps }: FormReduxProps): JSX.Element => {
  const isFormExist = useSelector(getFormIdSelector(formId));

  useEffect(() => {
    if (!isFormExist) {
      FormsService.createForm({ formId });
    }
    return () => {
      if (isFormExist) {
        FormsService.deleteForm({ formId });
      }
    };
  }, [formId, isFormExist]);

  return (
    <>
      {isFormExist && (
        <FormSpy
          onChange={(state): void => {
            const forms = state as FormStateUpdatePayload;
            setTimeout(() => FormsService.updateForm({ formId, ...forms }), 0);
          }}
          {...restProps}
        />
      )}
    </>
  );
};

export { FormRedux };
