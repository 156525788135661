import { Button as MuiButton, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { ButtonComponentProps } from './button.types';

const StyledButton = styled(MuiButton)<ButtonComponentProps>(({ size }) => {
  const styles: OverridesStyleRules = {
    padding:       `${pxToRem(10)} ${pxToRem(20)}`,
    borderRadius:  pxToRem(10),
    fontSize:      pxToRem(14),
    textTransform: 'none',
  };

  switch (size) {
    case 'small': {
      styles.padding = `${pxToRem(8)} ${pxToRem(12)}`;
      styles.fontSize = pxToRem(14);
      break;
    }
  }

  return styles;
});

export { StyledButton };
