import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { pxToRem } from '@themes';
import {
  StyledFilledInput,
  StyledFormControl,
} from '../form/fields/text-field/text-field.styles';

const StyledPinWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ theme: DefaultTheme; isError: boolean }>(({ theme, isError }) => ({
  display:                    'flex',
  gap:                        pxToRem(8),
  padding:                    `0 ${theme.spacing(1)}`,
  position:                   'relative',
  [`& ${StyledFilledInput}`]: {
    width:                pxToRem(48.5),
    height:               pxToRem(72),
    background:           'transparent',
    'input::placeholder': {
      color:       theme.colors.white,
      paddingLeft: theme.spacing(0.25),
      opacity:     1,
    },
  },
  [`& ${StyledFormControl}`]: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const StyledTextFieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasInputValue',
})<{ theme: DefaultTheme; hasInputValue?: boolean }>(
  ({ theme, hasInputValue = false }) => ({
    position:  'relative',
    '&:after': {
      content:         hasInputValue ? "''" : '',
      display:         'block',
      width:           pxToRem(16),
      height:          pxToRem(16),
      borderRadius:    '50%',
      position:        'absolute',
      top:             '50%',
      left:            '50%',
      backgroundColor: theme.colors.white,
      transform:       'translateX(-50%) translateY(-50%)',
    },
    input: {
      opacity: 0,
    },
  }),
);

const StyledErrorText = styled(Box)<{ theme: DefaultTheme }>(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  color:      theme.colors.mirage,
  whiteSpace: 'pre-wrap',
}));

const StyledContainer = styled(Box)<{ theme: DefaultTheme }>(({ theme }) => ({
  display:        'flex',
  justifyContent: 'space-around',
}));

const StyledDash = styled(Box)<{ theme: DefaultTheme }>(({ theme }) => ({
  width:           pxToRem(8),
  height:          pxToRem(2),
  margin:          'auto 0',
  marginLeft:      pxToRem(-8),
  backgroundColor: theme.colors.mirage,
}));

export {
  StyledContainer,
  StyledDash,
  StyledErrorText,
  StyledPinWrapper,
  StyledTextFieldWrapper,
};
