import { Form as FinalForm } from 'react-final-form';

import { validator } from './form.helper';
import { FormProps } from './form.types';

/**
 * Form component.
 *
 * @author Ihar Kazlouski
 * @function Form
 * @category components
 * @return {JSX.Element} button group component.
 */
export const Form = <FormValues extends object>({
  validateSchema,
  onSubmit = (): void => undefined,
  ...restProps
}: FormProps<FormValues>): JSX.Element => {
  const validate = validateSchema && validator(validateSchema);

  return <FinalForm validate={validate} onSubmit={onSubmit} {...restProps} />;
};
