import { TerminalTradingSettingsTabs } from '@enums';

export const tradingSettingsTabs = [
  {
    value: TerminalTradingSettingsTabs.LIMIT_BUY,
    label: 'Лимитная покупка',
  },
  {
    value: TerminalTradingSettingsTabs.LIMIT_SELL,
    label: 'Лимитная продажа',
  },
  {
    value: TerminalTradingSettingsTabs.MARKET_BUY,
    label: 'Маркет-покупка',
  },
  {
    value: TerminalTradingSettingsTabs.MARKET_SELL,
    label: 'Маркет-продажа',
  },
  {
    value: TerminalTradingSettingsTabs.APPROVE,
    label: 'Approve',
  },
];
