import React, { FC } from 'react';

import InfoIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';

import { Popup, PopupProps } from '@elements';

import { StyledContent, StyledHeader } from './success.styles';

/**
 * SuccessPopup component.
 *
 * @author Ihar Kazlouski
 * @function SuccessPopup
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const SuccessPopup: FC = ({ rejectPopup, popUp }: PopupProps): JSX.Element => {
  // ToDo: get success text from server or other source
  const header = popUp && popUp.data && popUp.data.header;
  const body = popUp && popUp.data && popUp.data.body;
  return (
    <Popup width={422} fullWidth popUp={popUp}>
      <Popup.Title>
        <StyledHeader>
          <InfoIcon
            fontSize='large'
            color='success'
            sx={{
              height: 48,
              width:  48,
            }}
          />
        </StyledHeader>
      </Popup.Title>
      <Popup.Content>
        <StyledContent flexDirection='column'>
          {header && (
            <Typography color='textPrimary' variant='h4' textAlign='center'>
              {header}
            </Typography>
          )}
          {body && (
            <Typography
              color='textPrimary'
              variant='body1'
              textAlign='center'
              pt={header ? 2 : undefined}
            >
              {body}
            </Typography>
          )}
        </StyledContent>
      </Popup.Content>
    </Popup>
  );
};

export { SuccessPopup };
