import { createSelector } from 'reselect';

import { AppState } from '@store/root.reducer';
import { Selector, SnackbarsState } from '@store/snackbars/snackbars.types';

/**
 * Get snackbars array.
 *
 * @author Ihar Kazlouski
 * @function getSnackbars
 * @category Selectors
 * @subcategory Snackbars
 * @param {AppState} state state.
 * @return {SnackbarsState[]} snackbars state.
 */
const getSnackbars = (state: AppState): SnackbarsState[] => {
  return state.snackbars;
};

export const getSnackbarsSelector = createSelector(
  getSnackbars,
  (snackbars) => snackbars,
);

export const getSnackbarSelector = (
  snackbarId: string,
): Selector<SnackbarsState | undefined> => {
  return createSelector(getSnackbars, (snackbars) => {
    return snackbars.find((snackbar) => snackbar.snackbarId === snackbarId);
  });
};

export const getSnackbarOpenStateSelector = (
  snackbarId: string,
): Selector<SnackbarsState['isOpen']> => {
  return createSelector([getSnackbarSelector(snackbarId)], (snackbar) => {
    return snackbar?.isOpen || false;
  });
};
