import axios from 'axios';

import { AuthService } from '@services/auth';
import { ErrorInterceptor } from '@interceptors';

import {
  ApiError,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
} from '@types';

export const http = axios.create({
  baseURL:       process.env.baseUrl,
  errorRedirect: true,
  // paramsSerializer: { encode: (value: string) => value },
} as CreateAxiosDefaults);

http.interceptors.request.use((request) => {
  const token = AuthService.getTokenSessionStorage();
  if (request && request.headers && token) {
    request.headers.Authorization = token ? `Bearer ${token}` : false;
  }
  return request;
});

http.interceptors.request.use(
  (request: AxiosRequestConfig) => request,
  ErrorInterceptor,
);
http.interceptors.response.use(
  (response: AxiosResponse) => response,
  ErrorInterceptor,
);

/**
 * Api service.
 *
 * @author Ihar Kazlouski
 * @class ApiServiceClass
 * @category Services
 */
class ApiServiceClass {
  /**
   * Api get function.
   *
   * @author Ihar Kazlouski
   * @param {string} path path.
   * @param {AxiosRequestConfig} props AxiosRequestConfig props.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiGet (
    path: string,
    props?: Omit<AxiosRequestConfig, 'headers'>,
  ): Promise<ApiError | AxiosResponse> {
    return await http.get(path, props);
  }

  /**
   * Api post function.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown> | FormData | T} body body.
   * @param {AxiosRequestConfig} props AxiosRequestConfig props.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiPost<T>(
    path: string,
    body: Record<string, unknown> | FormData | T,
    props?: Omit<AxiosRequestConfig, 'headers'>,
  ): Promise<ApiError | AxiosResponse> {
    return await http.post(path, body, props);
  }

  /**
   * Api put function.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown> | FormData | T} body body.
   * @param {AxiosRequestConfig} props AxiosRequestConfig props.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiPut<T>(
    path: string,
    body: Record<string, unknown> | FormData | T,
    props?: Omit<AxiosRequestConfig, 'headers'>,
  ): Promise<ApiError | AxiosResponse> {
    return await http.put(path, body, props);
  }

  /**
   * Api patch function.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown> | FormData | T} body body.
   * @param {AxiosRequestConfig} props AxiosRequestConfig props.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiPatch<T>(
    path: string,
    body: Record<string, unknown> | FormData | T,
    props?: Omit<AxiosRequestConfig, 'headers'>,
  ): Promise<ApiError | AxiosResponse> {
    return await http.patch(path, body, props);
  }

  /**
   * Api delete function.
   *
   * @author Ihar Kazlouski
   * @param {Record<string, unknown> | FormData | T} body body.
   * @param {AxiosRequestConfig} props AxiosRequestConfig props.
   * @return {Promise<ApiError | AxiosResponse>} data promise.
   */
  async apiDelete<T>(
    path: string,
    body?: Record<string, unknown> | FormData | T,
    props?: Omit<AxiosRequestConfig, 'headers'>,
  ): Promise<ApiError | AxiosResponse> {
    return await http.delete(path, {
      ...(body && { data: body }),
      ...props,
    });
    // return await http.delete(path, {data: { body } } as AxiosRequestConfig);
  }
}

const ApiService = new ApiServiceClass();

export { ApiService };
