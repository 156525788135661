// eslint-disable-next-line import/no-named-as-default
import { DefaultResources, initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

import en from './en.localization.json';
import ru from './ru.localization.json';

const getTranslateConfig = (): DefaultResources => ({
  resources: {
    ru,
    en,
  },
  lang:        'ru',
  fallbackLng: 'ru',
  debug:       false,
  detection:   {
    order:  ['localStorage', 'cookie'],
    caches: ['localStorage', 'cookie'],
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

// eslint-disable-next-line import/no-named-as-default-member
void i18n.use(detector).use(initReactI18next).init(getTranslateConfig());

export { i18n };
