import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { Icon, Popup, PopupProps } from '@elements';

import wallet from '@images/wallet-circle.svg';

/**
 * FollowInstructionsComponent component.
 *
 * @author Ihar Kazlouski
 * @function FollowInstructionsComponent
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const FollowInstructionsComponent: FC = ({
  popUp,
}: PopupProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Popup width={400} fullWidth popUp={popUp}>
      <Popup.Title>
        <div />
      </Popup.Title>
      <Popup.Content>
        <Stack gap={2.375} alignItems='center'>
          <Icon src={wallet} width={84} height={84} />
          <Stack gap={1} alignItems='center'>
            <Typography variant='body1' color='white'>
              {t('components.popups.followInstructions.title')}
            </Typography>
            <Typography variant='body1' color='white50' textAlign='center'>
              {t('components.popups.followInstructions.followInstructions')}
            </Typography>
          </Stack>
        </Stack>
      </Popup.Content>
    </Popup>
  );
};

export { FollowInstructionsComponent };
