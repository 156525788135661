import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Skeleton, Stack, Typography } from '@mui/material';

import { formatWithSubscript } from '@utils';
import {
  formatDateToDatetimeStr,
  getBlockExplorerAccountUrl,
  getBlockExplorerTxUrl,
  getCompactAmount,
  getNetworkInfo,
  getShortBlockchainHash,
  getTrasactionTypeInfo,
  isDefined,
} from '@helpers';

import { TransactionDirection } from '@enums';

import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@elements';

import { StyledTxLink } from './swap-transactions-table.styles';

import { SwapTransactionsTableProps } from './swap-transactions-table.types';

export const SwapTransactionsTableComponent: React.FC<
SwapTransactionsTableProps
> = ({ network, transactions, isLoading, perPage }) => {
  const { t, i18n } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('tables.swapTransactions.columns.date')}</TableCell>
          <TableCell>{t('tables.swapTransactions.columns.type')}</TableCell>
          <TableCell>{t('tables.swapTransactions.columns.price')}</TableCell>
          <TableCell>{t('tables.swapTransactions.columns.amount')}</TableCell>
          <TableCell>{t('tables.swapTransactions.columns.total')}</TableCell>
          <TableCell>
            {t('tables.swapTransactions.columns.initiator')}
          </TableCell>
          <TableCell>{t('tables.swapTransactions.columns.txLink')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading &&
          Array(perPage)
            .fill(null)
            .map((_, index) => (
              <TableRow key={index}>
                <TableCell colSpan={7}>
                  <Skeleton variant='rounded' height={34} width='100%' />
                </TableCell>
              </TableRow>
            ))}
        {!isLoading && isDefined(transactions) && transactions.length === 0 && (
          <TableRow>
            <TableCell colSpan={7} align='center'>
              {t('tables.swapTransactions.noData')}
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          isDefined(transactions) &&
          transactions.length > 0 &&
          isDefined(network) &&
          transactions?.map((transaction) => {
            const transactionTypeInfo = getTrasactionTypeInfo(transaction.type);

            return (
              <TableRow key={transaction.id}>
                <TableCell>
                  {formatDateToDatetimeStr(
                    i18n.language,
                    transaction.created_at,
                  )}
                </TableCell>

                <TableCell>
                  <Typography
                    variant='inherit'
                    color={
                      transactionTypeInfo.direction === TransactionDirection.In
                        ? 'mediumSeaGreen'
                        : 'lightCoral'
                    }
                  >
                    {t(transactionTypeInfo.i18nLabelKey)}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Stack>
                    <Typography
                      variant='inherit'
                      fontWeight={500}
                      whiteSpace='nowrap'
                    >
                      {formatWithSubscript(+transaction.price)}{' '}
                      {network?.native_symbol}
                    </Typography>
                    <Typography
                      variant='inherit'
                      fontWeight={500}
                      color='white50'
                    >
                      ${formatWithSubscript(+transaction.price_usd)}
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell>
                  {getCompactAmount(
                    i18n.language,
                    transactionTypeInfo.direction === TransactionDirection.In
                      ? transaction.amount_in
                      : transaction.amount_out,
                  )}
                </TableCell>

                <TableCell>
                  <Stack>
                    <Typography
                      variant='inherit'
                      fontWeight={500}
                      whiteSpace='nowrap'
                    >
                      {formatWithSubscript(
                        parseFloat(transaction.price) *
                          parseFloat(
                            transactionTypeInfo.direction ===
                              TransactionDirection.In
                              ? transaction.amount_in
                              : transaction.amount_out,
                          ),
                      )}{' '}
                      {network?.native_symbol}
                    </Typography>
                    <Typography
                      variant='inherit'
                      fontWeight={500}
                      color='white50'
                    >
                      $
                      {formatWithSubscript(
                        parseFloat(transaction.price_usd) *
                          parseFloat(
                            transactionTypeInfo.direction ===
                              TransactionDirection.In
                              ? transaction.amount_in
                              : transaction.amount_out,
                          ),
                      )}
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell>
                  <Link
                    href={getBlockExplorerAccountUrl(
                      network.name,
                      transaction.from_address,
                    )}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <Typography color='blue' variant='body3' fontWeight={500}>
                      {getShortBlockchainHash(transaction.from_address)}
                    </Typography>
                  </Link>
                </TableCell>

                <TableCell align='right'>
                  <StyledTxLink
                    href={getBlockExplorerTxUrl(
                      network.name,
                      transaction.transaction_hash,
                    )}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <Icon
                      src={getNetworkInfo(network.name).blockExplorer.icon}
                      width={20}
                      height={20}
                    />
                  </StyledTxLink>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
