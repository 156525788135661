import { FC } from 'react';

import { Popover } from '@mui/material';

import { ArrowStyle } from './menu-popover.styles';

import { MenuPopoverProps } from './menu-popover.types';

/**
 * Menu Popover component.
 *
 * @author Ihar Kazlouski
 * @function MenuPopover
 * @category components
 * @param {MenuPopoverProps} props menu popover.
 * @return {FC} Menu Popover component.
 */
const MenuPopover: FC<MenuPopoverProps> = ({ children, sx, ...otherProps }) => {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p:        1,
          width:    200,
          overflow: 'inherit',
          ...sx,
        },
      }}
      {...otherProps}
    >
      <ArrowStyle className='arrow' />
      {children}
    </Popover>
  );
};

export { MenuPopover };
