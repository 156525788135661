import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccount, useConnect, useEnsName } from 'wagmi';
import { getAccount, signMessage } from '@wagmi/core';

import { Link, Stack, Typography } from '@mui/material';

import { AuthService, PopupsService } from '@services';
import { config, createRandomString } from '@utils';

import { Authentication, Popups, Routes } from '@enums';
import { AuthenticationResponse, AxiosResponse } from '@types';

import { Button, Icon, Popup, PopupProps } from '@elements';

import telegram from '@images/telegram.svg';
import walletConnect from '@images/walletconnect.svg';

/**
 * RegistrationPopup component.
 *
 * @author Ihar Kazlouski
 * @function RegistrationPopup
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const RegistrationPopup: FC = ({ popUp }: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  const { connectors, connect } = useConnect();
  const header = popUp && popUp.data && popUp.data.header;
  const { connector } = getAccount(config);
  const { address } = useAccount();
  const { status } = useEnsName({ address });
  const navigate = useNavigate();

  const handleCheck = async (): Promise<void> => {
    const message = createRandomString(12);
    void PopupsService.open(Popups.FollowInstructions);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const signature = await signMessage(config, {
      connector,
      message: message,
    });

    void PopupsService.close(Popups.FollowInstructions);
    const payload = {
      message,
      signature: signature,
      address:   address,
      source:    'EVM',
    };

    const resp = (await AuthService.apiAuthPost(
      payload,
    )) as AxiosResponse<AuthenticationResponse>;

    if (resp.data.code === Authentication.New) {
      navigate(Routes.Mnemonic);
    } else {
      navigate(Routes.Pin);
    }
  };

  useEffect(() => {
    if (status === 'success') {
      void handleCheck();
    }
  }, [status]);

  return (
    <Popup width={400} fullWidth popUp={popUp}>
      <Popup.Title>
        {header || t('components.popups.registration.title')}
      </Popup.Title>
      <Popup.Content>
        <Stack display='flex' flexDirection='column' gap={2.375} mt={5}>
          <Stack display='flex' flexDirection='column' gap={1.5}>
            <Button
              variant='outlined'
              size='large'
              onClick={(): void => {
                window.open(
                  'https://t.me/graphdex_terminal_test_bot',
                  '_self',
                  'noreferrer',
                );
              }}
            >
              <Icon src={telegram} width={24} height={24} />
              <Typography variant='body1' fontWeight='500' ml={1} color='white'>
                Telegram
              </Typography>
            </Button>
            <Button
              variant='outlined'
              onClick={(): void => {
                void PopupsService.close(Popups.Registration);
                connect({ connector: connectors[0] });
              }}
            >
              <Icon src={walletConnect} width={24} height={24} />
              <Typography variant='body1' fontWeight='500' ml={1} color='white'>
                WalletConnect
              </Typography>
            </Button>
          </Stack>
          {!header && (
            <Link
              sx={{ margin: '0 auto' }}
              onClick={(): void => {
                void PopupsService.close(Popups.Registration);
                void PopupsService.open(Popups.Registration, {
                  header: 'Вход',
                  body:   '',
                });
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('components.popups.registration.alreadyHaveAccount')}
            </Link>
          )}
        </Stack>
      </Popup.Content>
    </Popup>
  );
};

export { RegistrationPopup };
