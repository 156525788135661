import { useCallback } from 'react';
import { DayPickerProps as DefaultDayPickerProps } from 'react-day-picker';

import { DatePickerProps } from '@elements';

import { StyledDayPicker } from './day-picker.styles';

import { DayPickerCaption } from './components/day-picker-caption';
import { DayPickerFooter } from './components/day-picker-footer';
import { getLocaleFile } from './day-picker.helpers';

const DayPicker = ({
  locale,
  localization,
  ...restProps
}: DefaultDayPickerProps & {
  localization?: DatePickerProps['localization'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedDate: (value: any) => void;
}): JSX.Element => {
  const Footer = useCallback(() => {
    return <DayPickerFooter {...restProps} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.setSelectedDate]);

  return (
    <StyledDayPicker
      {...restProps}
      locale={locale || getLocaleFile(localization)}
      components={{
        Caption: DayPickerCaption,
        Footer,
      }}
    />
  );
};

export { DayPicker };
