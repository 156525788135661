import { Backdrop, Dialog, Paper, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledDialog = styled(Dialog)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {};

  return styles;
});

const StyledPaper = styled(Paper)<{ theme?: DefaultTheme; width?: number }>(
  ({ theme, width }) => {
    const styles: OverridesStyleRules = {
      borderRadius:           theme.typography.pxToRem(12),
      maxWidth:               width ? theme.typography.pxToRem(width) : undefined,
      backgroundColor:        theme.colors.mirage,
      '&::-webkit-scrollbar': {
        width: '1px',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        background:   '#9E9E9E',
        borderRadius: '12px',
      },
      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
        width:  `calc(100% - ${theme.typography.pxToRem(32)})`,
      },
    };

    return styles;
  },
);

const StyledBackDrop = styled(Backdrop)<{
  theme?: DefaultTheme;
  width?: number;
}>(({ theme, width }) => {
  const styles: OverridesStyleRules = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backdropFilter:  'blur(10px)',
  };

  return styles;
});

export { StyledBackDrop, StyledDialog, StyledPaper };
