export const FETCH_WALLETS_REQUEST = 'FETCH_WALLETS_REQUEST';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_FAILURE = 'FETCH_WALLETS_FAILURE';

export const CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const UPDATE_WALLET_REQUEST = 'UPDATE_WALLET_REQUEST';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';
export const UPDATE_WALLET_FAILURE = 'UPDATE_WALLET_FAILURE';

export const DELETE_WALLET_REQUEST = 'DELETE_WALLET_REQUEST';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_FAILURE = 'DELETE_WALLET_FAILURE';
