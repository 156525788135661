import * as React from 'react';

import { StyledButtonGroup } from './button-group.styles';

import { ButtonGroupProps } from './button-group.types';

/**
 * Button group component.
 *
 * @author Ihar Kazlouski
 * @function ButtonGroup
 * @category components
 * @return {FC} button group component.
 */
const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  (props, ref) => <StyledButtonGroup ref={ref} {...props} />,
);

ButtonGroup.displayName = 'ButtonGroup';

export { ButtonGroup };
