import { Stack } from '@mui/material';

import { Button } from '@elements/button';

import { StyledRangeButtonsWrapper } from './day-picker-range-buttons.styles';

import { getButtonProps } from './day-picker-range-buttons.helpers';
import {
  DayPickerRangeButtonsProps,
  RangeButtonProps,
} from './day-picker-range-buttons.types';

/**
 * Date Picker range buttons.
 *
 * @author Ihar Kazlouski
 * @function DayPickerButtons
 * @category components
 * @param {DayPickerRangeButtonsProps} params day picker range buttons params.
 * @return {JSX.Element} DatePicker range buttons.
 */
const RangeButtons = ({
  range,
  selectedRange,
  handleButtonRange,
}: DayPickerRangeButtonsProps): JSX.Element => (
  <StyledRangeButtonsWrapper>
    <Stack direction='row' spacing={1} alignItems='center'>
      {range.map(({ label, to, from }: RangeButtonProps): JSX.Element => {
        const buttonProps = getButtonProps({ to, from }, selectedRange);
        return (
          <Button
            key={label}
            variant='contained'
            onClick={handleButtonRange({ to, from })}
            {...buttonProps}
          >
            {label}
          </Button>
        );
      })}
    </Stack>
  </StyledRangeButtonsWrapper>
);

export { RangeButtons };
