import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import {
  StyledButton,
  StyledModalButtonsContainer,
} from './modal-buttons.styled';
import { ModalButtonsProps } from './modal-buttons.types';

/**
 * Modal Buttons component.
 *
 * @author Ihar Kazlouski
 * @function ModalButtons
 * @category components
 * @param {ModalButtonsProps} content props.
 * @return {JSX.Element} Modal Buttons component.
 */
const ModalButtons: FC<ModalButtonsProps> = ({
  rejectButton,
  acceptButton,
  acceptModal,
  rejectModal,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledModalButtonsContainer>
      <Grid container spacing={2} justifyContent='flex-end' alignItems='center'>
        <Grid item xs={12} sm='auto' order={{ xs: 2, sm: 1 }}>
          <StyledButton color='inherit' fullWidth onClick={rejectModal}>
            {rejectButton || t('modals.buttons.cancel')}
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm='auto' order={{ xs: 1, sm: 2 }}>
          <StyledButton
            color='primary'
            variant='contained'
            fullWidth
            onClick={acceptModal}
          >
            {acceptButton || t('modals.buttons.save')}
          </StyledButton>
        </Grid>
      </Grid>
    </StyledModalButtonsContainer>
  );
};

ModalButtons.displayName = 'ModalButtons';

export { ModalButtons };
