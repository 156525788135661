import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { FETCH_NETWORKS_REQUEST } from '@store/network/network.action.types';
import { NetworkResponse } from '@store/network/network.types';

import { NetworkService } from '@services';
import { ConstantsUtil } from '@utils';

import { ApiError, AxiosResponse } from '@types';

import { actions } from './network.actions';

/**
 * Get networks saga worker.
 *
 * @author Ihar Kazlouski
 * @function getNetworksSagaWorker
 * @category Sagas
 * @subcategory Network
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getNetworksSagaWorker (): Generator<
PutEffect | CallEffect,
void,
ApiError & AxiosResponse<NetworkResponse>
> {
  try {
    const { data } = yield call(NetworkService.apiNetworksGet);

    yield put(
      actions.fetchNetworksSuccess({
        networks: data,
      }),
    );

    yield put({
      type:    `${FETCH_NETWORKS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.fetchNetworksFailure({
        error: error as ApiError,
      }),
    );

    yield put({
      type:    `${FETCH_NETWORKS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload: error as ApiError,
    });
  }
}

/**
 * Get networks saga.
 *
 * @author Ihar Kazlouski
 * @function getNetworksSagaWatcher
 * @category Sagas
 * @subcategory Network
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getNetworksSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FETCH_NETWORKS_REQUEST, getNetworksSagaWorker)]);
}

/**
 * Network saga.
 *
 * @author Ihar Kazlouski
 * @function walletSaga
 * @category Sagas
 * @subcategory Network
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* networkSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([fork(getNetworksSagaWatcher)]);
}
