import { Box, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledIconButtonWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginRight: theme.spacing(-1.5),
    marginTop:   theme.spacing(-1),
  };

  return styles;
});

export { StyledIconButtonWrapper };
