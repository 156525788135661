import { styled, Typography, TypographyProps } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPopupTitleTypography = styled(Typography)<
TypographyProps & {
  theme: DefaultTheme;
}
>(({ theme }) => {
  const styles: OverridesStyleRules = {
    [theme.breakpoints.down('lg')]: {
      fontSize:   theme.typography.pxToRem(17),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 500,
    },
  };

  return styles;
});

export { StyledPopupTitleTypography };
