import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Box, styled } from '@mui/material';
import { pxToRem } from '@themes';
import { StyledFilledInput, StyledFormControl } from '../form/fields/text-field/text-field.styles';
var StyledPinWrapper = styled(Box, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'isError';
  }
})(function (_ref) {
  var theme = _ref.theme,
    isError = _ref.isError;
  return _defineProperty(_defineProperty({
    display: 'flex',
    gap: pxToRem(8),
    padding: "0 ".concat(theme.spacing(1)),
    position: 'relative'
  }, "& ".concat(StyledFilledInput), {
    width: pxToRem(48.5),
    height: pxToRem(72),
    background: 'transparent',
    'input::placeholder': {
      color: theme.colors.white,
      paddingLeft: theme.spacing(0.25),
      opacity: 1
    }
  }), "& ".concat(StyledFormControl), {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  });
});
var StyledTextFieldWrapper = styled(Box, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'hasInputValue';
  }
})(function (_ref3) {
  var theme = _ref3.theme,
    _ref3$hasInputValue = _ref3.hasInputValue,
    hasInputValue = _ref3$hasInputValue === void 0 ? false : _ref3$hasInputValue;
  return {
    position: 'relative',
    '&:after': {
      content: hasInputValue ? "''" : '',
      display: 'block',
      width: pxToRem(16),
      height: pxToRem(16),
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: theme.colors.white,
      transform: 'translateX(-50%) translateY(-50%)'
    },
    input: {
      opacity: 0
    }
  };
});
var StyledErrorText = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  return _objectSpread(_objectSpread({}, theme.typography.body2), {}, {
    fontWeight: 500,
    color: theme.colors.mirage,
    whiteSpace: 'pre-wrap'
  });
});
var StyledContainer = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  return {
    display: 'flex',
    justifyContent: 'space-around'
  };
});
var StyledDash = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  return {
    width: pxToRem(8),
    height: pxToRem(2),
    margin: 'auto 0',
    marginLeft: pxToRem(-8),
    backgroundColor: theme.colors.mirage
  };
});
export { StyledContainer, StyledDash, StyledErrorText, StyledPinWrapper, StyledTextFieldWrapper };