import { createSelector } from 'reselect';

import { PopupsState, Selector } from '@store/popups/popups.types';
import { AppState } from '@store/root.reducer';

/**
 * Get popups array.
 *
 * @author Ihar Kazlouski
 * @function getPopups
 * @category Selectors
 * @subcategory Popups
 * @param {AppState} state state.
 * @return {PopupsState[]} popups state.
 */
const getPopups = (state: AppState): PopupsState[] => {
  return state.popups;
};

export const getPopupsSelector = createSelector(getPopups, (popups) => popups);

export const getPopupSelector = (
  popupId: string,
): Selector<PopupsState | undefined> => {
  return createSelector(getPopups, (popups) => {
    return popups.find((popup) => popup.popupId === popupId);
  });
};

export const getPopupOpenStateSelector = (
  popupId: string,
): Selector<PopupsState['isOpen']> => {
  return createSelector([getPopupSelector(popupId)], (popup) => {
    return popup?.isOpen || false;
  });
};
