import { OrderSide, OrderStatus, OrderStatusFilter } from '@enums';

export const OrderSideConfig = {
  [OrderSide.Buy]: {
    i18nLabelKey: 'common.orderSide.buy',
  },
  [OrderSide.Sell]: {
    i18nLabelKey: 'common.orderSide.buy',
  },
} as const;

export const OrderStatusConfig = {
  [OrderStatus.Created]: {
    i18nLabelKey: 'common.orderStatus.created',
    color:        'blue',
  },
  [OrderStatus.Process]: {
    i18nLabelKey: 'common.orderStatus.process',
    color:        'blue',
  },
  [OrderStatus.WaitingForResult]: {
    i18nLabelKey: 'common.orderStatus.waitingForResult',
    color:        'blue',
  },
  [OrderStatus.Completed]: {
    i18nLabelKey: 'common.orderStatus.completed',
    color:        'mediumSeaGreen',
  },
  [OrderStatus.Cancelled]: {
    i18nLabelKey: 'common.orderStatus.cancelled',
    color:        'lightCoral',
  },
  [OrderStatus.Failed]: {
    i18nLabelKey: 'common.orderStatus.failed',
    color:        'lightCoral',
  },
} as const;

export const OrderStatusFilterConfig = {
  [OrderStatusFilter.Active]: {
    i18nLabelKey: 'common.orderStatusFilter.active',
    statuses:     [
      OrderStatus.Created,
      OrderStatus.Process,
      OrderStatus.WaitingForResult,
    ],
  },
  [OrderStatusFilter.Completed]: {
    i18nLabelKey: 'common.orderStatusFilter.completed',
    statuses:     [OrderStatus.Completed, OrderStatus.Cancelled],
  },
  [OrderStatusFilter.Failed]: {
    i18nLabelKey: 'common.orderStatusFilter.failed',
    statuses:     [OrderStatus.Failed],
  },
} as const;

export const getOrderSideInfo = (
  side: OrderSide,
): {
  i18nLabelKey: string;
} => {
  return OrderSideConfig[side];
};

export const getOrderStatusInfo = (
  status: OrderStatus,
): {
  color: string;
  i18nLabelKey: string;
} => {
  return OrderStatusConfig[status];
};

export const getOrderStatusesByFilters = (
  orderStatusFilters: OrderStatusFilter[],
): OrderStatus[] => {
  return orderStatusFilters.reduce((acc, filter): OrderStatus[] => {
    return [...acc, ...OrderStatusFilterConfig[filter].statuses];
  }, [] as OrderStatus[]);
};
