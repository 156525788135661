import { dispatch as storeDispatch } from '@store';
import { actions, PopUpData, PopupPayload } from '@store/popups';

import { AsyncDispatch } from '@types';

/**
 * Popups controller.
 *
 * @author Ihar Kazlouski
 * @const PopupsServiceClass
 * @category Services
 * @subcategory Popups
 */
class PopupsServiceClass {
  /**
   * Open popup.
   *
   * @author Ihar Kazlouski
   * @param {string} popupId popupId.
   * @param {T} data popup data.
   * @return {void}
   */
  open (popupId: string, data?: PopUpData): Promise<PopupPayload | Error> {
    const dispatch: AsyncDispatch<PopupPayload | Error> = storeDispatch;

    const popupAction = dispatch(
      actions.popupInit({
        popupId,
        data,
        isOpen: true,
      }),
    );

    popupAction.catch((error: Error): Error => error);

    return popupAction;
  }

  /**
   * Close popup.
   *
   * @author Ihar Kazlouski
   * @param {string} popupId popupId.
   * @param {Dispatch} dispatch dispatch.
   * @return {void}
   */
  close (popupId: string): void {
    storeDispatch(actions.popupInitClosing({ popupId }));
  }

  /**
   * Close popup with answer.
   *
   * @author Ihar Kazlouski
   * @param {string} popupId popupId.
   * @param {Dispatch} dispatch dispatch.
   * @param {object} answer answer.
   * @return {void}
   */
  closeWithAnswer (
    popupId: string,
    { accepted = false, rejected = false },
  ): void {
    storeDispatch(
      actions.popupInitClosingWithAnswer({
        popupId,
        accepted,
        rejected,
      }),
    );
  }
}

const PopupsService = new PopupsServiceClass();

export { PopupsService };
