export function formatWithSubscript (num: number): string {
  if (Math.floor(num) === num) {
    return num.toString();
  }
  // Convert number to string with fixed precision, then remove trailing zeros
  const numStr = num.toFixed(20).replace(/0+$/, '');

  // Split into whole and decimal parts
  const [whole, decimal] = numStr.split('.');

  // If there is no decimal part, return the number as it is
  if (!decimal) {
    return numStr;
  }

  // Count leading zeros in the decimal part
  const leadingZeros = decimal.match(/^0+/)?.[0]?.length || 0;

  // If there are leading zeros
  if (leadingZeros > 2) {
    // Get the non-zero part after leading zeros
    const nonZeroPart = decimal.slice(leadingZeros);
    // Create subscript from leading zeros count
    const subscriptNum = String(leadingZeros)
      .split('')
      .map((digit) => String.fromCharCode(8320 + parseInt(digit))) // Convert to subscript
      .join('');

    // Retain only 4 digits from the non-zero part after leading zeros
    const fourDigitsAfterZeros = nonZeroPart.slice(0, 4);

    // Return formatted string
    return `${whole}.0${subscriptNum}${fourDigitsAfterZeros}`;
  } else {
    // If there are no leading zeros, return first 4 digits after the decimal
    const fourDigitsAfterDecimal = decimal.slice(0, 4);
    return `${whole}.${fourDigitsAfterDecimal}`;
  }
}
