import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';

export const StyledTab = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ButtonBaseProps & { active?: boolean }>(({ theme, active }) => {
  return {
    whiteSpace: 'nowrap',
    padding:    theme.spacing(1.5, 2),
    color:      active ? theme.palette.white.main : theme.palette.white50.main,
    background: active ? theme.palette.white8.main : 'transparent',
    transition: 'all 0.3s ease-in-out',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1),
    },
  };
});
