import React, { useCallback, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { AnimatePresence, motion } from 'framer-motion';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import {
  Button,
  CheckboxField,
  Form,
  FormField,
  Icon,
  Switch,
  TextField,
} from '@elements';

import chevronDownIcon from '@assets/images/chevron-down.svg';
import gearIcon from '@assets/images/gear.svg';

import { getTradingMarketBuySchema } from './trading-market-buy-form.schema';
import {
  TradingMarketBuyFormProps,
  TradingMarketBuyValues,
} from './trading-market-buy-form.types';

export const TradingMarketBuyForm: React.FC<TradingMarketBuyFormProps> = ({
  onSubmit,
  isSettings,
  initials,
}) => {
  const { t } = useTranslation();

  const initialValues: TradingMarketBuyValues = useMemo(
    () => ({
      amount:    '',
      stop_loss: {
        enabled:              false,
        price_change_percent: '',
        quantity_percent:     '',
      },
      take_profit: {
        enabled:              false,
        price_change_percent: '',
        quantity_percent:     '',
      },
      anti_mev_enabled:     false,
      anti_rug_enabled:     false,
      auto_approve_enabled: false,
      max_gas_limit:        '',
      extra_gas_size:       '',
      slippage_percent:     '',
      bribe:                '',
    }),
    [],
  );

  const handleSubmitForm = useCallback(
    async (values: TradingMarketBuyValues): Promise<void> => {
      await onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Form<TradingMarketBuyValues>
      onSubmit={handleSubmitForm}
      subscription={{
        submitting: true,
        invalid:    true,
        values:     true,
      }}
      initialValues={initials || initialValues}
      validateSchema={getTradingMarketBuySchema(t)}
      render={({
        submitting,
        invalid,
        values,
        handleSubmit,
        form,
      }: FormRenderProps<TradingMarketBuyValues>): JSX.Element => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <Stack component='form' spacing={2} onSubmit={handleSubmit}>
          <FormField
            type='number'
            name='amount'
            component={TextField}
            label={t('forms.trading.labels.amount')}
            fullWidth
          />

          <Stack spacing={1}>
            <FormField
              fullWidth
              type='checkbox'
              component={Switch}
              name='stop_loss.enabled'
              label={t('forms.trading.labels.stopLoss')}
            />

            <AnimatePresence initial={false}>
              {values.stop_loss.enabled && (
                <Stack
                  direction='row'
                  spacing={1}
                  component={motion.div}
                  variants={{
                    hide: { opacity: 0, height: 0 },
                    show: { opacity: 1, height: 'auto' },
                  }}
                  initial='hide'
                  animate='show'
                  exit='hide'
                >
                  <FormField
                    type='number'
                    name='stop_loss.price_change_percent'
                    component={TextField}
                    label={t('forms.trading.labels.priceChangePercent')}
                    disabled={!values.stop_loss.enabled}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='stop_loss.quantity_percent'
                    component={TextField}
                    label={t('forms.trading.labels.quantityPercent')}
                    disabled={!values.stop_loss.enabled}
                    fullWidth
                  />
                </Stack>
              )}
            </AnimatePresence>
          </Stack>

          <Stack spacing={1}>
            <FormField
              fullWidth
              type='checkbox'
              component={Switch}
              name='take_profit.enabled'
              label={t('forms.trading.labels.takeProfit')}
            />

            <AnimatePresence initial={false}>
              {values.take_profit.enabled && (
                <Stack
                  direction='row'
                  spacing={1}
                  component={motion.div}
                  variants={{
                    hide: { opacity: 0, height: 0 },
                    show: { opacity: 1, height: 'auto' },
                  }}
                  initial='hide'
                  animate='show'
                  exit='hide'
                >
                  <FormField
                    type='number'
                    name='take_profit.price_change_percent'
                    component={TextField}
                    label={t('forms.trading.labels.priceChangePercent')}
                    disabled={!values.take_profit.enabled}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='take_profit.quantity_percent'
                    component={TextField}
                    label={t('forms.trading.labels.quantityPercent')}
                    disabled={!values.take_profit.enabled}
                    fullWidth
                  />
                </Stack>
              )}
            </AnimatePresence>
          </Stack>

          <Accordion
            disableGutters
            sx={{
              padding:    0,
              background: 'transparent',
              '&:before': {
                display: 'none',
              },
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              disableRipple
              expandIcon={
                <Icon
                  src={chevronDownIcon}
                  width={20}
                  height={20}
                  color='white'
                />
              }
            >
              <Stack direction='row' spacing={1}>
                <Icon src={gearIcon} width={18} height={18} color='white' />
                <Typography variant='body2' color='white'>
                  {t('forms.trading.settings')}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='anti_mev_enabled'
                      title={
                        <Typography
                          component='span'
                          variant='body2'
                          color='white'
                        >
                          {t('forms.trading.labels.antiMev')}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='auto_approve_enabled'
                      title={
                        <Typography
                          component='span'
                          variant='body2'
                          color='white'
                        >
                          {t('forms.trading.labels.autoApprove')}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='anti_rug_enabled'
                      title={
                        <Typography
                          component='span'
                          variant='body2'
                          color='white'
                        >
                          {t('forms.trading.labels.antiRug')}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                <FormField
                  type='number'
                  name='max_gas_limit'
                  component={TextField}
                  label={t('forms.trading.labels.maxGasLimit')}
                  fullWidth
                />

                <FormField
                  type='number'
                  name='extra_gas_size'
                  component={TextField}
                  label={t('forms.trading.labels.extraGasSize')}
                  fullWidth
                />

                <Stack direction='row' spacing={1}>
                  <FormField
                    type='number'
                    name='bribe'
                    component={TextField}
                    label={t('forms.trading.labels.bribe')}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='slippage_percent'
                    component={TextField}
                    label={t('forms.trading.labels.slippagePercent')}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {isSettings ? (
            <>
              <Button
                type='submit'
                disabled={submitting || invalid}
                variant='contained'
                color='white'
              >
                {t('modals.buttons.save')}
              </Button>
              <Button
                variant='outlined'
                onClick={(): void => form.reset(initialValues)}
              >
                Сбросить
              </Button>
            </>
          ) : (
            <Button
              type='submit'
              disabled={submitting || invalid}
              variant='contained'
              color='mediumSeaGreen'
            >
              {t('pages.terminal.form.side.buy')}
            </Button>
          )}
        </Stack>
      )}
    />
  );
};
