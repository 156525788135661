import { createSelector } from 'reselect';

import { ApiError, Pair } from '@types';

import { AppState } from '../root.reducer';
import { TerminalState } from './terminal.types';

/**
 * Get pairs pending.
 *
 * @author Ilya Khachikyan
 * @function getPairsPending
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getPairsPending = (state: AppState): boolean =>
  state.terminal.pairs.pending.getList;

/**
 * Get pairs.
 *
 * @author Ilya Khachikyan
 * @function getPairs
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {Pair[] | null} pairs.
 */
const getPairs = (state: AppState): Pair[] | null => state.terminal.pairs.list;

/**
 * Get selected pair.
 *
 * @author Ilya Khachikyan
 * @function getSelectedPair
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {Pair | null} pair.
 */
const getSelectedPair = (state: AppState): Pair | null =>
  state.terminal.pairs.selected;

/**
 * Get pairs error.
 *
 * @author Ilya Khachikyan
 * @function getPairsListError
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {string | null} error.
 */
const getPairsListError = (state: AppState): ApiError | null =>
  state.terminal.pairs.errors.getList;

/**
 * Get orders list.
 *
 * @author Ilya Khachikyan
 * @function getOrdersList
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {TerminalState['orders']['list']} orders list.
 */
const getOrdersList = (state: AppState): TerminalState['orders']['list'] =>
  state.terminal.orders.list;

/**
 * Get orders pending.
 *
 * @author Ilya Khachikyan
 * @function getPairsPending
 * @category Selectors
 * @subcategory Terminal
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getOrdersPending = (state: AppState): boolean =>
  state.terminal.orders.pending.getList;

export const getTerminalPairsSelector = createSelector(
  getPairs,
  (pairs) => pairs,
);
export const getTerminalPairsPendingSelector = createSelector(
  getPairsPending,
  (pending) => pending,
);
export const getTerminalPairsErrorSelector = createSelector(
  getPairsListError,
  (error) => error,
);
export const getTerminalSelectedPairSelector = createSelector(
  getSelectedPair,
  (pair) => pair,
);

export const getTerminalOrdersSelector = createSelector(
  getOrdersList,
  (list) => list,
);

export const getTerminalOrdersPendingSelector = createSelector(
  getOrdersPending,
  (pending) => pending,
);
