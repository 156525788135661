import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { TextField } from '@elements/form';

import { StyledContainer, StyledDash, StyledPinWrapper, StyledTextFieldWrapper } from './pin-sms-fields.styles';

import { PinSmsFieldsProps } from './pin-sms-fields.types';

/**
 * Fields for entering pin/sms
 *
 * @author Ihar Kazlouski
 * @function PinSmsFields
 * @return {FC<PinSmsFieldsProps>} pin/sms fields component
 */
const PinSmsFields: FC<PinSmsFieldsProps> = ({
  onChange,
  hideNumbers = false,
  error = '',
  fieldCount = 6,
  hasDash = false,
  triggerClearInput = false,
  onIncorrectChange,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefs = Array.from({ length: fieldCount }, () => useRef<HTMLInputElement>(null));
  const [inputValues, setInputValues] = useState<string[]>(Array.from({ length: fieldCount }, () => ''));
  const [isError, setIsError] = useState<boolean>(!!error);

  useEffect(() => {
    if (inputRefs[0]) {
      setTimeout(() => inputRefs[0].current?.focus(), 100); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputValues(Array.from({ length: fieldCount }, () => ''));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (inputRefs[0]) {
      setTimeout(() => inputRefs[0].current?.focus(), 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldCount, triggerClearInput]);

  useEffect(() => {
    setIsError(!!error);
    setInputValues(Array.from({ length: fieldCount }, () => ''));
    if (inputRefs[0]) {
      setTimeout(() => inputRefs[0].current?.focus(), 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldCount, error]);

  useEffect(() => {
    onIncorrectChange && onIncorrectChange(inputValues.includes(''));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  /**
   * Handle key down
   * 
   * @author Ihar Kazlouski
   * @function handleKeyDown
   * @param {number} index 
   * @returns {(event: KeyboardEvent<HTMLInputElement>) => void}
   */
  const handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Backspace') {
      setInputValues((prevInputValues) => prevInputValues.map((value, idx) => idx === index ? '' : value));
      if (inputRefs[index].current?.value === '') {
        setTimeout(() => inputRefs[index - 1]?.current?.focus(), 0);
      }
      return;
    }

    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };

  /**
   * Handle key down
   * 
   * @author Ihar Kazlouski
   * @function handleChange
   * @param {number} index 
   * @returns {(event: ChangeEvent<HTMLInputElement>) => void}
   */
  const handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = event.target.value[0];
    if (!/^[0-9]+$/gi.test(currentValue)) {
      return;
    }
    if (error) {
      setIsError(false);
    }

    let newInputValues: string[] = [];
    setInputValues((prevInputValues) => {
      const newValues = prevInputValues.map((value, idx) => idx === index ? currentValue : value);
      newInputValues = [...newValues];
      return newValues;
    });

    if (index !== fieldCount - 1) {
      setTimeout(() => inputRefs[index + 1]?.current?.focus(), 0);
    } else {
      setTimeout(() => inputRefs[index]?.current?.blur(), 0);
      onChange(newInputValues.join(''));
    }
  };
  
  return (
    <StyledContainer>
      <StyledPinWrapper isError={isError}>
        {
          inputRefs.map((ref, index) => (
            <React.Fragment key={index}>
              {hideNumbers ? (
                <StyledTextFieldWrapper hasInputValue={!!inputValues[index]}>
                   <TextField
                    value={inputValues[index]}
                    inputProps={{ maxLength: '1', inputMode: 'numeric', pattern: '[0-9]*' }}
                    type='password'
                    inputRef={ref}
                    onKeyDown={handleKeyDown(index)}
                    onChange={handleChange(index)}
                   />
                </StyledTextFieldWrapper>
              ) : (
                <TextField
                  value={inputValues[index]}
                  inputProps={{ maxLength: '1', inputMode: 'numeric', pattern: '[0-9]*' }}
                  type='text'
                  inputRef={ref}
                  onKeyDown={handleKeyDown(index)}
                  onChange={handleChange(index)}
                />
              )}
              {hasDash && index === 2 && <StyledDash />}
            </React.Fragment>),
          )
        }
      </StyledPinWrapper>
    </StyledContainer>
  );
};

export { PinSmsFields };
