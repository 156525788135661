import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPopupContentContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginTop: theme.spacing(2.375),

    // [theme.breakpoints.down('lg')]: {
    //   marginTop: theme.spacing(6.5),
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginTop: theme.spacing(3.5),
    // },
  };

  return styles;
});

export { StyledPopupContentContainer };
