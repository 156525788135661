import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { MetaPageProps } from './meta-page.types';

/**
 * Meta page component.
 *
 * @author Ihar Kazlouski
 * @function MetaPage
 * @category components
 * @param {MetaPageProps['title']} title page.
 * @param {MetaPageProps['description']} description page.
 * @param {MetaPageProps['keywords']} keywords page.
 * @return {FC} Meta page component.
 */
const MetaPage: FC<MetaPageProps> = ({ title, description, keywords }) => {
  return (
    <Helmet defaultTitle='Graghdex'>
      {title && <title>Graghdex{title && `: ${title}`}</title>}
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
    </Helmet>
  );
};

export { MetaPage };
