const colors = {
  // Graphdex
  mirage:         '#161A25',
  white:          '#FFFFFF',
  white50:        '#FFFFFF80',
  white20:        '#FFFFFF33',
  white10:        '#FFFFFF1A',
  white8:         '#FFFFFF14',
  white4:         '#FFFFFF0A',
  inkBlack:       '#121212',
  blue:           '#2077E7',
  oliveGreen10:   '#40D9A51A',
  mediumSeaGreen: '#37B88C',
  seaGreen:       '#2C906E',
  lightCoral:     '#E55555',
  indianRed:      '#BA4242',
  lightCoral10:   '#E555551A',
  whiteSmoke:     '#EAEAEA',
  frenchRose:     '#EF476F',
  darkGreen:      '#3F3D29',

  // Accent
  persianBlue:    '#223CB1',
  royalBlue:      '#425DD9',
  cornflowerBlue: '#2077E7',
  titanWhite:     '#E9ECFF',
  whiteLilac:     '#F3F5FB',
  neonCarrot:     '#FF9331',
  serenade:       '#FFF0E2',
  jewel:          '#14804A',
  oceanGreen:     '#38A06C',
  scandal:        '#E1FCEF',
  maroonFlush:    '#CC234C',
  cabaret:        '#DD4469',
  remy:           '#FEEBEF',

  // Background
  mischka:    '#D6D8E2',
  ghostWhite: '#F7F8FC',

  // Text & Icon
  tuna:        '#343843',
  santasGray:  '#A2A6B8',
  frenchGray:  '#BEC0CC',
  flushOrange: '#FF7A00',

  // Divider
  catskillWhite: '#E9EDF5',
  geyser:        '#D9DEE8',
};

export { colors };
