import { history } from '@history';

import { ApiService } from '@services/api';

import { Routes } from '@enums';
import {
  ApiError,
  AuthenticationResponse,
  AuthPayload,
  AxiosResponse,
  LoginPayload,
  RegisterPayload,
  RegisterResponse,
} from '@types';

/**
 * Auth service.
 *
 * @author Ihar Kazlouski
 * @class AuthServiceClass
 * @category Services
 */
class AuthServiceClass {
  /**
   * Get token from local storage
   *
   * @author Ihar Kazlouski
   * @return {string} token.
   */
  getTokenLocalStorage (): string | null {
    return localStorage.getItem('token');
  }

  /**
   * Get token from local storage
   *
   * @author Ihar Kazlouski
   * @param {string} token.
   */
  setTokenLocalStorage (token: string): void {
    localStorage.setItem('token', token);
  }

  /**
   * Get token from session storage
   *
   * @author Ihar Kazlouski
   * @return {string} token.
   */
  getTokenSessionStorage (): string | null {
    return sessionStorage.getItem('token');
  }

  /**
   * Set token to session storage
   *
   * @author Ihar Kazlouski
   * @param {string} token.
   */
  setTokenSessionStorage (token: string): void {
    sessionStorage.setItem('token', token);
  }

  /**
   * Remove token to session storage
   *
   * @author Ihar Kazlouski
   * @param {string} token.
   */
  removeTokenSessionStorage (): void {
    sessionStorage.removeItem('token');
  }

  /**
   * Go to auth route
   *
   * @author Ihar Kazlouski
   */
  goToAuthRoute (): void {
    history.push(Routes.Login);
  }

  /**
   * Registration post api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<RegisterResponse>} promise.
   */
  async apiRegisterPost (
    payload: RegisterPayload,
  ): Promise<ApiError | AxiosResponse<RegisterResponse>> {
    return ApiService.apiPost('/auth/website/register', payload);
  }

  /**
   * Check is user registered post api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<AuthenticationResponse>} promise.
   */
  async apiAuthPost (
    payload: AuthPayload,
  ): Promise<ApiError | AxiosResponse<AuthenticationResponse>> {
    return ApiService.apiPost('/auth/website/authentication', payload);
  }

  /**
   * Login post api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<RegisterResponse>} promise.
   */
  async apiLoginPost (
    payload: LoginPayload,
  ): Promise<ApiError | AxiosResponse<RegisterResponse>> {
    return ApiService.apiPost('/auth/website/login', payload);
  }
}

const AuthService = new AuthServiceClass();

export { AuthService };
