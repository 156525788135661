import {
  alpha,
  Box,
  Popover,
  popoverClasses,
  PopoverProps,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { TextField } from '@elements/form/fields/text-field';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledPopover = styled(Popover)<PopoverProps & { theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      [`& .${popoverClasses.paper}`]: {
        padding:      pxToRem(20),
        borderRadius: pxToRem(16),
        borderColor:  theme.colors.catskillWhite,
        boxShadow:    `0 ${pxToRem(3)} ${pxToRem(5)} ${alpha(
          theme.colors.tuna,
          0.17,
        )}}`,
      },
    };

    return styles;
  },
);

const StyledDayPickerButtonsWrapper = styled(Box)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      paddingTop: theme.spacing(2.5),
      marginTop:  theme.spacing(2.5),
      borderTop:  `${pxToRem(1)} solid ${theme.palette.catskillWhite.main}`,
    };

    return styles;
  },
);

const TimeTextField = styled(TextField)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      borderRadius:            pxToRem(6),
      '& .MuiInputBase-input': {
        height:  pxToRem(26),
        padding: theme.spacing(0.5, 1.5),
      },
    };

    return styles;
  },
);

export { StyledDayPickerButtonsWrapper, StyledPopover, TimeTextField };
