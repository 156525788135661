import { createSelector } from 'reselect';

import { ApiError, User } from '@types';

import { AppState } from '../root.reducer';
import { AuthUserPayload } from './auth.types';

/**
 * Get pending.
 *
 * @author Ihar Kazlouski
 * @function getPending
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getPending = (state: AppState): boolean => state.auth.pending;

/**
 * Get user.
 *
 * @author Ihar Kazlouski
 * @function getUser
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {User | null} current user.
 */
const getUser = (state: AppState): User | null => state.auth.user;

/**
 * Get error.
 *
 * @author Ihar Kazlouski
 * @function getError
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {string | null} error.
 */
const getError = (state: AppState): ApiError | null => state.auth.error;

/**
 * Get auth.
 *
 * @author Ihar Kazlouski
 * @function getAuth
 * @category Selectors
 * @subcategory Auth
 * @param {AppState} state state.
 * @return {AuthUser | null} error.
 */
const getAuth = (state: AppState): AuthUserPayload => {
  return {

  };
};

export const getUserSelector = createSelector(getUser, (user) => user);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending,
);
export const getErrorSelector = createSelector(getError, (error) => error);
export const getAuthSelector = createSelector(getAuth, (auth) => auth);
