import { DayPicker } from 'react-day-picker';

import { alpha, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import 'react-day-picker/dist/style.css';

const StyledDayPicker = styled(DayPicker)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    '--rdp-accent-color':     theme.colors.cornflowerBlue,
    '--rdp-background-color': 'transparent',
    '--rdp-outline':          'none',
    '--rdp-outline-selected': 'none',
    '--rdp-cell-size':        pxToRem(46),
    margin:                   0,
    '& .rdp-caption_label':   {
      fontSize:      pxToRem(16),
      lineHeight:    pxToRem(24),
      fontWeight:    700,
      textTransform: 'uppercase',
      padding:       'unset',
    },
    '& .rdp-table': {
      borderCollapse: 'separate',
      borderSpacing:  `0 ${pxToRem(6)}`,
      marginTop:      theme.spacing(2),
    },
    '& .rdp-head_row':  {},
    '& .rdp-head_cell': {
      fontWeight:    500,
      fontSize:      pxToRem(16),
      lineHeight:    pxToRem(20),
      color:         alpha(theme.colors.tuna, 0.65),
      textTransform: 'capitalize',
    },
    '& .rdp-weeknumber, & .rdp-day': {
      border:       'none',
      borderRadius: pxToRem(8),
    },
    '& .rdp-button': {
      fontWeight:   500,
      fontSize:     pxToRem(16),
      lineHeight:   pxToRem(20),
      color:        theme.palette.tuna.main,
      borderRadius: '50%',
      ':hover':     {
        border: `${pxToRem(2)} solid ${theme.palette.cornflowerBlue.main}`,
        color:  theme.palette.cornflowerBlue.main,
      },
    },
    '& .rdp-day_selected': {
      color:           theme.palette.white.main,
      backgroundColor: theme.palette.cornflowerBlue.main,
      ':hover':        {
        color: theme.palette.white.main,
      },
    },
    '& .rdp-day_outside': {
      color: theme.palette.mischka.main,
    },
    '& .rdp-day_range_start': {
      borderTopRightRadius:    0,
      borderBottomRightRadius: 0,
    },
    '& .rdp-day_range_end': {
      borderTopLeftRadius:    0,
      borderBottomLeftRadius: 0,
    },
    '& .rdp-day_range_end.rdp-day_range_start': {
      borderRadius: '50%',
    },
    '& .rdp-day_range_middle.rdp-day_selected': {
      borderRadius:    0,
      backgroundColor: alpha(theme.colors.cornflowerBlue, 0.06),
      color:           theme.colors.tuna,
      ':hover':        {
        border: `${pxToRem(2)} solid ${theme.palette.cornflowerBlue.main}`,
        color:  theme.palette.cornflowerBlue.main,
      },
    },
    '& .rdp-day_today:not(.rdp-day_outside)': {
      fontWeight: 'inherit',
    },
    '& .rdp-tfoot': {
      margin: 'unset',
    },
  };

  return styles;
});

export { StyledDayPicker };
