import { createSelector } from 'reselect';

import { ApiError, Pair, Transaction } from '@types';

import { AppState } from '../root.reducer';

/**
 * Get pending.
 *
 * @author Ihar Kazlouski
 * @function getPending
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {boolean} pending.
 */
const getPairsPending = (state: AppState): boolean =>
  state.statistic.pairsPending;
const getTransactionsPending = (state: AppState): boolean =>
  state.statistic.transactionsPending;

const getCount = (state: AppState): number | null => state.statistic.pairsCount;

/**
 * Get pairs.
 *
 * @author Ihar Kazlouski
 * @function getPairs
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {Pair[] | null} pairs.
 */
const getPairs = (state: AppState): Pair[] | null => state.statistic.pairs;

/**
 * Get transactions.
 *
 * @author Ihar Kazlouski
 * @function getTransactions
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {Pair[] | null} transactions.
 */
const getTransactions = (state: AppState): Transaction[] | null =>
  state.statistic.transactions;

/**
 * Get error.
 *
 * @author Ihar Kazlouski
 * @function getError
 * @category Selectors
 * @subcategory Network
 * @param {AppState} state state.
 * @return {string | null} error.
 */
const getError = (state: AppState): ApiError | null => state.statistic.error;

export const getPairsSelector = createSelector(getPairs, (pairs) => pairs);
export const getTransactionsSelector = createSelector(
  getTransactions,
  (transactions) => transactions,
);
export const getPairsPendingSelector = createSelector(
  getPairsPending,
  (pending) => pending,
);

export const getTransactionsPendingSelector = createSelector(
  getTransactionsPending,
  (pending) => pending,
);

export const getCountSelector = createSelector(getCount, (count) => count);
export const getErrorSelector = createSelector(getError, (error) => error);
