import React, { ChangeEvent, useEffect, useRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { StyledPinWrapper } from '@elements/pin-sms-fields/pin-sms-fields.styles';

import {
  StyledButton,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledIcon,
  StyledInputLabel,
  StyledTextField,
  StyleFromHelperWrapper,
} from './keyboard-pin-field.styles';
import { StyledContainer } from '@views/pages/pin/pin.styles';

import backspace from '@images/arrow-left.svg';

import { PinFieldProps } from './keyboard-pin-field.types';

/**
 * KeyboardPinField Component for creating a multi-field PIN input
 *
 * @author Yury Kuntsou
 * @function KeyboardPinField
 * @category components
 * @param {PinFieldProps} props - Properties of the pin-field component
 * @returns {JSX.Element}
 */
const KeyboardPinField = (props: PinFieldProps): JSX.Element => {
  const {
    value,
    name,
    error,
    fields = 4,
    onChange,
    onFocus,
    onBlur,
    onComplete,
    bottomLabel,
    label,
    isShowError = true,
    isSeparate,
    autoFocus,
    disabled,
    title,
    ...restProps
  } = props;
  const pinFields = Array.from(Array(fields), () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRef<HTMLInputElement>(null),
  );
  const inputName = name || 'pinfield';
  const inputValue = props.value || Array.from(Array(fields), () => '');
  const isError = isShowError && !!error;
  useEffect(() => {
    if (onComplete && value && value[value.length - 1] !== '') {
      onComplete(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  /**
   * A description of the entire function.
   *
   * @param {string} value - The value to be handled
   * @return {void}
   */
  const handleClick = (value: string) => (): void => {
    if (onChange) {
      const newValue = [...inputValue];
      const index = newValue.indexOf('');

      if (window.navigator.vibrate) {
        window.navigator.vibrate(100);
      }

      if (index > -1) {
        newValue[index] = value;
      }

      if (value === 'Backspace') {
        const newValue = [...inputValue];

        if (index === 0) {
          return;
        }

        if (index === -1) {
          newValue[newValue.length - 1] = '';
        } else {
          newValue[index - 1] = '';
        }

        onChange(
          newValue as unknown as ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement
          >,
        );
      } else {
        onChange(
          newValue as unknown as ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement
          >,
        );
      }
    }
  };

  return (
    <Box mt={1.5} display='flex' flexDirection='column' alignItems='center'>
      {label && (
        <StyledInputLabel $color={restProps.color} htmlFor={inputName} shrink>
          {label}
        </StyledInputLabel>
      )}
      <Box position='relative'>
        <Stack direction='row' justifyContent='center'>
          {pinFields.map((field, index) => (
            <StyledContainer key={index}>
              <StyledPinWrapper isError={isError}>
                <StyledTextField
                  focused={inputValue.join('').length === index}
                  value={inputValue[index]}
                  inputProps={{
                    maxLength: '1',
                    inputMode: 'numeric',
                    pattern:   '[0-9]*',
                  }}
                  type='password'
                />
              </StyledPinWrapper>
            </StyledContainer>
          ))}
        </Stack>
        {title && (
          <Typography
            variant='body1'
            color='white'
            textAlign='center'
            pt={3}
            maxWidth='343px'
          >
            {title}
          </Typography>
        )}
        {isError && (
          <StyledFormHelperErrorText id={`${inputName}-error-text`}>
            {error}
          </StyledFormHelperErrorText>
        )}
        {bottomLabel && !isError ? (
          <StyleFromHelperWrapper>
            {typeof bottomLabel === 'string' ? (
              <StyledFormHelperText $color={restProps.color}>
                {bottomLabel}
              </StyledFormHelperText>
            ) : (
              bottomLabel
            )}
          </StyleFromHelperWrapper>
        ) : null}
      </Box>
      <Box pt={4} width='248px'>
        <Stack columnGap={6.5} rowGap={3} direction='row' flexWrap='wrap'>
          {Array.from({ length: 9 }, (_, index) => index + 1).map(
            (num, index) => (
              <StyledButton
                key={num}
                variant='text'
                onClick={handleClick(num.toString())}
                disabled={disabled}
              >
                {num}
              </StyledButton>
            ),
          )}
          <Box height='48px' width='48px' />
          <StyledButton
            variant='text'
            onClick={handleClick('0')}
            disabled={disabled}
          >
            0
          </StyledButton>
          <StyledButton
            variant='text'
            onClick={handleClick('Backspace')}
            disabled={disabled}
          >
            <StyledIcon
              height={24}
              src={backspace}
              width={24}
              disabled={disabled}
            />
          </StyledButton>
        </Stack>
      </Box>
    </Box>
  );
};

export { KeyboardPinField };
