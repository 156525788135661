import {
  Box,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  FormHelperText,
  formHelperTextClasses,
  InputLabel,
  inputLabelClasses,
  Radio,
  radioClasses,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledFormControl = styled(FormControl)(() => {
  const styles: OverridesStyleRules = {
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:        'relative',
      transform:       'unset',
      transformOrigin: 'unset',
      maxWidth:        '100%',
      fontSize:        theme.typography.pxToRem(16),
      lineHeight:      theme.typography.pxToRem(20),
      color:           theme.palette.white50.main,
      display:         'block',
      whiteSpace:      'unset',

      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.white50.main,
      },

      [`&.${inputLabelClasses.error}`]: {
        color: theme.palette.white50.main,
      },
    };

    return styles;
  },
);

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:   'absolute',
    left:       0,
    right:      0,
    top:        '100%',
    marginTop:  theme.typography.pxToRem(4),
    fontSize:   theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex:     1,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:  'block',
    padding:  theme.spacing(0),
    margin:   'unset',
    maxWidth: '100%',

    [`&.${formHelperTextClasses.error}`]: {
      color: theme.palette.lightCoral.main,
    },
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.white50.main,
    display:  'block',
    padding:  theme.spacing(0),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

const StyledRadioItem = styled(FormControlLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      color: theme.palette.white50.main,

      ['&:hover']: {
        color: theme.palette.white.main,
      },

      [`.${radioClasses.checked} + .${formControlLabelClasses.label}`]: {
        color: theme.palette.white.main,
      },
    };

    return styles;
  },
);

const StyledRadioButton = styled(Radio)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding: theme.spacing(1.25),
    };

    return styles;
  },
);

const StyledCheckedIcon = styled('span')<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding:        theme.spacing(0),
      width:          theme.spacing(2.5),
      height:         theme.spacing(2.5),
      display:        'flex',
      justifyContent: 'center',
      alignItems:     'center',
      background:     theme.palette.blue.main,
      color:          theme.palette.white.main,
      borderRadius:   theme.spacing(2.5),
    };

    return styles;
  },
);

const StyledUncheckedIcon = styled('span')<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding:      theme.spacing(0),
      width:        theme.spacing(2.5),
      height:       theme.spacing(2.5),
      background:   theme.palette.white8.main,
      border:       `1px solid ${theme.palette.white10.main}`,
      borderRadius: theme.spacing(2.5),
    };

    return styles;
  },
);

export {
  StyledCheckedIcon,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyledRadioButton,
  StyledRadioItem,
  StyledUncheckedIcon,
  StyleFormHelperWrapper,
};
