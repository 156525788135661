import { Dialog, Paper, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledDialog = styled(Dialog)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {};

  return styles;
});

const StyledPaper = styled(Paper)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    borderRadius:                   theme.typography.pxToRem(12),
    width:                          '100%',
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
      // width:  `calc(100% - ${theme.typography.pxToRem(32)})`,
    },
  };

  return styles;
});
export { StyledDialog, StyledPaper };
