import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import { getCurrentNetworkSelector } from '@store/network/network.selectors';

import { PopupsService } from '@services';
import { addressCutUtil, copyToClipboardUtil } from '@utils';

import { Popups, WalletOption } from '@enums';

import { Button, Icon } from '@elements';

import {
  ExpandBlock,
  IconRound,
  StyledButton,
  StyledWalletContainer,
  WalletItem,
} from './wallet.styles';

import arrowDown from '@images/arrow-down.image.svg';
import copy from '@images/copy.svg';
import edit from '@images/edit.svg';
import plus from '@images/plus.svg';
import trash from '@images/trash.svg';
import walletIcon from '@images/wallet.svg';

import { WalletProps } from './wallet.types';

const WalletComponent: FC<WalletProps> = ({
  className,
  wallets,
  setSelectedWallet,
  selectedWallet,
}) => {
  const [expanded, setExpanded] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const currentNetwork = useSelector(getCurrentNetworkSelector);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const handleWheelScroll = (event: WheelEvent): void => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
        event.preventDefault();
      }
    };

    const handleMouseDown = (event: MouseEvent): void => {
      if (scrollContainerRef.current) {
        setIsDragging(true);
        setStartX(event.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
      }
    };

    const handleMouseMove = (event: MouseEvent): void => {
      if (!isDragging || !scrollContainerRef.current) return;
      event.preventDefault();
      const x = event.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 2; // The * 2 is to control the scroll speed
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = (): void => {
      setIsDragging(false);
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheelScroll);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollContainer.addEventListener('mousedown', handleMouseDown);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollContainer.addEventListener('mousemove', handleMouseMove);
      scrollContainer.addEventListener('mouseleave', handleMouseUp);
      scrollContainer.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheelScroll);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scrollContainer.removeEventListener('mousedown', handleMouseDown);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scrollContainer.removeEventListener('mousemove', handleMouseMove);
        scrollContainer.removeEventListener('mouseleave', handleMouseUp);
        scrollContainer.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [isDragging, startX, scrollLeft]);

  const toggleExpand = (): void => setExpanded(!expanded);

  return (
    <StyledWalletContainer className={className}>
      <Box display='flex' alignItems='center' width='100%'>
        <Box
          ref={scrollContainerRef}
          display='flex'
          flexWrap='nowrap'
          width='100%'
          gap={0.5}
          sx={{
            overflowX:             'scroll',
            scrollbarWidth:        'none',
            '::-webkit-scrollbar': {
              width:  0,
              height: 0,
            },
          }}
        >
          {wallets?.map((wallet, i) => (
            <Box
              key={wallet.id}
              sx={{ flexShrink: '0', minWidth: { xs: '80%', sm: '33%' } }}
            >
              <StyledButton
                onClick={(): void => setSelectedWallet(wallet)}
                selected={wallet.id === selectedWallet?.id}
              >
                <IconRound selected={wallet.id === selectedWallet?.id}>
                  <Icon src={walletIcon} width={24} height={24} />
                </IconRound>
                {wallet.name || addressCutUtil(wallet.address)}
              </StyledButton>
            </Box>
          ))}
        </Box>
        <IconButton onClick={toggleExpand}>
          <Icon src={arrowDown} width={24} height={24} />
        </IconButton>
      </Box>

      {/* Wallets List */}
      {expanded && (
        <ExpandBlock>
          {wallets?.map((wallet, index) => (
            <WalletItem key={wallet.id}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' alignItems='center' gap={1}>
                  <IconRound>
                    <Icon src={walletIcon} width={24} height={24} />
                  </IconRound>
                  <Stack>
                    <Typography variant='body3' color='white' fontWeight='500'>
                      {wallet.name || addressCutUtil(wallet.address)}
                    </Typography>
                    <Box
                      display='flex'
                      alignItems='center'
                      gap={0.5}
                      sx={{ cursor: 'pointer' }}
                      onClick={(): void => copyToClipboardUtil(wallet.address)}
                    >
                      <Typography
                        variant='body3'
                        color='cornflowerblue'
                        fontWeight='700'
                      >
                        {addressCutUtil(wallet.address)}
                      </Typography>
                      <Icon src={copy} width={16} height={16} color='action' />
                    </Box>
                  </Stack>
                </Box>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  mt={1}
                  gap={1}
                >
                  <Stack alignItems='end'>
                    <Typography variant='body3' color='white' fontWeight='500'>
                      {wallet.balance} {currentNetwork?.native_symbol}
                    </Typography>
                    <Typography
                      variant='caption2'
                      color='white50'
                      fontWeight='500'
                    >
                      {wallet.balance_usd} USD
                    </Typography>
                  </Stack>
                  <Box>
                    <IconButton
                      size='small'
                      color='white'
                      onClick={(): void =>
                        void PopupsService.open(Popups.WalletAction, {
                          header:    t('pages.wallet.updateWalletName'),
                          body:      WalletOption.Update,
                          elementId: wallet.id,
                        })
                      }
                    >
                      <Icon src={edit} width={18} height={18} />
                    </IconButton>
                    <IconButton
                      size='small'
                      color='white'
                      onClick={(): void =>
                        void PopupsService.open(Popups.WalletAction, {
                          header:    t('pages.wallet.deleteWallet'),
                          body:      WalletOption.Delete,
                          elementId: wallet.id,
                        })
                      }
                    >
                      <Icon src={trash} width={18} height={18} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </WalletItem>
          ))}
          {/* Add More Button */}
          <Box mt={2} textAlign='center'>
            <Button
              variant='text'
              sx={{
                display:    'flex',
                alignItems: 'center',
                gap:        1.5,
                margin:     '0 auto',
              }}
              onClick={(): void =>
                void PopupsService.open(Popups.WalletAction, {
                  header: t('pages.wallet.createWallet'),
                  body:   WalletOption.Add,
                })
              }
            >
              <Icon src={plus} width={24} height={24} color='white' />
              {t('pages.wallet.addMore')}
            </Button>
          </Box>
        </ExpandBlock>
      )}
    </StyledWalletContainer>
  );
};

export { WalletComponent };
