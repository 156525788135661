import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { InitService } from '@services';
import { AuthLayout, PublicLayout, WithSuspense } from '@hocs';

import { AppRoutes } from '@types';

import { Modals, Popups, Snackbars } from '@components';
import { MetaPage } from '@elements';

import 'react-reflex/styles.css';

import { appAuthRoutes, appPublicRoutes } from './routers';

/**
 * App component.
 *
 * @author Ihar Kazlouski
 * @function AppComponent
 * @category Components
 * @component
 * @return {FC} app.
 */
const AppComponent: FC = () => {
  const [isAppReady, setIsAppReady] = useState<boolean>(false);

  /**
   * Hide splash screen logic.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const hideSplashScreen = (): void => {
    const loader = document.getElementById('loader');
    const loaderProgress = document.getElementById('loader-progress');

    if (loader && loaderProgress) {
      loaderProgress.style.width = '100%';
      (window as Window & typeof globalThis & { appReady: boolean }).appReady =
        true;

      setTimeout(() => {
        document.body.classList.remove('loading');

        setTimeout(() => {
          loader.style.visibility = 'hidden';
        }, 750);
      }, 1250);
    }
  };

  useEffect(() => {
    InitService.init()
      .then(() => {
        setIsAppReady(true);
        hideSplashScreen();
      })
      .catch(() => {
        setIsAppReady(true);
        hideSplashScreen();
      });
  }, []);

  return (
    <>
      <MetaPage />
      {isAppReady ? (
        <>
          <Routes>
            {appPublicRoutes.map((route: AppRoutes) => (
              <Route
                key={route.name}
                path='/'
                element={<PublicLayout inline={route.inline} />}
              >
                <Route
                  path={route.path}
                  element={WithSuspense(route.element)}
                  index={route.index}
                />
              </Route>
            ))}
            {appAuthRoutes.map((route: AppRoutes) => (
              <Route key={route.name} path='/' element={<AuthLayout />}>
                <Route
                  path={route.path}
                  element={WithSuspense(route.element)}
                  index={route.index}
                />
              </Route>
            ))}
          </Routes>
          <Modals />
          <Popups />
          <Snackbars />
        </>
      ) : null}
    </>
  );
};

export default withTranslation()(AppComponent);
