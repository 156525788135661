import * as React from 'react';

import { StyledButton } from './button.styles';

import { ButtonProps } from './button.types';

/**
 * Button component.
 *
 * @author Ihar Kazlouski
 * @function Button
 * @category components
 * @return {FC} button component.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <StyledButton ref={ref} disableElevation {...props} />,
);

Button.displayName = 'Button';

export { Button };
