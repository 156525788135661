import { formatISO, isValid, parseISO } from 'date-fns/esm';

/**
 * Helps yup schemas to validate dates.
 *
 * @author  Mikhail Siutsou
 * @function convertedDateToObject
 * @category helpers
 * @param {Date | number | string | undefined} date  date.
 * @param {'complete' | 'date' | 'time'} representation representation format date.
 * @return {Date | undefined} return parsed date.
 */
export const convertedDateToObject = (
  date: Date | number | string | undefined,
  representation?: 'complete' | 'date' | 'time',
): Date | undefined => {
  if (!date || !isValid(date)) {
    return undefined;
  }
  const newDate = typeof date === 'string' ? parseISO(date) : date;
  return parseISO(
    formatISO(newDate, { representation: representation || 'date' }),
  );
};

/**
 * Formats Date to datetime.
 *
 * @author  Ilya Khachikyan
 * @function formatDateToDateTime
 * @category helpers
 * @param {Language} locale.
 * @param {Date | number | string | undefined} date  date.
 * @return {string} returns formated string.
 */
export const formatDateToDatetimeStr = (
  locale: string,
  date: Date | number | string,
): string => {
  const normalizedDate = typeof date === 'number' ? date * 1000 : date;
  const dateObj = new Date(normalizedDate);

  return `${dateObj
    .toLocaleDateString(locale, { day: 'numeric', month: 'short' })
    .replace('.', '')} ${dateObj.toLocaleTimeString(locale)}`;
};
