import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  CREATE_WALLET_REQUEST,
  DELETE_WALLET_REQUEST,
  FETCH_WALLETS_REQUEST,
  UPDATE_WALLET_REQUEST,
} from '@store/wallet/wallet.action.types';

import { WalletService } from '@services';
import { ConstantsUtil } from '@utils';

import { ApiError, AxiosResponse, PayloadAction } from '@types';

import { actions } from './wallet.actions';
import {
  CreateWalletRequestPayload,
  DeleteWalletRequestPayload,
  FetchWalletsRequestPayload,
  UpdateWalletRequestPayload,
  WalletCreateResponse,
  WalletResponse,
  WalletUpdateResponse,
} from './wallet.types';

/**
 * Get wallets saga worker.
 *
 * @author Ihar Kazlouski
 * @function getWalletsSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getWalletsSagaWorker (
  action: PayloadAction<FetchWalletsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<WalletResponse>
  > {
  try {
    const { data } = yield call(
      WalletService.apiWalletsGet,
      action.payload.networkId,
    );

    yield put(
      actions.fetchWalletSuccess({
        wallets: data,
      }),
    );
    yield put({
      type:    `${FETCH_WALLETS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: data,
    });
  } catch (error) {
    yield put(
      actions.fetchWalletFailure({
        error: error as ApiError,
      }),
    );

    yield put({
      type:    `${FETCH_WALLETS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      payload: error as ApiError,
    });
  }
}

/**
 * Get wallets saga.
 *
 * @author Ihar Kazlouski
 * @function getWalletsSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getWalletsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FETCH_WALLETS_REQUEST, getWalletsSagaWorker)]);
}

/**
 * Create wallets saga worker.
 *
 * @author Ihar Kazlouski
 * @function createWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* createWalletSagaWorker (
  action: PayloadAction<CreateWalletRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<WalletCreateResponse>
  > {
  try {
    const payload = {
      networkId: action.payload.networkId,
      name:      action.payload.name,
    };

    const { data } = yield call(WalletService.apiWalletCreatePost, payload);

    yield put(
      actions.createWalletSuccess({
        wallet: data,
      }),
    );
  } catch (error) {
    yield put(
      actions.createWalletFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * create wallets saga.
 *
 * @author Ihar Kazlouski
 * @function createWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* createWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(CREATE_WALLET_REQUEST, createWalletSagaWorker)]);
}

/**
 * Delete wallets saga worker.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* deleteWalletSagaWorker (
  action: PayloadAction<DeleteWalletRequestPayload>,
): Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>> {
  try {
    const payload = {
      networkId: action.payload.networkId,
      walletId:  action.payload.walletId,
    };

    yield call(WalletService.apiWalletDelete, payload);

    yield put(
      actions.deleteWalletSuccess({
        walletId: action.payload.walletId,
      }),
    );
  } catch (error) {
    yield put(
      actions.deleteWalletFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * delete wallets saga.
 *
 * @author Ihar Kazlouski
 * @function deleteWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* deleteWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(DELETE_WALLET_REQUEST, deleteWalletSagaWorker)]);
}

/**
 * update wallets saga worker.
 *
 * @author Ihar Kazlouski
 * @function updateWalletSagaWorker
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* updateWalletSagaWorker (
  action: PayloadAction<UpdateWalletRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<WalletUpdateResponse>
  > {
  try {
    const payload = {
      networkId: action.payload.networkId,
      walletId:  action.payload.walletId,
      name:      action.payload.name,
    };

    const { data } = yield call(WalletService.apiWalletUpdatePatch, payload);

    yield put(
      actions.updateWalletSuccess({
        walletId: action.payload.walletId,
        name:     data.name,
      }),
    );
  } catch (error) {
    yield put(
      actions.updateWalletFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * update wallets saga.
 *
 * @author Ihar Kazlouski
 * @function updateWalletSagaWatcher
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* updateWalletSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(UPDATE_WALLET_REQUEST, updateWalletSagaWorker)]);
}

/**
 * Wallet saga.
 *
 * @author Ihar Kazlouski
 * @function walletSaga
 * @category Sagas
 * @subcategory Wallet
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* walletSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(getWalletsSagaWatcher),
    fork(createWalletSagaWatcher),
    fork(deleteWalletSagaWatcher),
    fork(updateWalletSagaWatcher),
  ]);
}
