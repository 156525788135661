import { SET_THEME } from '@store/theme/theme.action-types';

import { Theme } from '@enums';

import { ActionsTypes } from './theme.types';

const initialState: Theme = Theme.Light;

/**
 * Theme reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Theme
 * @param {Theme} state theme state.
 * @param {ThemeActions} action theme action.
 * @return {Theme} theme state.
 */
export default (state: Theme = initialState, action: ActionsTypes): Theme => {
  switch (action.type) {
    case SET_THEME: {
      return action.theme;
    }

    default: {
      return state;
    }
  }
};
