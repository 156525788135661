import { produce } from 'immer';

import {
  CLOSE_POPUP,
  CLOSING_POPUP_WITH_ANSWER,
  SET_POPUP,
} from './popups.action-types';
import { ActionsTypes, PopupsState } from './popups.types';

const initialState: PopupsState[] = [];

/**
 * Popups reducer.
 *
 * @author Mihail Siutsou
 * @category Reducers
 * @subcategory Popups
 * @param {PopupsState} state state.
 * @param {ActionsTypes} action action.
 * @return {PopupsState[]} popups state.
 */
const popupsReducer = (
  state = initialState,
  action: ActionsTypes,
): PopupsState[] => {
  switch (action.type) {
    case SET_POPUP: {
      return produce(state, (draft) => {
        const idx = state.findIndex(
          (popup) => popup.popupId === action.payload.popupId,
        );
        if (idx >= 0) {
          draft[idx] = {
            popupId:  action.payload.popupId,
            isOpen:   action.payload.isOpen,
            data:     action.payload.data,
            accepted: false,
            rejected: false,
          };
        } else {
          draft.push({
            popupId:  action.payload.popupId,
            isOpen:   action.payload.isOpen,
            data:     action.payload.data,
            accepted: false,
            rejected: false,
          });
        }
      });
    }

    case CLOSE_POPUP: {
      return produce(state, (draft) => {
        const popup = draft.find(
          (popup) => popup.popupId === action.payload.popupId,
        );

        if (popup) {
          popup.isOpen = false;
        }
      });
    }

    case CLOSING_POPUP_WITH_ANSWER: {
      return produce(state, (draft) => {
        const index = draft.findIndex(
          (popup) => popup.popupId === action.payload.popupId,
        );
        if (index !== -1) draft.splice(index, 1);
      });
    }

    default: {
      return state;
    }
  }
};

export default popupsReducer;
