import {
  Box,
  filledInputClasses,
  FormHelperText,
  InputLabel,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { Button } from '@elements/button';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { Icon } from '../../icon/icon.component';
import { TextField } from '../fields/text-field/text-field.component';
import { TextFieldProps } from '../fields/text-field/text-field.types';

const StyledInputLabel = styled(InputLabel)<{
  $color: TextFieldProps['color'];
  theme: DefaultTheme;
}>(({ theme, $color }) => {
  const styles: OverridesStyleRules = {
    color:           theme.colors.mirage,
    display:         'block',
    fontSize:        pxToRem(16),
    fontWeight:      400,
    lineHeight:      pxToRem(18),
    marginBottom:    theme.spacing(0.5),
    maxWidth:        '100%',
    paddingLeft:     theme.spacing(1),
    position:        'relative',
    transform:       'unset',
    transformOrigin: 'unset',
  };

  // if ($color === 'bayFog') {
  //   styles.color = theme.colors.white;
  // }

  return styles;
});

const StyledTextField = styled(TextField)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    [`& .${filledInputClasses.input}`]: {
      ['&[type="password"]']: {
        ['&::placeholder']: {
          color: theme.colors.mirage,
        },
        color:    theme.palette.white.main,
        fontSize: pxToRem(64),
      },

      boxSizing: 'border-box',
      padding:   theme.spacing(1),
      textAlign: 'center',
    },
  };

  return styles;
});

const StyleFromHelperWrapper = styled(Box)<{ relative?: boolean }>(() => {
  const styles: OverridesStyleRules = {
    fontSize:   pxToRem(12),
    fontWeight: 500,
    left:       '50%',
    lineHeight: pxToRem(14),
    marginTop:  pxToRem(16),
    position:   'absolute',
    right:      0,
    top:        '100%',
    transform:  'translateX(-50%)',
    width:      pxToRem(178),
    paddingTop: pxToRem(32),
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:     theme.colors.frenchRose,
    display:   'block',
    margin:    'unset',
    maxWidth:  '100%',
    marginTop: theme.spacing(4),
    fontSize:  pxToRem(16),
    textAlign: 'center',
    wordWrap:  'break-word',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  $color: TextFieldProps['color'];
  theme: DefaultTheme;
}>(({ theme, $color }) => {
  const styles: OverridesStyleRules = {
    color:     theme.colors.mirage,
    display:   'block',
    margin:    'unset',
    maxWidth:  '100%',
    padding:   theme.spacing(0, 1),
    textAlign: 'center',
  };

  // if ($color === 'bayFog') {
  //   styles.color = theme.colors.white;
  // }

  return styles;
});

const StyledButton = styled(Button)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    backgroundColor: 'transparent',
    boxShadow:       'none',
    color:           theme.colors.white,
    fontSize:        pxToRem(24),
    fontWeight:      400,
    height:          pxToRem(48),
    lineHeight:      pxToRem(29),
    minWidth:        'unset',
    padding:         0,
    textAlign:       'center',
    width:           pxToRem(48),
  };

  return styles;
});

const StyledIcon = styled(Icon)<{
  theme: DefaultTheme;
  disabled?: boolean;
}>(({ theme, disabled }) => {
  const styles: OverridesStyleRules = {};

  return styles;
});

export {
  StyledButton,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledIcon,
  StyledInputLabel,
  StyledTextField,
  StyleFromHelperWrapper,
};
