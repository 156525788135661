import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Box, IconButton, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  return {
    textAlign: 'center'
  };
});
var StyledTitle = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  return _objectSpread(_objectSpread({}, theme.typography.h1), {}, {
    color: theme.colors.white,
    marginBottom: theme.spacing(1.5)
  });
});
var StyledTitleContainer = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = _defineProperty({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    gap: pxToRem(16),
    border: "1px solid ".concat(theme.colors.white10)
  }, theme.breakpoints.down('tablet'), {
    display: 'block',
    padding: 0,
    gap: 'unset',
    border: 'none',
    textAlign: 'center',
    '& h1': {
      fontSize: '16px'
    }
  });
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref4) {
  var theme = _ref4.theme,
    isMobile = _ref4.isMobile;
  return _defineProperty({
    margin: "".concat(isMobile ? pxToRem(30) : pxToRem(32), " auto 0"),
    width: pxToRem(496)
  }, theme.breakpoints.down('tablet'), {
    width: pxToRem(343)
  });
});
var StyledBackButton = styled(IconButton)(function (_ref6) {
  var theme = _ref6.theme;
  return {
    width: pxToRem(52)
    // [theme.breakpoints.up('lg')]: {
    //   display: 'none',
    // },
  };
});
export { StyledBackButton, StyledButtonWrapper, StyledContainer, StyledTitle, StyledTitleContainer };