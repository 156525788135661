import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { ProtectedContent } from '@hocs';

import { Routes } from '@enums';

import { Icon } from '@elements';

import {
  StyledAppBar,
  StyledLogo,
  StyledLogoutButton,
  StyledToolbar,
} from './auth-header.styles';

import logo from '@images/logo.png';
import logoutIcon from '@images/logout.svg';

const AuthHeaderComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAppBar color='transparent' elevation={0} position='relative'>
      <StyledToolbar disableGutters>
        <Box display='flex' gap={1.5} alignItems='center' minWidth={0}>
          <Link to={Routes.Main}>
            <StyledLogo src={logo} alt='logo' />
          </Link>
        </Box>

        <Box display='flex' gap={2} alignItems='center'>
          <ProtectedContent>
            <StyledLogoutButton variant='text' color='lightCoral'>
              <Icon src={logoutIcon} width={24} height={24} />
              {t('components.buttons.logout')}
            </StyledLogoutButton>
          </ProtectedContent>
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export { AuthHeaderComponent };
