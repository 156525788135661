import { FC } from 'react';

import { CircularProgress, Modal } from '@mui/material';

import { LoaderContainer } from './loader.styles';

/**
 * Loader component.
 *
 * @author Ihar Kazlouski
 * @function Loader
 * @category components
 * @return {FC} modal component.
 */
const Loader: FC<{ open: boolean }> = ({ open }) => {
  return (
    <Modal
      role='progressbar'
      disableEnforceFocus
      disableAutoFocus
      disableEscapeKeyDown
      open={open}
      aria-labelledby='loader'
      hideBackdrop
      closeAfterTransition
    >
      <LoaderContainer>
        <CircularProgress disableShrink />
      </LoaderContainer>
    </Modal>
  );
};

export { Loader };
