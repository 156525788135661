import {
  all,
  AllEffect,
  call,
  CallEffect,
  debounce,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { history } from '@history';

import { TerminalService } from '@services';
import { getOrderStatusesByFilters } from '@helpers';

import { Routes } from '@enums';
import { ApiError, AxiosResponse, PayloadAction } from '@types';

import {
  FETCH_TERMINAL_DEFAULT_PAIR_REQUEST,
  FETCH_TERMINAL_ORDERS_REQUEST,
  FETCH_TERMINAL_PAIRS_REQUEST,
} from './terminal.action-types';
import { actions } from './terminal.actions';
import {
  FetchTerminalOrdersRequestPayload,
  FetchTerminalPairsRequestPayload,
  OrdersResponse,
  PairsResponse,
} from './terminal.types';

/**
 * Get pairs saga worker.
 *
 * @author Ilya Khachikyan
 * @function getPairsSagaWorker
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getPairsSagaWorker (
  action: PayloadAction<FetchTerminalPairsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<PairsResponse>
  > {
  try {
    const { data } = yield call(TerminalService.apiPairsGet, {
      networkId:   action.payload.networkId,
      searchQuery: action.payload.searchQuery,
      limit:       5,
    });

    yield put(
      actions.fetchPairsSuccess({
        pairs: data,
      }),
    );
  } catch (error) {
    yield put(
      actions.fetchPairsFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * Get default pair saga worker.
 *
 * @author Ilya Khachikyan
 * @function getDefaultPairSagaWorker
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getDefaultPairSagaWorker (
  action: PayloadAction<FetchTerminalPairsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<PairsResponse>
  > {
  try {
    const { data } = yield call(TerminalService.apiPairsGet, {
      networkId:   action.payload.networkId,
      searchQuery: action.payload.searchQuery,
      limit:       1,
    });

    if (data.results.length > 0) {
      yield put(
        actions.selectPair({
          pair: data.results[0],
        }),
      );
    } else {
      history.push(Routes.Error);
      throw new Error('Default pair not found');
    }
  } catch (error) {
    yield put(
      actions.fetchDefaultPairFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * Get orders saga worker.
 *
 * @author Ilya Khachikyan
 * @function getOrdersSagaWorker
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getOrdersSagaWorker (
  action: PayloadAction<FetchTerminalOrdersRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<OrdersResponse>
  > {
  try {
    const { data } = yield call(TerminalService.apiOrdersGet, {
      networkId:    action.payload.networkId,
      pairId:       action.payload.pairId,
      limit:        action.payload.perPage,
      offset:       (action.payload.page - 1) * action.payload.perPage,
      statusList:   getOrderStatusesByFilters(action.payload.statusList),
      sortingOrder: action.payload.sortingOrder,
    });

    yield put(
      actions.fetchOrdersSuccess({
        orders: data,
      }),
    );
  } catch (error) {
    yield put(
      actions.fetchOrdersFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * Get pairs saga.
 *
 * @author Ilya Khachikyan
 * @function getPairsSagaWatcher
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getPairsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([debounce(500, FETCH_TERMINAL_PAIRS_REQUEST, getPairsSagaWorker)]);
}

/**
 * Get default pair saga.
 *
 * @author Ilya Khachikyan
 * @function getDefaultPairSagaWatcher
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getDefaultPairSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(FETCH_TERMINAL_DEFAULT_PAIR_REQUEST, getDefaultPairSagaWorker),
  ]);
}

/**
 * Get orders saga.
 *
 * @author Ilya Khachikyan
 * @function getOrdersSagaWatcher
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getOrdersSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FETCH_TERMINAL_ORDERS_REQUEST, getOrdersSagaWorker)]);
}

/**
 * Terminal saga.
 *
 * @author Ilya Khachikyan
 * @function terminalSaga
 * @category Sagas
 * @subcategory Terminal
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* terminalSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(getPairsSagaWatcher),
    fork(getDefaultPairSagaWatcher),
    fork(getOrdersSagaWatcher),
  ]);
}
