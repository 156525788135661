import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILURE, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS } from './auth.action-types';
var initialState = {
  pending: false,
  user: null,
  error: null
};

/**
 * Auth reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Auth
 * @param {AuthState} state state.
 * @param {ActionsTypes} action action.
 * @return {AuthState} auth state.
 */
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case FETCH_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = action.payload.user;
          draft.error = null;
        });
      }
    case FETCH_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = action.payload.error;
        });
      }
    case LOGIN_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case LOGIN_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.user = action.payload.user;
          draft.pending = false;
          draft.error = null;
        });
      }
    case LOGIN_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = action.payload.error;
        });
      }
    case LOGOUT_USER_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case LOGOUT_USER_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.user = null;
          draft.error = null;
        });
      }
    case LOGOUT_USER_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
        });
      }
    default:
      {
        return _objectSpread({}, state);
      }
  }
});