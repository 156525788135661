import { TFunction } from 'react-i18next';

import * as yup from 'yup';

export const getTradingMarketBuySchema = (t: TFunction): yup.BaseSchema =>
  yup.object().shape({
    amount: yup
      .number()
      .nullable()
      .required(t('forms.validation.required'))
      .positive(t('forms.validation.positive'))
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    stop_loss: yup.object().shape({
      enabled:              yup.boolean(),
      price_change_percent: yup
        .number()
        .nullable()
        .transform((value: string, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        )
        .when(
          ['enabled'],
          (
            enabled: boolean,
            schema: yup.NumberSchema<number | null | undefined>,
          ) => {
            return enabled
              ? schema.required(t('forms.validation.required'))
              : schema;
          },
        ),
      quantity_percent: yup
        .number()
        .nullable()
        .transform((value: string, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        )
        .when(
          ['enabled'],
          (
            enabled: boolean,
            schema: yup.NumberSchema<number | null | undefined>,
          ) => {
            return enabled
              ? schema.required(t('forms.validation.required'))
              : schema;
          },
        ),
    }),
    take_profit: yup.object().shape({
      enabled:              yup.boolean(),
      price_change_percent: yup
        .number()
        .nullable()
        .transform((value: string, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        )
        .when(
          ['enabled'],
          (
            enabled: boolean,
            schema: yup.NumberSchema<number | null | undefined>,
          ) => {
            return enabled
              ? schema.required(t('forms.validation.required'))
              : schema;
          },
        ),
      quantity_percent: yup
        .number()
        .nullable()
        .transform((value: string, originalValue) =>
          String(originalValue).trim() === '' ? null : value,
        )
        .when(
          ['enabled'],
          (
            enabled: boolean,
            schema: yup.NumberSchema<number | null | undefined>,
          ) => {
            return enabled
              ? schema.required(t('forms.validation.required'))
              : schema;
          },
        ),
    }),
    anti_mev_enabled:     yup.boolean(),
    anti_rug_enabled:     yup.boolean(),
    auto_approve_enabled: yup.boolean(),
    max_gas_limit:        yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    extra_gas_size: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    slippage_percent: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    bribe: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
  });
