import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ResolvedRegister } from '@wagmi/core';

import { history } from '@history';

import { config } from '@utils';
import { WithTheme } from '@hocs';

import { AppRouter } from '@components';

import '@localization';

import AppComponent from './app.component';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <WagmiProvider config={config as ResolvedRegister['config']}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <WithTheme>
          <AppRouter history={history}>
            <AppComponent />
          </AppRouter>
        </WithTheme>
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>,
);
