import React, { FC } from 'react';

import { Box } from '@mui/material';

import { PopupFooterProps } from './popup-footer.types';

/**
 * PopupFooterSlot component.
 *
 * @author Ihar Kazlouski
 * @function PopupFooter
 * @category components
 * @param {PopupFooterProps} content props.
 * @return {JSX.Element} PopupFooterSlot component.
 */
const PopupFooter: FC<PopupFooterProps> = ({ children }): JSX.Element => {
  return <Box>{children}</Box>;
};

PopupFooter.displayName = 'PopupFooter';

export { PopupFooter };
