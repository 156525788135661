import React, { FC } from 'react';

import { StyledModalFooterContainer } from './modal-footer.styled';
import { ModalFooterProps } from './modal-footer.types';

/**
 * ModalFooterSlot component.
 *
 * @author Ihar Kazlouski
 * @function ModalFooter
 * @category components
 * @param {ModalFooterProps} content props.
 * @return {JSX.Element} ModalFooterSlot component.
 */
const ModalFooter: FC<ModalFooterProps> = ({ children }): JSX.Element => {
  return <StyledModalFooterContainer>{children}</StyledModalFooterContainer>;
};

ModalFooter.displayName = 'ModalFooter';

export { ModalFooter };
