import { FC, ReactElement } from 'react';

import { Box, Grid, RadioGroup } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledCheckedIcon,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyledRadioButton,
  StyledRadioItem,
  StyledUncheckedIcon,
  StyleFormHelperWrapper,
} from './radio-group-field.styles';

import Check from '@images/check.svg';

import { RadioGroupFieldProps } from './radio-group-field.types';

/**
 * RadioGroup component.
 *
 * @author Ilya Khachikyan
 * @function RadioGroup
 * @category components
 * @return {JSX.Element} radio-group component.
 */
const RadioGroupField: FC<RadioGroupFieldProps> = ({
  name,
  color,
  label,
  bottomLabel,
  error,
  ref,
  focused,
  labelProps,
  containerProps,
  fieldProps,
  options = [],
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-radio-group`;

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  return (
    <StyledFormControl error={isError} focused={focused}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        {label && (
          <Grid item xs={12} {...labelProps}>
            <StyledInputLabel shrink htmlFor={literalProps.htmlFor}>
              {label}
            </StyledInputLabel>
          </Grid>
        )}
        <Grid item flex=' 1' {...fieldProps}>
          <Box position='relative'>
            <RadioGroup
              ref={ref}
              id={literalProps.id}
              aria-describedby={inputName}
              name={name}
              {...restProps}
            >
              <StyledRadioItem
                value=''
                hidden
                control={<StyledRadioButton />}
                label=''
              />
              {options.map(
                (option): ReactElement => (
                  <StyledRadioItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    label={option.label}
                    control={
                      <StyledRadioButton
                        icon={<StyledUncheckedIcon />}
                        checkedIcon={
                          <StyledCheckedIcon>
                            <Icon src={Check} width={20} height={20} />
                          </StyledCheckedIcon>
                        }
                      />
                    }
                  />
                ),
              )}
            </RadioGroup>
            {isError && (
              <StyleFormHelperWrapper>
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  {error}
                </StyledFormHelperErrorText>
              </StyleFormHelperWrapper>
            )}
            {bottomLabel && !isError ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { RadioGroupField };
