import { alpha, Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const ErrorContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      flexDirection:   'column',
      width:           '100%',
      backgroundColor: alpha(theme.palette.cornflowerBlue.main, 0.06),
      padding:         theme.spacing(4, 3.5),
      borderRadius:    pxToRem(24),
    };

    return styles;
  },
);

const IconContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      backgroundColor: alpha(theme.palette.cornflowerBlue.main, 0.2),
      padding:         theme.spacing(1),
      borderRadius:    pxToRem(16),
      lineHeight:      0,
    };

    return styles;
  },
);

export {
  ErrorContainer,
  IconContainer,
};
