import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { AccountCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

import { PopupsService } from '@services';

import { Popups, Routes } from '@enums';

import { Icon } from '@elements';

import {
  StyledContainer,
  StyledMenu,
  StyledMenuCloseButton,
  StyledMenuNavLink,
  StyledNavLink,
} from './bottom-navigation.styles';

import cashIcon from '@assets/images/cash.svg';
import chevronRightIcon from '@assets/images/chevron-right.svg';
import closeIcon from '@assets/images/close-circle.svg';
import compassIcon from '@assets/images/compass.svg';
import diamondIcon from '@assets/images/diamond.svg';
import walletIcon from '@assets/images/wallet2.svg';

import { navigationMenuLinks } from './bottom-navigation.constants';

/**
 * BottomNavigation component.
 *
 * @author Ilya Khachikyan
 * @function BottomNavigationComponent
 * @category components
 * @return {FC} botton navigation component.
 */
export const BottomNavigationComponent: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isNavigationMenuOpen = Boolean(anchorEl);

  const menuNavLinksAcitve = useMemo(() => {
    const paths = navigationMenuLinks.map((link) => link.path);
    for (const path of paths) {
      if (matchPath({ path }, pathname)) {
        return true;
      }
    }
    return false;
  }, [pathname]);

  const handleNavigationMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleNavigationMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <StyledContainer>
      <Grid container columns={5}>
        <Grid display='flex' item xs={1}>
          <StyledNavLink component={NavLink} to={Routes.NewPairs}>
            <Icon src={compassIcon} width={24} height={24} />
            <Typography variant='caption2' color='inherit'>
              {t('common.navigation.newPairs')}
            </Typography>
          </StyledNavLink>
        </Grid>

        <Grid display='flex' item xs={1}>
          <StyledNavLink
            component={NavLink}
            to={Routes.Strategies}
            disabled={true}
          >
            <Icon src={diamondIcon} width={24} height={24} />
            <Typography variant='caption2' color='inherit'>
              {t('common.navigation.strategies')}
            </Typography>
          </StyledNavLink>
        </Grid>

        <Grid display='flex' item xs={1}>
          <StyledNavLink
            onClick={handleNavigationMenuOpen}
            active={menuNavLinksAcitve}
          >
            <Icon src={cashIcon} width={24} height={24} />
            <Typography variant='caption2' color='inherit'>
              {t('components.bottomNavigation.menuLabel')}
            </Typography>
          </StyledNavLink>

          <StyledMenu
            anchorEl={anchorEl}
            open={isNavigationMenuOpen}
            onClose={handleNavigationMenuClose}
            anchorOrigin={{
              vertical:   'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical:   'bottom',
            }}
          >
            {navigationMenuLinks.map((link) => (
              <StyledMenuNavLink
                component={NavLink}
                to={link.path}
                key={link.path}
                onClick={handleNavigationMenuClose}
                disabled={link.disabled}
              >
                <Typography variant='body1' fontWeight={500} color='inherit'>
                  {t(link.i18nLabelKey)}
                </Typography>
                <Icon
                  src={chevronRightIcon}
                  width={20}
                  height={20}
                  color='inherit'
                />
              </StyledMenuNavLink>
            ))}

            <StyledMenuCloseButton onClick={handleNavigationMenuClose}>
              <Icon src={closeIcon} width={44} height={44} />
            </StyledMenuCloseButton>
          </StyledMenu>
        </Grid>

        <Grid display='flex' item xs={1}>
          <StyledNavLink component={NavLink} to={Routes.Wallet}>
            <Icon src={walletIcon} width={24} height={24} />
            <Typography variant='caption2' color='inherit'>
              {t('common.navigation.wallet')}
            </Typography>
          </StyledNavLink>
        </Grid>

        <Grid display='flex' item xs={1}>
          <StyledNavLink
            component={NavLink}
            onClick={(): void => void PopupsService.open(Popups.Profile)}
          >
            <AccountCircle color='inherit' fontSize='medium' />
            <Typography variant='caption2' color='inherit'>
              {t('common.navigation.profile')}
            </Typography>
          </StyledNavLink>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
