import { FC } from 'react';

import {
  ErrorPopup,
  FollowInstructionsComponent,
  InfoPopup,
  ProfileComponent,
  RegistrationPopup,
  SavedSeedComponent,
  SuccessPopup,
  WalletActionPopUp,
} from '@popups';

import { Popups } from '@enums';

export const popupVariants: Record<string, FC> = {
  [Popups.Success]:            SuccessPopup,
  [Popups.Info]:               InfoPopup,
  [Popups.Error]:              ErrorPopup,
  [Popups.Registration]:       RegistrationPopup,
  [Popups.FollowInstructions]: FollowInstructionsComponent,
  [Popups.SavedSeed]:          SavedSeedComponent,
  [Popups.WalletAction]:       WalletActionPopUp,
  [Popups.Profile]:            ProfileComponent,
};
