import { Environment, Feature } from '@enums';
import { FeatureAvailableConfig, FeaturesAvailableConfig } from '@types';

/**
 * Features service.
 *
 * @author Ihar Kazlouski
 * @class FeaturesServiceClass
 * @category Services
 */
class FeaturesServiceClass {
  /**
   * Available configs.
   *
   * @property {object} configs available configs.
   * @private
   */
  private configs: FeaturesAvailableConfig = {
    [Feature.ServiceWorker]: {
      [Environment.Dev]:     true,
      [Environment.Test]:    true,
      [Environment.Preprod]: true,
      [Environment.Prod]:    true,
    },
    [Feature.MockAPI]: {
      [Environment.Dev]:     true,
      [Environment.Test]:    true,
      [Environment.Preprod]: true,
      [Environment.Prod]:    true,
    },
  };

  /**
   * Is feature available by config.
   *
   * @author Ihar Kazlouski
   * @private
   * @param {FeatureAvailableConfig} config feature config.
   * @return {boolean} available.
   */
  private static isFeatureAvailable (config: FeatureAvailableConfig): boolean {
    switch (process.env.server) {
      case 'dev': {
        return config.dev;
      }

      case 'test': {
        return config.test;
      }

      case 'preprod': {
        return config.preprod;
      }

      case 'prod': {
        return config.prod;
      }

      default: {
        return false;
      }
    }
  }

  /**
   * Is feature available.
   *
   * @author Ihar Kazlouski
   * @private
   * @param {Feature} featureName feature name.
   * @return {boolean} available.
   */
  available (featureName: Feature): boolean {
    return FeaturesServiceClass.isFeatureAvailable(this.configs[featureName]);
  }
}

const FeaturesService = new FeaturesServiceClass();

export { FeaturesService };
