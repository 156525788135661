import { ExoticComponent } from 'react';

/**
 * Retry lazy page component.
 *
 * @author Ihar Kazlouski
 * @function Retry
 * @category Hocs
 * @param {Function} fn function.
 * @param {number} retriesLeft retries left.
 * @param {number} interval interval value.
 * @return {Promise<ExoticComponent>} load promise.
 */
function Retry (
  fn: () => Promise<ExoticComponent & { default: ExoticComponent }>,
  retriesLeft = 3,
  interval = 1000,
): Promise<ExoticComponent & { default: ExoticComponent }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            // Refresh page for get new index.html
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          Retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export { Retry };
