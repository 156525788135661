export enum Language {
  Ru = 'ru',
  En = 'en',
}

export enum Authentication {
  Registered = 0,
  New = 1,
}

export enum ProfileTabs {
  Friends = 'Пригласить друзей',
  Education = 'Пройти обучение',
  Language = 'Язык',
  Trade = 'Торговать на другой версии',
  Security = 'Безопасность',
  Notifications = 'Уведомления',
  Theme = 'Тема оформления',
  TradeSettings = 'Настройки параметров торговли',
  Help = 'Документация и поддержка',
  About = 'О нас',
  Logout = 'Выйти из аккаунта',
  Default = 'Default',
  TerminalTradingSettings = 'Торговые настройки терминала',
  SnipingSettings = 'Настройки снайпинга',
  StrategySettings = 'Настройки стратегий',
  CopyTradingSettings = 'Настройки копитрейдинга',
}

export enum WalletActionsTab {
  Deposit = 'Deposit',
  Transfer = 'Transfer',
  Withdraw = 'Withdraw',
}

export enum WalletTab {
  Assets = 'Assets',
  Orders = 'Orders',
  TransactionHistory = 'TransactionHistory',
}

export enum TerminalTradingSettingsTabs {
  LIMIT_BUY = 'LIMIT_BUY',
  LIMIT_SELL = 'LIMIT_SELL',
  MARKET_BUY = 'MARKET_BUY',
  MARKET_SELL = 'MARKET_SELL',
  APPROVE = 'APPROVE',
}

export enum WalletOption {
  Delete = 'Delete',
  Update = 'Update',
  Add = 'Add',
}

export enum Networks {
  Ethereum = 'Ethereum',
  Solana = 'Solana',
}

export enum SortingOrderDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export enum TransactionType {
  Withdraw = 'WITHDRAW',
  Deposit = 'DEPOSIT',
  AddLiquidity = 'ADD_LIQUIDITY',
  RemoveLiquidity = 'REMOVE_LIQUIDITY',
  SwapIn = 'SWAP_IN',
  SwapOut = 'SWAP_OUT',
}

export enum TransactionDirection {
  In = 'in',
  Out = 'out',
}

export enum ChartResolution {
  Minute1 = '1min',
  Minute5 = '5min',
  Minute15 = '15min',
  Minute30 = '30min',
  Hour1 = '1h',
  Hour2 = '2h',
  Hour4 = '4h',
  Day = '1D',
  Week = '1W',
  Month = '1ME',
}

export enum OrderType {
  Limit = 'LIMIT',
  Market = 'MARKET',
  StopLoss = 'STOP_LOSS',
  TakeProfit = 'TAKE_PROFIT',
}

export enum OrderSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum OrderStatus {
  Created = 'CREATED',
  Process = 'PROCESS',
  WaitingForResult = 'WAITING_FOR_RESULT',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
}

export enum OrderStatusFilter {
  Active = 'active',
  Completed = 'completed',
  Failed = 'failed',
}

export enum OrderMarketCapSide {
  Up = 'up',
  Down = 'down',
}

export enum TerminalChartWSMessageType {
  AllBars = 'ALL_BARS',
  LastBarUpdate = 'LAST_BAR_UPDATE',
  NewBar = 'NEW_BAR',
}
