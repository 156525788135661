import InlineSVG from 'svg-inline-react';

import { styled } from '@mui/material';

import { IconComponentProps } from '@elements/icon/icon.types';

import { pxToRem } from '@themes';

const StyledIcon = styled((props: IconComponentProps) => (
  <InlineSVG raw {...props} />
))`
  display: flex;

  .fill {
    fill: currentColor;
  }

  .stroke {
    stroke: currentColor;
  }

  width: ${(props: IconComponentProps): string =>
    typeof props.width === 'string' ? props.width : pxToRem(props.width || 20)};
  height: ${(props: IconComponentProps): string =>
    typeof props.height === 'string'
      ? props.height
      : pxToRem(props.height || 20)};

  svg {
    width: ${(props: IconComponentProps): string =>
      typeof props.width === 'string'
        ? props.width
        : pxToRem(props.width || 20)};
    height: ${(props: IconComponentProps): string =>
      typeof props.height === 'string'
        ? props.height
        : pxToRem(props.height || 20)};
  }
`;

export { StyledIcon };
