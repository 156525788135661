import { NavLink } from 'react-router-dom';

import {
  AppBar,
  Box,
  Divider,
  IconButton,
  styled,
  Toolbar,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { Button } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledAppBar = styled(AppBar)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:      theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.white10.main}`,
  };

  return styles;
});

const StyledToolbar = styled(Toolbar)(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:        'flex',
    minHeight:      theme.spacing(7),
    width:          '100%',
    gap:            theme.spacing(2.5),
    justifyContent: 'space-between',
  };

  return styles;
});

const StyledLogo = styled('img')(() => {
  const styles: OverridesStyleRules = {
    height: pxToRem(40),
    width:  'auto',
  };

  return styles;
});

const StyledNavBar = styled(Box)(() => {
  const styles: OverridesStyleRules = {
    overflow:        'auto',
    msOverflowStyle: 'none',
    scrollbarWidth:  'none',

    [`&::-webkit-scrollbar`]: {
      display: 'none',
    },
  };

  return styles;
});

const StyledNavLink = styled(NavLink)<{
  theme?: DefaultTheme;
  disabled?: boolean;
}>(({ theme, disabled }) => {
  const styles: OverridesStyleRules = {
    textDecoration: 'none',
    color:          theme.palette.white.main,
    fontWeight:     500,
    fontSize:       pxToRem(14),
    lineHeight:     pxToRem(18),
    whiteSpace:     'nowrap',
    padding:        theme.spacing(1, 1.25),
    borderRadius:   theme.spacing(4),
    transition:     'all 0.3s ease-in-out',

    '&.active': {
      color:      theme.palette.blue.main,
      background: theme.palette.white8.main,
    },

    ...(disabled
      ? {
          pointerEvents: 'none',
          color:         theme.palette.white50.main,
        }
      : {}),
  };

  return styles;
});

const StyledIconsToolbar = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding:      theme.spacing(1),
      borderRadius: theme.spacing(4),
      background:   theme.palette.white8.main,
    };

    return styles;
  },
);

const StyledIconButton = styled(IconButton)<{
  theme?: DefaultTheme;
  component?: string;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:    theme.spacing(0),
    flexShrink: 0,
    color:      'inherit',
  };

  return styles;
});

const StyledDivider = styled(Divider)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    borderColor: theme.palette.white10.main,
  };

  return styles;
});

const StyledAccountButton = styled(IconButton)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      fontSize: pxToRem(48),
      padding:  theme.spacing(0),
    };

    return styles;
  },
);

const StyledConnectButton = styled(Button)(({ theme }) => {
  const styles: OverridesStyleRules = {
    whiteSpace: 'nowrap',
  };

  return styles;
});

export {
  StyledAccountButton,
  StyledAppBar,
  StyledConnectButton,
  StyledDivider,
  StyledIconButton,
  StyledIconsToolbar,
  StyledLogo,
  StyledNavBar,
  StyledNavLink,
  StyledToolbar,
};
