import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AccountCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { GasPriceResponse } from '@store/network';
import { getWalletsSelector } from '@store/wallet/wallet.selectors';

import { NetworkService, PopupsService } from '@services';
import { formatWithSubscript } from '@utils';
import { ProtectedContent } from '@hocs';
import { isDefined } from '@helpers';

import { Popups, Routes } from '@enums';

import { LanguageSelect } from '@components/language-select';
import { NetworkMenu } from '@components/network-menu';
import { Icon } from '@elements';

import {
  StyledAccountButton,
  StyledAppBar,
  StyledConnectButton,
  StyledDivider,
  StyledIconButton,
  StyledIconsToolbar,
  StyledLogo,
  StyledNavBar,
  StyledNavLink,
  StyledToolbar,
} from './public-header.styles';

import lightningIcon from '@images/lightning.svg';
import logo from '@images/logo.png';
import walletIcon from '@images/wallet.svg';

import { iconsToolbarLinks, navLinks } from './public-header.constants';

const PublicHeaderComponent: FC = () => {
  const { t } = useTranslation();
  const wallets = useSelector(getWalletsSelector);
  const [gasPrice, setGasPrice] = useState<GasPriceResponse | null>(null);

  const { totalUsdBalance, totalDifference } = useMemo(() => {
    return wallets.reduce(
      (total, wallet) => ({
        totalUsdBalance: total.totalUsdBalance + parseFloat(wallet.balance_usd),
        totalDifference: total.totalDifference + parseFloat(wallet.difference),
      }),
      {
        totalUsdBalance: 0,
        totalDifference: 0,
      },
    );
  }, [wallets]);

  useEffect(() => {
    void NetworkService.apiGasPriceGet()
      .then(({ data }) => {
        setGasPrice(data);
      })
      .catch();
  }, []);

  return (
    <StyledAppBar color='transparent' elevation={0} position='relative'>
      <StyledToolbar disableGutters>
        <Box display='flex' gap={1.5} alignItems='center' minWidth={0}>
          <Link to={Routes.Main}>
            <StyledLogo src={logo} alt='logo' />
          </Link>

          <StyledNavBar
            component='nav'
            display='flex'
            gap={0.5}
            overflow='auto'
          >
            {navLinks.map((navLink) => (
              <StyledNavLink
                to={navLink.path}
                key={navLink.path}
                disabled={navLink.disabled}
              >
                {t(navLink.i18nLabelKey)}
              </StyledNavLink>
            ))}
          </StyledNavBar>

          <StyledIconsToolbar display='flex' gap={1}>
            {iconsToolbarLinks.map((link, index) => (
              <StyledIconButton key={index}>
                <Icon src={link.icon} width={24} height={24} />
              </StyledIconButton>
            ))}
          </StyledIconsToolbar>
        </Box>

        <Box display='flex' gap={2} alignItems='center'>
          <LanguageSelect />

          <StyledDivider orientation='vertical' flexItem />

          {isDefined(gasPrice) && (
            <Box display='flex' gap={1} alignItems='center'>
              <Icon src={lightningIcon} width={24} height={24} color='white' />

              <Box display='flex' flexDirection='column'>
                <Typography
                  component='p'
                  variant='caption1'
                  color='white'
                  fontWeight={500}
                >
                  {formatWithSubscript(+gasPrice.amount)}
                </Typography>
                <Typography
                  component='p'
                  variant='caption2'
                  color='white50'
                  fontWeight={500}
                >
                  gwei
                </Typography>
              </Box>
            </Box>
          )}

          <StyledDivider orientation='vertical' flexItem />

          <NetworkMenu />

          <ProtectedContent
            fallback={
              <StyledConnectButton
                variant='outlined'
                onClick={(): void =>
                  void PopupsService.open(Popups.Registration)
                }
              >
                {t('components.header.connect')}
              </StyledConnectButton>
            }
          >
            <Box display='flex' gap={1} alignItems='center'>
              <Icon src={walletIcon} width={24} height={24} color='white' />

              <Box display='flex' flexDirection='column'>
                <Typography
                  whiteSpace='nowrap'
                  component='p'
                  variant='caption1'
                  color='white'
                  fontWeight={500}
                >
                  {totalUsdBalance}
                </Typography>
                <Typography
                  component='p'
                  variant='caption2'
                  color='white50'
                  fontWeight={500}
                >
                  {totalDifference}%
                </Typography>
              </Box>
            </Box>

            <StyledAccountButton
              onClick={(): void => void PopupsService.open(Popups.Profile)}
            >
              <AccountCircle color='white' fontSize='inherit' />
            </StyledAccountButton>
          </ProtectedContent>
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export { PublicHeaderComponent };
