import { Field } from 'react-final-form';

import { useTheme } from '@mui/material';

import { getObjectWithOnlyProps } from '@helpers';

import { FormFieldProps } from './form-field.types';

/**
 * FormField component.
 *
 * @author Ihar Kazlouski
 * @function TextFieldForm
 * @category components
 * @return {JSX.Element} textfield form component.
 */
const FormField = ({
  name,
  component,
  type,
  parse,
  format,
  formatOnBlur,
  ...restProps
}: FormFieldProps): JSX.Element => {
  const FieldComponent = component;
  const theme = useTheme();
  const clearRestProps = getObjectWithOnlyProps(restProps);

  return (
    <Field
      name={name}
      type={type}
      parse={parse}
      format={format}
      formatOnBlur={formatOnBlur}
      render={({ input, meta: { error, touched } }): JSX.Element => {
        const errorField = touched && (error as string | undefined);
        const handlers: ('onBlur' | 'onFocus' | 'onChange')[] = [
          'onBlur',
          'onFocus',
          'onChange',
        ];
        const preparedInput = { ...input, value: input.value || '' };

        for (const handler of handlers) {
          if (handler && restProps && restProps[handler]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            preparedInput[handler] = (e: any): void => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              let event: any;

              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (e?.target) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                event = e;
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                event = { target: { value: e } };
              }

              input[handler](event);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              restProps[handler](event);
            };
          }
        }

        return (
          <FieldComponent
            type={type}
            {...clearRestProps}
            {...preparedInput}
            error={errorField}
            // helperText={errorField}
            theme={theme}
          />
        );
      }}
    />
  );
};

export { FormField };
