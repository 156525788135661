import { ApiService } from '@services';

import { ChartResolution } from '@enums';
import {
  AxiosResponse,
  ChartHistoryResponse,
  SwapTransactionsResponse,
} from '@types';

/**
 * Transactions controller.
 *
 * @author Ilya Khachikyan
 * @const TransactionskServiceClass
 * @category Services
 * @subcategory Transactions
 */
class TransactionsServiceClass {
  /**
   * Get swap transactions api function.
   *
   * @author Ilya Khachikyan
   * @return {Promise<AxiosResponse<SwapTransactionsResponse>} promise.
   */
  async apiSwapTransactionsGet (
    networkId: string,
    pairId: string,
    limit?: number,
    offset?: number,
  ): Promise<AxiosResponse<SwapTransactionsResponse>> {
    return ApiService.apiGet(
      `/public/networks/${networkId}/pairs/${pairId}/swap_transactions`,
      {
        params: { limit, offset },
      },
    ) as Promise<AxiosResponse<SwapTransactionsResponse>>;
  }

  async apiChartHistoryGet (
    networkId: string,
    pairId: string,
    interval: ChartResolution = ChartResolution.Day,
    count?: number,
  ): Promise<AxiosResponse<ChartHistoryResponse>> {
    return ApiService.apiGet(
      `/public/networks/${networkId}/trading-view/${pairId}`,
      {
        params: { count, interval },
      },
    ) as Promise<AxiosResponse<ChartHistoryResponse>>;
  }
}

const TransactionsService = new TransactionsServiceClass();

export { TransactionsService };
