import { dispatch } from '@store';
import { actions as actionsAuth } from '@store/auth';

import { history } from '@history';

import { AuthService } from '@services';
import { ConstantsUtil } from '@utils';
import { getPopupErrorMessage } from '@helpers';

import { ApiResponse, Routes } from '@enums';
import { ApiError, ApiErrorObject, AxiosError } from '@types';

/**
 * Error interceptor, catch all API errors
 *
 * @author Ihar Kazlouski
 * @function ErrorInterceptor
 * @category Interceptors
 * @param {AxiosError<ApiError>} error api error.
 * @return {Promise<ApiError>} api error.
 */
const ErrorInterceptor = (error: AxiosError<ApiError>): Promise<ApiError> => {
  const errorObject: ApiErrorObject = {
    status:  undefined,
    cause:   undefined,
    name:    '',
    stack:   '',
    message: '',
  };

  const errorRedirect = error.config.errorRedirect;

  /**
   * Redirect to error page handler.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const redirectToErrorPage = (error: ApiErrorObject): void => {
    if (errorRedirect) {
      const convertedError = getPopupErrorMessage(error);
      history.push(Routes.Error, convertedError);
    }
  };

  if (error.response) {
    // Request made and server responded
    errorObject.status = error?.response?.status;
    errorObject.name =
      error?.response?.data?.name || error?.response?.data?.error;
    errorObject.message =
      error?.response?.data?.message ||
      error?.response?.data?.error_description;

    switch (error.response.status) {
      case ApiResponse.Forbidden: {
        AuthService.removeTokenSessionStorage();
        localStorage.clear();
        dispatch(actionsAuth.logoutUserSuccess());
        break;
      }

      default: {
        /* empty */
      }
    }
  } else if (error.request) {
    // The request was made but no response was received
    errorObject.message = ConstantsUtil.errors.NETWORK_ERROR;

    redirectToErrorPage(errorObject);
  } else {
    // Something happened in setting up the request that triggered an Error
    errorObject.message = ConstantsUtil.errors.NETWORK_SETTING_ERROR;

    redirectToErrorPage(errorObject);
  }
  const convertedError = getPopupErrorMessage(errorObject);

  return Promise.reject(convertedError);
};

export { ErrorInterceptor };
