import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getSnackbarSelector } from '@store/snackbars/snackbars.selectors';

import { SnackbarsService } from '@services';

import { Snackbars } from '@enums';

import { Icon } from '@elements';

import { SnackbarAlert, StyledSnackbar } from './snackbar.styles';

import ErrorIcon from '@images/ep-alert.svg';
import SuccessIcon from '@images/ep-success.svg';

import { SnackbarComponentProps } from './snackbar.types';

/**
 * Snackbar component.
 *
 * @author Ihar Kazlouski
 * @function SnackbarComponent
 * @category components
 * @param {string} snackbarId snackbar id.
 * @return {FC} snackbar component.
 */
const SnackbarComponent: FC<SnackbarComponentProps> = ({ snackbarId }) => {
  const snackBar = useSelector(getSnackbarSelector(snackbarId));

  /**
   * Reject snackbar.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const rejectSnackbar = useCallback((): void => {
    SnackbarsService.close(snackbarId);
  }, [snackbarId]);

  /**
   * Close snackbar.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClose = useCallback((): void => {
    rejectSnackbar();
  }, [rejectSnackbar]);

  return (
    <>
      <StyledSnackbar
        open={snackBar && snackBar.isOpen ? snackBar.isOpen : false}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical:   snackBar?.data?.vertical || 'top',
          horizontal: snackBar?.data?.horizontal || 'left',
        }}
        ClickAwayListenerProps={{
          onClickAway: (): void => {
            // empty
          },
        }}
      >
        <SnackbarAlert
          severity={snackbarId === Snackbars.Success ? 'success' : 'error'}
          sx={{ width: '100%' }}
          iconMapping={{
            success: <Icon src={SuccessIcon} width={16} height={16} />,
            error:   <Icon src={ErrorIcon} width={16} height={16} />,
          }}
        >
          {snackBar?.data?.title || ''}
        </SnackbarAlert>
      </StyledSnackbar>
    </>
  );
};

export { SnackbarComponent };
