import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns/esm';

import Box from '@mui/material/Box';

import { convertedDateToObject } from '@helpers';

import {
  StyledDayPickerButtonsWrapper,
  StyledPopover,
} from './day-picker-field.styles';

import {
  DayPicker,
  DayPickerButtons,
  DayPickerTextfield,
  RangeButtonProps,
  RangeButtons,
} from './components';
import { DatePickerRangeProps } from './day-picker-field.types';
/**
 * Date Picker Range component.
 *
 * @author Ihar Kazlouski
 * @function DatePickerRange
 * @category components
 * @param {DatePickerRangeProps} params day picker range params.
 * @return {JSX.Element} DatePicker component.
 */
const DayPickerRange = ({
  toDate,
  fromDate,
  value,
  range,
  name,
  acceptButton,
  cancelButton,
  numberOfMonths = 1,
  error,
  locale,
  localization,
  onChange,
  ...textfieldProps
}: DatePickerRangeProps): JSX.Element => {
  const { t } = useTranslation();
  const initialRange = {
    to:   convertedDateToObject(value?.to),
    from: convertedDateToObject(value?.from),
  };

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedRange, setSelectedRange] = useState<DateRange>(initialRange);

  const open = Boolean(anchorEl);
  const id = open ? `daypicker-${name || 'field'}` : undefined;

  /**
   * Click handler.
   *
   * @author Ihar Kazlouski
   * @param {React.MouseEvent<HTMLInputElement>} event event.
   * @return {void}
   */
  const handleClick = (event: React.MouseEvent<HTMLInputElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Close handler.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClose = useCallback((): void => {
    setAnchorEl(null);

    setSelectedRange({
      to:   convertedDateToObject(value?.to),
      from: convertedDateToObject(value?.from),
    });
  }, [value?.from, value?.to]);

  /**
   * Click handler.
   *
   * @author Ihar Kazlouski
   * @param {SelectRangeEventHandler} params range select.
   * @return {void}
   */
  const handleRangeSelect: SelectRangeEventHandler = useCallback(
    (range: DateRange | undefined, ...props): void => {
      setSelectedRange({
        to:   convertedDateToObject(range?.to),
        from: convertedDateToObject(range?.from),
      });
    },
    [],
  );

  /**
   * Get formatted value.
   *
   * @author Ihar Kazlouski
   * @param {DateRange | undefined} value range value.
   * @return {string}
   */
  const getFormattedValue = (value: DateRange | undefined): string => {
    const formattedValues = ['XX.XX.XX', 'XX.XX.XX'];

    if (!value || (!value.from && !value.to)) {
      return '';
    }

    if (value.from) {
      formattedValues[0] = format(value.from, 'dd.MM.yy');
    }

    if (value.to) {
      formattedValues[1] = format(value.to, 'dd.MM.yy');
    }

    return formattedValues.join(' - ');
  };

  /**
   * Change date handler.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleChangeDate = useCallback((): void => {
    if (onChange) {
      const newValues = {
        target: {
          value: {
            to:   selectedRange?.to,
            from: selectedRange?.from,
          },
        },
      } as unknown as ChangeEvent<HTMLInputElement>;

      onChange(newValues);
    }

    setAnchorEl(null);

    setSelectedRange({
      to:   selectedRange?.to,
      from: selectedRange?.from,
    });
  }, [onChange, selectedRange?.from, selectedRange?.to]);

  /**
   * Change date handler by bytton.
   *
   * @author Ihar Kazlouski
   * @param {{to: RangeButtonProps['to'];from: RangeButtonProps['from'];}} range value.
   * @return {void}
   */
  const handleButtonRange = useCallback(
    (range: { to: RangeButtonProps['to']; from: RangeButtonProps['from'] }) =>
      () => {
        setSelectedRange({
          to:   convertedDateToObject(range.to),
          from: convertedDateToObject(range.from),
        });
      },
    [],
  );

  /**
   * Clear date value by bytton.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClearButton = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      if (onChange) {
        const newValues = {
          target: {
            value: {
              to:   undefined,
              from: undefined,
            },
          },
        } as unknown as ChangeEvent<HTMLInputElement>;

        onChange(newValues);
        setSelectedRange({
          to:   undefined,
          from: undefined,
        });
      }
    },
    [onChange],
  );

  const formattedValue = getFormattedValue(value);

  const textError =
    typeof error === 'string' ? error : error && (error?.from || error?.to);

  return (
    <Box>
      <DayPickerTextfield
        name={name}
        {...textfieldProps}
        value={formattedValue}
        onClick={handleClick}
        error={textError}
        focused={Boolean(open)}
        handleClearButton={handleClearButton}
      />
      <Box position='relative'>
        <StyledPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            elevation: 0,
          }}
        >
          <Box>
            {range && (
              <RangeButtons
                range={range}
                selectedRange={selectedRange}
                handleButtonRange={handleButtonRange}
              />
            )}
            <DayPicker
              fromDate={fromDate}
              toDate={toDate}
              numberOfMonths={numberOfMonths}
              mode='range'
              selected={selectedRange}
              onSelect={handleRangeSelect}
              locale={locale}
              localization={localization}
              setSelectedDate={setSelectedRange}
              showOutsideDays
              fixedWeeks
            />
            <StyledDayPickerButtonsWrapper>
              <DayPickerButtons
                numberOfMonths={numberOfMonths}
                handleClose={handleClose}
                handleChangeDate={handleChangeDate}
                acceptButton={t('common.datePicker.chooseDates')}
                cancelButton={t('common.datePicker.cancel')}
              />
            </StyledDayPickerButtonsWrapper>
          </Box>
        </StyledPopover>
      </Box>
    </Box>
  );
};

export { DayPickerRange };
