export const getShortBlockchainHash = (hash: string): string => {
  const endIndex = hash.length - 1;
  return `${hash.slice(0, 6)}...${hash.slice(endIndex - 4, endIndex)}`;
};

export const getCompactAmount = (
  locale: string,
  amount: string | number,
): string => {
  const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  return parsedAmount
    .toLocaleString(locale, { notation: 'compact' })
    .replace(',', '.');
};
