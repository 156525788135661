import { produce } from 'immer';

import {
  FETCH_PAIRS_FAILURE,
  FETCH_PAIRS_REQUEST,
  FETCH_PAIRS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
} from './statistic.action.types';
import { ActionsTypes, StatisticState } from './statistic.types';

const initialState: StatisticState = {
  pairsCount:          null,
  pairsPending:        false,
  transactionsPending: false,
  pairs:               null,
  error:               null,
  transactions:        null,
  transactionsCount:   null,
};

/**
 * Statistic reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Statistic
 * @param {NetworksState} state state.
 * @param {ActionsTypes} action action.
 * @return {NetworksState} Statistic state.
 */
export default (state = initialState, action: ActionsTypes): StatisticState => {
  switch (action.type) {
    case FETCH_PAIRS_REQUEST: {
      return produce(state, (draft) => {
        draft.pairsPending = true;
      });
    }

    case FETCH_PAIRS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pairsPending = false;
        draft.pairs = action.payload.pairs.results;
        draft.pairsCount = action.payload.pairs.count;
        draft.error = null;
      });
    }

    case FETCH_PAIRS_FAILURE: {
      return produce(state, (draft) => {
        draft.pairsPending = false;
        draft.pairs = null;
        draft.pairsCount = null;
        draft.error = action.payload.error;
      });
    }

    case FETCH_TRANSACTIONS_REQUEST: {
      return produce(state, (draft) => {
        draft.transactionsPending = true;
      });
    }

    case FETCH_TRANSACTIONS_SUCCESS: {
      return produce(state, (draft) => {
        draft.transactionsPending = false;
        draft.transactions = action.payload.transactions.results;
        draft.transactionsCount = action.payload.transactions.count;
        draft.error = null;
      });
    }

    case FETCH_TRANSACTIONS_FAILURE: {
      return produce(state, (draft) => {
        draft.transactionsPending = false;
        draft.transactions = null;
        draft.transactionsCount = null;
        draft.error = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
