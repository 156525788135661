import { alpha, createTheme } from '@mui/material';

import { Theme } from '@enums';

import { colors } from '../colors';
import {
  CeraProBoldWoff2,
  CeraProMediumWoff2,
  CeraProRegularWoff2,
} from '../fonts';

const {
  breakpoints,
  palette,
  spacing,
  typography: { pxToRem },
} = createTheme({
  breakpoints: {
    values: {
      xs:      0,
      sm:      600,
      md:      768,
      lg:      1280,
      xl:      1536,
      mobile:  0,
      tablet:  768,
      desktop: 1280,
    },
  },
});

const defaultTheme = createTheme(
  {
    breakpoints: {
      values: breakpoints.values,
    },
    palette: {
      mode:       Theme.Light,
      background: {
        default: colors.mirage,
      },
      mirage: palette.augmentColor({
        color: {
          main: colors.mirage,
        },
      }),
      white: palette.augmentColor({
        color: {
          main: colors.white,
        },
      }),
      white50: palette.augmentColor({
        color: {
          main: colors.white50,
        },
      }),
      white20: palette.augmentColor({
        color: {
          main: colors.white20,
        },
      }),
      white10: palette.augmentColor({
        color: {
          main: colors.white10,
        },
      }),
      white8: palette.augmentColor({
        color: {
          main: colors.white8,
        },
      }),
      white4: palette.augmentColor({
        color: {
          main: colors.white4,
        },
      }),
      inkBlack: palette.augmentColor({
        color: {
          main: colors.inkBlack,
        },
      }),
      blue: palette.augmentColor({
        color: {
          main: colors.blue,
        },
      }),
      oliveGreen10: palette.augmentColor({
        color: {
          main: colors.oliveGreen10,
        },
      }),
      mediumSeaGreen: palette.augmentColor({
        color: {
          main: colors.mediumSeaGreen,
        },
      }),
      seaGreen: palette.augmentColor({
        color: {
          main: colors.seaGreen,
        },
      }),
      lightCoral: palette.augmentColor({
        color: {
          main: colors.lightCoral,
        },
      }),
      indianRed: palette.augmentColor({
        color: {
          main: colors.indianRed,
        },
      }),
      lightCoral10: palette.augmentColor({
        color: {
          main: colors.lightCoral10,
        },
      }),
      whiteSmoke: palette.augmentColor({
        color: {
          main: colors.whiteSmoke,
        },
      }),
      // TODO: Remove unused colors
      tuna: palette.augmentColor({
        color: {
          main: colors.tuna,
        },
      }),
      cornflowerBlue: palette.augmentColor({
        color: {
          main: colors.cornflowerBlue,
        },
      }),
      frenchRose: palette.augmentColor({
        color: {
          main: colors.frenchRose,
        },
      }),
      geyser: palette.augmentColor({
        color: {
          main: colors.geyser,
        },
      }),
      frenchGray: palette.augmentColor({
        color: {
          main: colors.frenchGray,
        },
      }),
      santasGray: palette.augmentColor({
        color: {
          main: colors.santasGray,
        },
      }),
      catskillWhite: palette.augmentColor({
        color: {
          main: colors.catskillWhite,
        },
      }),
      ghostWhite: palette.augmentColor({
        color: {
          main: colors.ghostWhite,
        },
      }),
      scandal: palette.augmentColor({
        color: {
          main:         colors.scandal,
          contrastText: colors.jewel,
        },
      }),
      serenade: palette.augmentColor({
        color: {
          main:         colors.serenade,
          contrastText: colors.flushOrange,
        },
      }),
      remy: palette.augmentColor({
        color: {
          main:         colors.remy,
          contrastText: colors.cabaret,
        },
      }),
      titanWhite: palette.augmentColor({
        color: {
          main: colors.titanWhite,
        },
      }),
      whiteLilac: palette.augmentColor({
        color: {
          main: colors.whiteLilac,
        },
      }),
      mischka: palette.augmentColor({
        color: {
          main: colors.mischka,
        },
      }),
      jewel: palette.augmentColor({
        color: {
          main: colors.jewel,
        },
      }),
      maroonFlush: palette.augmentColor({
        color: {
          main: colors.maroonFlush,
        },
      }),
    },
    typography: {
      htmlFontSize: 16,
      fontFamily:   'Inter, sans-serif',
      h1:           {
        fontSize:   pxToRem(56),
        lineHeight: pxToRem(58),
        fontFamily: 'Montserrat, sans-serif',
      },
      h2: {
        fontSize:   pxToRem(32),
        lineHeight: 'normal',
      },
      h3: {
        fontSize:   pxToRem(24),
        lineHeight: pxToRem(32),
      },
      h4: {
        fontSize:   pxToRem(20),
        lineHeight: pxToRem(32),
      },
      h5: {
        fontSize:   pxToRem(18),
        lineHeight: pxToRem(24),
      },
      subtitle1: {
        fontSize:   pxToRem(20),
        lineHeight: pxToRem(24),
      },
      body1: {
        fontSize:   pxToRem(16),
        lineHeight: pxToRem(20),
      },
      body2: {
        fontSize:   pxToRem(14),
        lineHeight: pxToRem(17),
      },
      body3: {
        fontSize:   pxToRem(12),
        lineHeight: pxToRem(15),
      },
      caption1: {
        fontSize:   pxToRem(12),
        lineHeight: pxToRem(16),
      },
      caption2: {
        fontSize:   pxToRem(10),
        lineHeight: pxToRem(14),
      },
      overline: {
        fontSize:      pxToRem(12),
        lineHeight:    pxToRem(18),
        letterSpacing: '-0.03em',
      },
    },
    mixins:     {},
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        body {
          color: white;
        }
        * {
          font-family: Inter, sans-serif
        }
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('CeraPro-Regular'), url(${
            CeraProRegularWoff2 as string
          }) format('woff2');
        }
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('CeraPro-Medium'), url(${
            CeraProMediumWoff2 as string
          }) format('woff2');
        }
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('CeraPro-Bold'), url(${
            CeraProBoldWoff2 as string
          }) format('woff2');
        }
        [hidden] {
          display: none !important;
        }
        .reflex-splitter {
          display: none;
        }
        .reflex-handle.active > div {
          background-color: ${alpha(colors.geyser, 0.9)};
        }
        .reflex-container > .reflex-element {
          overflow: hidden;
        }
      `,
      },
      MuiTypography: {
        variants: [
          {
            props: { color: 'royalBlue' },
            style: {
              color: colors.royalBlue,
            },
          },
          {
            props: { color: 'frenchRose' },
            style: {
              color: colors.frenchRose,
            },
          },
          {
            props: { color: 'cornflowerBlue' },
            style: {
              color: colors.cornflowerBlue,
            },
          },
          {
            props: { color: 'neonCarrot' },
            style: {
              color: colors.neonCarrot,
            },
          },
          {
            props: { color: 'jewel' },
            style: {
              color: colors.jewel,
            },
          },
          {
            props: { color: 'cabaret' },
            style: {
              color: colors.cabaret,
            },
          },
          {
            props: { color: 'white' },
            style: {
              color: colors.white,
            },
          },
          {
            props: { color: 'white50' },
            style: {
              color: colors.white50,
            },
          },
          {
            props: { color: 'mediumSeaGreen' },
            style: {
              color: colors.mediumSeaGreen,
            },
          },
          {
            props: { color: 'tuna' },
            style: {
              color: colors.tuna,
            },
          },
          {
            props: { color: 'santasGray' },
            style: {
              color: colors.santasGray,
            },
          },
          {
            props: { color: 'frenchGray' },
            style: {
              color: colors.frenchGray,
            },
          },
          {
            props: { color: 'frenchGray' },
            style: {
              color: colors.frenchGray,
            },
          },
          {
            props: { color: 'white50' },
            style: {
              color: colors.white50,
            },
          },
          {
            props: { color: 'lightCoral' },
            style: {
              color: colors.lightCoral,
            },
          },
          {
            props: { color: 'blue' },
            style: {
              color: colors.blue,
            },
          },
        ],
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              borderRadius: pxToRem(10),
              fontWeight:   500,
              fontSize:     pxToRem(16),
              lineHeight:   pxToRem(20),
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              borderRadius: pxToRem(10),
              color:        colors.white,
              fontSize:     pxToRem(16),
              lineHeight:   pxToRem(20),
              fontWeight:   500,
              border:       `1px dashed ${colors.white10}`,

              ':hover': {
                border:     `1px dashed ${colors.white}`,
                background: 'transparent',
              },
              ':disabled': {
                color:      colors.white50,
                border:     `1px dashed ${colors.white10}`,
                background: 'transparent',
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'lightCoral' },
            style: {
              borderRadius: pxToRem(10),
              color:        colors.lightCoral,
              fontSize:     pxToRem(16),
              lineHeight:   pxToRem(20),
              fontWeight:   500,
              border:       `1px solid ${colors.indianRed}`,

              ':hover': {
                border:     `1px solid ${colors.indianRed}`,
                background: 'transparent',
              },
              ':disabled': {
                color:      colors.lightCoral10,
                border:     `1px dashed ${colors.lightCoral10}`,
                background: 'transparent',
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              borderRadius:    pxToRem(12),
              color:           colors.white,
              fontWeight:      700,
              fontSize:        pxToRem(16),
              lineHeight:      pxToRem(20),
              letterSpacing:   '-0.003em',
              backgroundColor: 'transparent',
              textTransform:   'none',
            },
          },
          {
            props: { color: 'primary', variant: 'contained' },
            style: {
              color:           colors.mirage,
              backgroundColor: colors.white,
              textTransform:   'none',

              ':hover': {
                backgroundColor: colors.whiteSmoke,
                color:           colors.mirage,
              },
              ':focus': {
                backgroundColor: colors.white,
                color:           colors.mirage,
              },
              ':disabled': {
                backgroundColor: colors.white50,
                color:           colors.mirage,
              },
            },
          },
          {
            props: { color: 'lightCoral', variant: 'text' },
            style: {
              color:         colors.lightCoral,
              textTransform: 'none',
            },
          },
          {
            props: { color: 'lightCoral', variant: 'contained' },
            style: {
              color:           colors.white,
              backgroundColor: colors.lightCoral,
              textTransform:   'none',

              ':hover': {
                backgroundColor: colors.indianRed,
                color:           colors.white,
              },
              ':focus': {
                backgroundColor: colors.lightCoral,
                color:           colors.white,
              },
              ':disabled': {
                backgroundColor: colors.lightCoral10,
                color:           colors.white50,
              },
            },
          },
          {
            props: { color: 'mediumSeaGreen', variant: 'contained' },
            style: {
              color:           colors.white,
              backgroundColor: colors.mediumSeaGreen,
              textTransform:   'none',

              ':hover': {
                backgroundColor: colors.seaGreen,
                color:           colors.white,
              },
              ':focus': {
                backgroundColor: colors.mediumSeaGreen,
                color:           colors.white,
              },
              ':disabled': {
                backgroundColor: colors.oliveGreen10,
                color:           colors.white50,
              },
            },
          },
          {
            props: { color: 'mediumSeaGreen', variant: 'outlined' },
            style: {
              textTransform: 'none',
              border:        `1px solid ${colors.mediumSeaGreen}`,
              color:         colors.mediumSeaGreen,

              ':hover': {
                backgroundColor: colors.seaGreen,
                color:           colors.white,
              },
              ':focus': {
                color: colors.white,
              },
              ':disabled': {
                backgroundColor: colors.oliveGreen10,
                color:           colors.white50,
              },
            },
          },
          {
            props: { color: 'white8', variant: 'contained' },
            style: {
              color:           colors.white,
              backgroundColor: colors.white8,
              textTransform:   'none',

              ':hover': {
                opacity: '80%',
              },
              ':focus': {
                opacity: '90%',
              },
              ':disabled': {
                color:           colors.white,
                backgroundColor: colors.white8,
                opacity:         '50%',
              },
            },
          },
          {
            props: { color: 'secondary', variant: 'contained' },
            style: {
              backgroundColor: colors.titanWhite,
              color:           colors.cornflowerBlue,
              textTransform:   'none',
              ':hover':        {
                backgroundColor: colors.titanWhite,
                color:           colors.royalBlue,
              },
              ':focus': {
                backgroundColor: alpha(colors.cornflowerBlue, 0.3),
                color:           colors.royalBlue,
              },
              ':disabled': {
                color:           colors.frenchGray,
                backgroundColor: colors.ghostWhite,
              },
            },
          },
          {
            props: { color: 'white', variant: 'contained' },
            style: {
              backgroundColor: colors.white,
              textTransform:   'none',
              boxShadow:       `0px 4px 8px rgba(91, 120, 251, 0.2), 0px 0px 4px rgba(91, 120, 251, 0.1)`,
              ':hover':        {
                backgroundColor: colors.white,
                boxShadow:       `0px 4px 8px rgba(91, 120, 251, 0.2), 0px 0px 4px rgba(91, 120, 251, 0.1)`,
              },
              ':focus': {
                backgroundColor: colors.white,
              },
            },
          },
          {
            props: { size: 'large' },
            style: {
              padding:      spacing(1.75, 2.5),
              borderRadius: pxToRem(12),
            },
          },
          {
            props: { size: 'medium' },
            style: {
              padding:      spacing(1.5, 2),
              borderRadius: pxToRem(10),
            },
          },
          {
            props: { size: 'small' },
            style: {
              padding:      spacing(1, 2),
              borderRadius: pxToRem(8),
            },
          },
        ],
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color:          colors.cornflowerBlue,
            textDecoration: 'none',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '&&': {
              height:                       pxToRem(48),
              minHeight:                    pxToRem(48),
              padding:                      spacing(0, 2.5),
              [breakpoints.down('tablet')]: {
                height:    pxToRem(40),
                minHeight: pxToRem(40),
                padding:   spacing(0, 2),
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            'path.stroke': {
              stroke: colors.santasGray,
            },
            'rect.stroke': {
              stroke: colors.santasGray,
            },
            'line.stroke': {
              stroke: colors.santasGray,
            },
            'path.fill': {
              fill: colors.santasGray,
            },
            'rect.fill': {
              fill: colors.santasGray,
            },
            'line.fill': {
              fill: colors.santasGray,
            },
            ':disabled': {
              svg: {
                'path.stroke': {
                  stroke: colors.catskillWhite,
                },
                'rect.stroke': {
                  stroke: colors.catskillWhite,
                },
                'line.stroke': {
                  stroke: colors.catskillWhite,
                },
                'path.fill': {
                  fill: colors.catskillWhite,
                },
                'rect.fill': {
                  fill: colors.catskillWhite,
                },
                'line.fill': {
                  fill: colors.catskillWhite,
                },
              },
            },
          },
        },
        variants: [
          {
            props: { color: 'titanWhite' },
            style: {
              backgroundColor: colors.titanWhite,
              borderRadius:    pxToRem(10),
              ':hover':        {
                backgroundColor: colors.titanWhite,
              },
            },
          },
          {
            props: { color: 'whiteLilac' },
            style: {
              backgroundColor: colors.whiteLilac,
              borderRadius:    pxToRem(10),
              ':hover':        {
                backgroundColor: colors.whiteLilac,
              },
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          root: {
            minWidth:            pxToRem(116),
            display:             'flex',
            alignItems:          'center',
            height:              pxToRem(24),
            borderRadius:        pxToRem(8),
            '& .chip-indicator': {
              width:  pxToRem(6),
              height: pxToRem(6),
            },
          },
        },
        variants: [
          {
            props: { color: 'scandal' },
            style: {
              '& .chip-indicator': {
                backgroundColor: colors.jewel,
                borderRadius:    pxToRem(2),
              },
            },
          },
          {
            props: { color: 'serenade' },
            style: {
              '& .chip-indicator': {
                backgroundColor: colors.flushOrange,
                borderRadius:    pxToRem(2),
              },
            },
          },
          {
            props: { color: 'remy' },
            style: {
              '& .chip-indicator': {
                backgroundColor: colors.cabaret,
                borderRadius:    pxToRem(2),
              },
            },
          },
        ],
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: colors.white8,
          },
        },
        variants: [
          {
            props: { variant: 'rounded' },
            style: {
              borderRadius: pxToRem(10),
            },
          },
        ],
      },
    },
  },
  {
    colors,
  },
);

export { defaultTheme, pxToRem };
