import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import { PopupsService } from '@services';

import { Popups, Routes } from '@enums';

import { Button, Popup, PopupProps } from '@elements';

/**
 * SavedSeedComponent component.
 *
 * @author Ihar Kazlouski
 * @function SavedSeedComponent
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const SavedSeedComponent: FC = ({ popUp }: PopupProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const header = popUp && popUp.data && popUp.data.header;
  const body = popUp && popUp.data && popUp.data.body;

  const handleSubmit = (): void => {
    void PopupsService.close(Popups.SavedSeed);
    navigate(Routes.Pin, { state: { mnemonic: body } });
  };

  return (
    <Popup width={445} fullWidth popUp={popUp}>
      <Popup.Title>
        <Typography color='white' variant='body1'>
          {header}
        </Typography>
      </Popup.Title>
      <Popup.Content>
        <Stack gap={2.5}>
          <Typography variant='body1' color='white50'>
            {t('components.popups.savedSeed.body')}
          </Typography>
          <Box display='flex' gap={2.5} width='100%'>
            <Button
              variant='outlined'
              fullWidth
              onClick={(): void => void PopupsService.close(Popups.SavedSeed)}
            >
              {t('components.popups.savedSeed.back')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleSubmit}
            >
              {t('components.popups.savedSeed.saved')}
            </Button>
          </Box>
        </Stack>
      </Popup.Content>
    </Popup>
  );
};

export { SavedSeedComponent };
