import React, { FC } from 'react';

import { Grid, IconButton } from '@mui/material';

import { Popups } from '@enums';

import { Icon } from '@elements';

import close from '@images/close.svg';

import { StyledPopupTitleTypography } from './popup-title.styled';
import { PopupTitleProps } from './popup-title.types';

/**
 * PopupTitleSlot component.
 *
 * @author Ihar Kazlouski
 * @function PopupTitle
 * @category components
 * @param {PopupFooterProps} content props.
 * @return {JSX.Element} PopupTitleSlot component.
 */
const PopupTitle: FC<PopupTitleProps> = ({
  children,
  handleClose,
  rejectPopup,
  popupId,
}): JSX.Element => {
  const isProfile = popupId === Popups.Profile;
  const titleContent =
    typeof children === 'string' ? (
      <StyledPopupTitleTypography variant='body1' color='white'>
        {children}
      </StyledPopupTitleTypography>
    ) : (
      children
    );

  return (
    <Grid
      container
      flexWrap='nowrap'
      alignItems='center'
      justifyContent='space-between'
      columnSpacing={1}
      position='relative'
    >
      <Grid
        item
        width='100%'
        alignItems='center'
        display={typeof children === 'string' ? 'unset' : 'flex'}
      >
        {titleContent}
      </Grid>
      {!isProfile && (
        <Grid
          item
          position='absolute'
          right={isProfile ? '24px' : '3%'}
          top={isProfile ? '-5px' : '-24%'}
        >
          <IconButton onClick={rejectPopup} color='white' size='small'>
            <Icon src={close} width={20} height={20} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

PopupTitle.displayName = 'PopupTitle';

export { PopupTitle };
