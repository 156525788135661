import { produce } from 'immer';

import { Networks } from '@enums';

import {
  FETCH_NETWORKS_FAILURE,
  FETCH_NETWORKS_REQUEST,
  FETCH_NETWORKS_SUCCESS,
  SET_CURRENT_NETWORK,
} from './network.action.types';
import { ActionsTypes, NetworksState } from './network.types';

const initialState: NetworksState = {
  count:          null,
  pending:        false,
  currentNetwork: null,
  networks:       null,
  error:          null,
};

/**
 * Networks reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Network
 * @param {NetworksState} state state.
 * @param {ActionsTypes} action action.
 * @return {NetworksState} network state.
 */
export default (state = initialState, action: ActionsTypes): NetworksState => {
  switch (action.type) {
    case FETCH_NETWORKS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case FETCH_NETWORKS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.networks = action.payload.networks.results;
        draft.currentNetwork =
          action.payload.networks.results.find(
            (network) => network.name === Networks.Ethereum,
          ) || null;
        draft.count = action.payload.networks.count;
        draft.error = null;
      });
    }

    case FETCH_NETWORKS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.networks = null;
        draft.currentNetwork = null;
        draft.count = null;
        draft.error = action.payload.error;
      });
    }

    case SET_CURRENT_NETWORK: {
      return produce(state, (draft) => {
        draft.currentNetwork =
          state.networks?.find(
            (network) => network.id === action.payload.networkId,
          ) || null;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
