export enum ApiResponse {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  ServerError = 500,
  BadGateway = 502,
}

export enum ApiResponseStatusText {
  'invalid_request' = 'invalid_request',
  'invalid_token' = 'invalid_token',
  'insufficient_scope' = 'insufficient_scope',
  'server_error' = 'server_error',
  'invalid_grant' = 'invalid_grant',
  'unauthorized' = 'unauthorized',
}
