import { createSelector } from 'reselect';

import { ModalsState, Selector } from '@store/modals/modals.types';
import { AppState } from '@store/root.reducer';

/**
 * Get modals array.
 *
 * @author Ihar Kazlouski
 * @function getModals
 * @category Selectors
 * @subcategory Modals
 * @param {AppState} state state.
 * @return {ModalsState[]} modals state.
 */
const getModals = (state: AppState): ModalsState[] => {
  return state.modals;
};

export const getModalsSelector = createSelector(getModals, (modals) => modals);

export const getModalSelector = (
  modalId: string,
): Selector<ModalsState | undefined> => {
  return createSelector(getModals, (modals) => {
    return modals.find((modal) => modal.modalId === modalId);
  });
};

export const getModalOpenStateSelector = (
  modalId: string,
): Selector<ModalsState['isOpen']> => {
  return createSelector([getModalSelector(modalId)], (modal) => {
    return modal?.isOpen || false;
  });
};

export const getModalDataSelector = (
  modalId: string,
): Selector<ModalsState['data']> => {
  return createSelector([getModalSelector(modalId)], (modal) => {
    return modal?.data || false;
  });
};
