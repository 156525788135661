import { Networks } from '@enums';

import ethIcon from '@assets/images/currency/eth.svg';
import solanaIcon from '@assets/images/currency/solana.svg';
import evmExplorerIcon from '@assets/images/socials/logo-evm-explorer.svg';
import solExplorerIcon from '@assets/images/socials/logo-sol-explorer.svg';

export const NetworksConfig = {
  [Networks.Ethereum]: {
    icon:        ethIcon,
    defaultPair: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',

    blockExplorer: {
      url:         'https://etherscan.io/',
      icon:        evmExplorerIcon,
      accountPath: 'address/',
      txPath:      'tx/',
    },
  },
  [Networks.Solana]: {
    icon:        solanaIcon,
    defaultPair: 'EGZ7tiLeH62TPV1gL8WwbXGzEPa9zmcpVnnkPKKnrE2U',

    blockExplorer: {
      url:         'https://solscan.io/',
      icon:        solExplorerIcon,
      accountPath: 'account/',
      txPath:      'tx/',
    },
  },
};

export const getNetworkInfo = (
  network: Networks,
): {
  icon: string;
  defaultPair: string;
  blockExplorer: {
    url: string;
    icon: string;
    accountPath: string;
    txPath: string;
  };
} => {
  return NetworksConfig[network];
};

export const getBlockExplorerTxUrl = (
  network: Networks,
  hash: string,
): string => {
  const { blockExplorer } = getNetworkInfo(network);

  return `${blockExplorer.url}${blockExplorer.txPath}${hash}`;
};

export const getBlockExplorerAccountUrl = (
  network: Networks,
  hash: string,
): string => {
  const { blockExplorer } = getNetworkInfo(network);

  return `${blockExplorer.url}${blockExplorer.accountPath}${hash}`;
};
