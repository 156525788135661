import { IconButton, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Icon } from '@elements/icon';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledAdornmentButton = styled(IconButton)<{
  theme: DefaultTheme;
  hidden: boolean;
}>(({ theme, hidden }) => {
  const styles: OverridesStyleRules = {
    marginRight: theme.spacing(1),
    display:     hidden && 'none',
  };

  return styles;
});

const StyledArrowIcon = styled(Icon)<{
  theme: DefaultTheme;
  open: boolean;
}>(({ open, theme }) => {
  const styles: OverridesStyleRules = {
    '& svg': {
      transition: theme.transitions.create(['transform']),
      transform:  `rotate(${open ? 180 : 0}deg)`,
    },
    '&& svg > path': {
      stroke: theme.colors.santasGray,
    },
  };

  return styles;
});

export { StyledAdornmentButton, StyledArrowIcon };
