import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { OrderStatusFilter, SortingOrderDirection } from '@enums';
import { FETCH_TERMINAL_DEFAULT_PAIR_FAILURE, FETCH_TERMINAL_ORDERS_FAILURE, FETCH_TERMINAL_ORDERS_REQUEST, FETCH_TERMINAL_ORDERS_SUCCESS, FETCH_TERMINAL_PAIRS_FAILURE, FETCH_TERMINAL_PAIRS_REQUEST, FETCH_TERMINAL_PAIRS_SUCCESS, RESET_TERMINAL_ORDERS, RESET_TERMINAL_PAIR, RESET_TERMINAL_PAIRS, SELECT_TERMINAL_PAIR } from './terminal.action-types';
var initialState = {
  pairs: {
    selected: null,
    list: null,
    pending: {
      getList: false
    },
    errors: {
      getList: null,
      getDefault: null
    }
  },
  orders: {
    list: {
      items: null,
      page: 1,
      perPage: 10,
      total: 0,
      statusList: [OrderStatusFilter.Active, OrderStatusFilter.Completed, OrderStatusFilter.Failed],
      sortingOrder: {
        direction: SortingOrderDirection.Descending,
        field: 'created_at'
      }
    },
    pending: {
      getList: false
    },
    errors: {
      getList: null
    }
  }
};

/**
 * Terminal reducer.
 *
 * @author Ilya Khachikyan
 * @category Reducers
 * @subcategory Terminal
 * @param {TerminalState} state state.
 * @param {ActionsTypes} action action.
 * @return {TerminalState} Terminal state.
 */
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_TERMINAL_PAIRS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pairs.pending.getList = true;
        });
      }
    case FETCH_TERMINAL_PAIRS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pairs.pending.getList = false;
          draft.pairs.list = action.payload.pairs.results;
          draft.pairs.errors.getList = null;
        });
      }
    case FETCH_TERMINAL_PAIRS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pairs.pending.getList = false;
          draft.pairs.list = null;
          draft.pairs.errors.getList = action.payload.error;
        });
      }
    case RESET_TERMINAL_PAIRS:
      {
        return produce(state, function (draft) {
          draft.pairs.list = null;
        });
      }
    case FETCH_TERMINAL_DEFAULT_PAIR_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pairs.selected = null;
          draft.pairs.errors.getDefault = action.payload.error;
        });
      }
    case SELECT_TERMINAL_PAIR:
      {
        return produce(state, function (draft) {
          draft.pairs.selected = action.payload.pair;
        });
      }
    case RESET_TERMINAL_PAIR:
      {
        return produce(state, function (draft) {
          draft.pairs.selected = null;
        });
      }
    case FETCH_TERMINAL_ORDERS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.orders.pending.getList = true;
          draft.orders.list.page = action.payload.page;
          draft.orders.list.perPage = action.payload.perPage;
          draft.orders.list.sortingOrder = action.payload.sortingOrder;
          draft.orders.list.statusList = action.payload.statusList;
        });
      }
    case FETCH_TERMINAL_ORDERS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.orders.pending.getList = false;
          draft.orders.list.items = action.payload.orders.results;
          draft.orders.list.total = action.payload.orders.count;
          draft.orders.errors.getList = null;
        });
      }
    case FETCH_TERMINAL_ORDERS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.orders.pending.getList = false;
          draft.orders.list.items = null;
          draft.orders.errors.getList = action.payload.error;
        });
      }
    case RESET_TERMINAL_ORDERS:
      {
        return produce(state, function (draft) {
          draft.orders.list.items = null;
        });
      }
    default:
      {
        return _objectSpread({}, state);
      }
  }
});