import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { configureStore } from '@reduxjs/toolkit';

import { RootState } from '@types';

import rootReducer from './root.reducer';
import { rootSaga } from './root.saga';

const waitForMiddleware = createReduxWaitForMiddleware();

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store
const store: Store<RootState> = configureStore({
  reducer:    rootReducer,
  middleware: [sagaMiddleware, waitForMiddleware],
});

// Run the saga
sagaMiddleware.run(rootSaga);

const { dispatch } = store;

export { dispatch };

export default store;
