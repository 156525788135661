import React from 'react';

import {
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from '@mui/material';

import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
} from './table.styles';

export const Table: React.FC<TableProps> = StyledTable;
export const TableHead: React.FC<TableHeadProps> = StyledTableHead;
export const TableBody: React.FC<TableBodyProps> = StyledTableBody;
export const TableRow: React.FC<TableRowProps> = StyledTableRow;
export const TableCell: React.FC<TableCellProps> = StyledTableCell;
