import {
  Box,
  FormControl,
  FormHelperText,
  formHelperTextClasses,
  inputBaseClasses,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Select,
  selectClasses,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { SelectFieldProps } from './select-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: SelectFieldProps['fullWidth'];
}>(({ theme, fullWidth, disabled }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',

    ...(disabled
      ? {
          opacity:       '0.5',
          pointerEvents: 'none',

          [`${StyledInputLabel}`]: {
            color: theme.palette.white50.main,
          },
        }
      : {}),
  };

  return styles;
});

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      fontSize:      theme.typography.pxToRem(14),
      lineHeight:    theme.typography.pxToRem(20),
      fontWeight:    500,
      color:         theme.palette.white50.main,
      transform:     'translate(12px, 16px) scale(1)',
      pointerEvents: 'none',

      [`&.${inputLabelClasses.shrink}`]: {
        transform: 'translate(12px, 7px) scale(0.85)',
      },

      [`&.${inputLabelClasses.error}`]: {
        color: theme.palette.white50.main,
      },

      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.white.main,
      },
    };

    return styles;
  },
);

const StyledSelect = styled(Select)(({ theme }) => {
  return {
    background: theme.palette.white4.main,
    border:     `${theme.typography.pxToRem(1)} solid ${
      theme.palette.white10.main
    }`,
    borderRadius: theme.typography.pxToRem(10),
    overflow:     'hidden',
    transition:   theme.transitions.create(['border-color']),

    ['&:hover']: {
      background: theme.palette.white4.main,
      border:     `${theme.typography.pxToRem(1)} solid ${
        theme.palette.white.main
      }`,
    },

    [`&.${inputBaseClasses.focused}`]: {
      background: theme.palette.white4.main,
      border:     `${theme.typography.pxToRem(1)} solid ${
        theme.palette.white.main
      }`,

      [`.${selectClasses.icon}`]: {
        color: theme.palette.white.main,
      },
    },

    [`&.${selectClasses.disabled}`]: {
      WebkitTextFillColor: theme.palette.white.main,
      pointerEvents:       'none',
    },

    [`& .${inputBaseClasses.input}`]: {
      boxSizing:  'border-box',
      height:     theme.typography.pxToRem(50),
      fontSize:   theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 500,
      color:      theme.palette.white.main,
      padding:    theme.spacing(3, 1.5, 0.5),
      position:   'relative',

      ['&::-webkit-outer-spin-button, &::-webkit-inner-spin-button']: {
        WebkitAppearance: 'none',
        margin:           theme.spacing(0),
      },
    },

    [`&.${selectClasses.error}`]: {
      border: `${theme.typography.pxToRem(1)} solid ${
        theme.palette.lightCoral.main
      }`,
    },

    [`& .${selectClasses.icon}`]: {
      right: theme.spacing(1.5),
      top:   pxToRem(16),
      color: theme.palette.white50.main,
    },
  };
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  return {
    borderRadius: theme.spacing(1),
    padding:      theme.spacing(1.5),

    '&:hover': {
      background: theme.palette.white8.main,
    },
  };
});

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginTop:  theme.typography.pxToRem(4),
    fontSize:   theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex:     1,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.lightCoral.main,
    display:  'block',
    padding:  theme.spacing(0, 1),
    margin:   'unset',
    maxWidth: '100%',

    [`&.${formHelperTextClasses.error}`]: {
      color: theme.palette.lightCoral.main,
    },
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.secondary.main,
    display:  'block',
    padding:  theme.spacing(0, 2),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

export {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyleFormHelperWrapper,
};
