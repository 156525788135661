import { Routes } from '@enums';

import eduCricleFilledIcon from '@images/edu-circle-filled.svg';
import notificationOnIcon from '@images/notifications.svg';
import questionCircleIcon from '@images/question-circle-icon.svg';
import smartphoneIcon from '@images/smartphone.svg';
import trophyIcon from '@images/trophy.svg';

export const navLinks = [
  {
    path:         Routes.NewPairs,
    i18nLabelKey: 'common.navigation.newPairs',
  },
  {
    path:         Routes.Trends,
    i18nLabelKey: 'common.navigation.trends',
    disabled:     true,
  },
  {
    path:         Routes.Terminal,
    i18nLabelKey: 'common.navigation.terminal',
  },
  {
    path:         Routes.Strategies,
    i18nLabelKey: 'common.navigation.strategies',
    disabled:     true,
  },
  {
    path:         Routes.Sniping,
    i18nLabelKey: 'common.navigation.sniping',
    disabled:     true,
  },
  {
    path:         Routes.CopyTrading,
    i18nLabelKey: 'common.navigation.copyTrading',
    disabled:     true,
  },
  {
    path:         Routes.Wallet,
    i18nLabelKey: 'common.navigation.wallet',
  },
  {
    path:         Routes.Futures,
    disabled:     true,
    i18nLabelKey: 'common.navigation.futures',
  },
];

export const iconsToolbarLinks = [
  { icon: notificationOnIcon },
  { icon: questionCircleIcon },
  { icon: eduCricleFilledIcon },
  { icon: smartphoneIcon },
  { icon: trophyIcon },
];
