import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
} from './auth.action-types';
import {
  FetchUserFailurePayload,
  FetchUserSuccessPayload,
  LoginUserFailurePayload,
  LoginUserSuccessPayload,
  LogoutUserFailurePayload,
} from './auth.types';

export const actions = {
  /**
   * Fetch user request action.
   *
   * @author Ihar Kazlouski
   * @function fetchUserRequest
   * @category Actions
   * @subcategory Auth
   * @param {string} token JWT token.
   * @return {fetchUserRequest} fetch user request.
   */
  fetchUserRequest: (token: string) =>
    ({
      type:              FETCH_USER_REQUEST,
      payload:           { token },
      [WAIT_FOR_ACTION]: `${FETCH_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${FETCH_USER_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Fetch user request success action.
   *
   * @author Ihar Kazlouski
   * @function fetchUserSuccess
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserSuccessPayload} payload payload.
   * @return {fetchUserSuccess} fetch user success.
   */
  fetchUserSuccess: (payload: FetchUserSuccessPayload) =>
    ({
      type: FETCH_USER_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch user request failure action.
   *
   * @author Ihar Kazlouski
   * @function fetchUserFailure
   * @category Actions
   * @subcategory Auth
   * @param {FetchUserFailurePayload} payload payload.
   * @return {fetchUserFailure} fetch user request failure.
   */
  fetchUserFailure: (payload: FetchUserFailurePayload) =>
    ({
      type: FETCH_USER_FAILURE,
      payload,
    } as const),
  /**
   * Login user request action.
   *
   * @author Ihar Kazlouski
   * @function loginUserRequest
   * @category Actions
   * @subcategory Auth
   * @param {LoginFormValues} values login form values.
   * @return {loginUserRequest} login user request.
   */
  loginUserRequest: (values: Record<string, unknown>) =>
    ({
      type:    LOGIN_USER_REQUEST,
      payload: { values },
    } as const),

  /**
   * Login user request success action.
   *
   * @author Ihar Kazlouski
   * @function loginUserSuccess
   * @category Actions
   * @subcategory Auth
   * @param {LoginUserSuccessPayload} payload payload.
   * @return {loginUserSuccess} login user success.
   */
  loginUserSuccess: (payload: LoginUserSuccessPayload) =>
    ({
      type: LOGIN_USER_SUCCESS,
      payload,
    } as const),

  /**
   * Login user request failure action.
   *
   * @author Ihar Kazlouski
   * @function loginUserFailure
   * @category Actions
   * @subcategory Auth
   * @param {LoginUserFailurePayload} payload payload.
   * @return {loginUserFailure} login user request failure.
   */
  loginUserFailure: (payload: LoginUserFailurePayload) =>
    ({
      type: LOGIN_USER_FAILURE,
      payload,
    } as const),
  /**
   * Logout user request action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserRequest
   * @category Actions
   * @subcategory Auth
   * @return {logoutUserRequest} logout user request.
   */
  logoutUserRequest: () =>
    ({
      type: LOGOUT_USER_REQUEST,
    } as const),

  /**
   * Logout user request success action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserSuccess
   * @category Actions
   * @subcategory Auth
   * @return {logoutUserSuccess} logout user success.
   */
  logoutUserSuccess: () =>
    ({
      type: LOGOUT_USER_SUCCESS,
    } as const),

  /**
   * Logout user request failure action.
   *
   * @author Ihar Kazlouski
   * @function logoutUserFailure
   * @category Actions
   * @subcategory Auth
   * @param {LogoutUserFailurePayload} payload payload.
   * @return {logoutUserFailure} login user request failure.
   */
  logoutUserFailure: (payload: LogoutUserFailurePayload) =>
    ({
      type: LOGOUT_USER_FAILURE,
      payload,
    } as const),
};
