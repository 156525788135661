import React from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton, Stack, Typography } from '@mui/material';

import { isDefined } from '@helpers';

import { OrderCard } from '@components/order-card';
import { Icon } from '@elements';

import { StyledContainer } from './order-cards-list.styles';

import alertIcon from '@images/alert-circle.svg';

import { OrderCardsListProps } from './order-cards-list.types';

export const OrderCardsListComponent: React.FC<OrderCardsListProps> = ({
  className,
  network,
  orders,
  isLoading,
  perPage,
}) => {
  const { t } = useTranslation();

  return (
    <StyledContainer className={className}>
      {isLoading &&
        Array(perPage)
          .fill(null)
          .map((_, index) => (
            <Skeleton variant='rounded' height={195} width='100%' key={index} />
          ))}
      {!isLoading && isDefined(orders) && orders.length === 0 && (
        <Stack padding={10} gap={2} alignItems='center'>
          <Icon src={alertIcon} width={84} height={84} color='white' />
          <Typography variant='body2' color='white' fontWeight={500}>
            {t('tables.orders.noData')}
          </Typography>
        </Stack>
      )}
      {!isLoading &&
        isDefined(orders) &&
        orders.length > 0 &&
        isDefined(network) &&
        orders?.map((order) => (
          <OrderCard order={order} network={network} key={order.id} />
        ))}
    </StyledContainer>
  );
};
