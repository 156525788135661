import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { CREATE_WALLET_FAILURE, CREATE_WALLET_REQUEST, CREATE_WALLET_SUCCESS, DELETE_WALLET_FAILURE, DELETE_WALLET_REQUEST, DELETE_WALLET_SUCCESS, FETCH_WALLETS_FAILURE, FETCH_WALLETS_REQUEST, FETCH_WALLETS_SUCCESS, UPDATE_WALLET_FAILURE, UPDATE_WALLET_REQUEST, UPDATE_WALLET_SUCCESS } from './wallet.action.types';
var initialState = {
  count: null,
  pending: false,
  wallets: [],
  error: null
};

/**
 * Wallet reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Wallet
 * @param {WalletsState} state state.
 * @param {ActionsTypes} action action.
 * @return {WalletsState} wallet state.
 */
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_WALLETS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case FETCH_WALLETS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.wallets = action.payload.wallets.results;
          draft.count = action.payload.wallets.count;
          draft.error = null;
        });
      }
    case FETCH_WALLETS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.wallets = [];
          draft.count = null;
          draft.error = action.payload.error;
        });
      }
    case CREATE_WALLET_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case CREATE_WALLET_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.wallets.push({
            id: action.payload.wallet.id,
            name: action.payload.wallet.name,
            address: action.payload.wallet.address,
            balance: '0',
            balance_usd: '0',
            difference: '0'
          });
          draft.error = null;
        });
      }
    case CREATE_WALLET_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.error = action.payload.error;
        });
      }
    case DELETE_WALLET_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case DELETE_WALLET_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          var idx = draft.wallets.findIndex(function (wallet) {
            return wallet.id === action.payload.walletId;
          });
          if (idx !== -1) draft.wallets.splice(idx, 1);
          draft.error = null;
        });
      }
    case DELETE_WALLET_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.error = action.payload.error;
        });
      }
    case UPDATE_WALLET_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case UPDATE_WALLET_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          var idx = draft.wallets.findIndex(function (wallet) {
            return wallet.id === action.payload.walletId;
          });
          if (idx !== -1) draft.wallets[idx].name = action.payload.name;
          draft.error = null;
        });
      }
    case UPDATE_WALLET_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.error = action.payload.error;
        });
      }
    default:
      {
        return _objectSpread({}, state);
      }
  }
});