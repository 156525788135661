import { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { Grid } from '@mui/material';

import { TextMask } from '@elements/form/fields/text-mask';

import {
  StyledFilledInput,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyleFormHelperWrapper,
} from './text-field.styles';

import { TextFieldProps } from './text-field.types';

const MotionHelperWrapper = motion(StyleFormHelperWrapper);

/**
 * TextField component.
 *
 * @author Ihar Kazlouski
 * @function TextField
 * @category components
 * @return {JSX.Element} textfield component.
 */
const TextField: FC<TextFieldProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  maskProps,
  inputRef,
  focused,
  labelProps,
  fieldProps,
  containerProps,
  type,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-textfield`;
  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  const TextFieldMaskProps = maskProps
    ? {
        inputComponent: TextMask as never,
        inputProps:     maskProps,
      }
    : {};

  return (
    <StyledFormControl
      error={isError}
      fullWidth={fullWidth}
      focused={focused}
      variant='filled'
      disabled={restProps.disabled}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        <Grid item flex='1 1' {...fieldProps}>
          {label && (
            <StyledInputLabel htmlFor={literalProps.htmlFor}>
              {label}
            </StyledInputLabel>
          )}
          <StyledFilledInput
            inputRef={inputRef}
            error={isError}
            id={literalProps.id}
            aria-describedby={inputName}
            name={name}
            fullWidth={fullWidth}
            disableUnderline
            color={color}
            type={type}
            {...TextFieldMaskProps}
            {...restProps}
          />
          <AnimatePresence>
            {isError && (
              <MotionHelperWrapper
                variants={{
                  hide: { opacity: 0, height: '0rem', marginTop: '0rem' },
                  show: { opacity: 1, height: 'auto', marginTop: '0.25rem' },
                }}
                initial='hide'
                animate='show'
                exit='hide'
              >
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  {error}
                </StyledFormHelperErrorText>
              </MotionHelperWrapper>
            )}
          </AnimatePresence>
          {bottomLabel && !isError ? (
            <StyleFormHelperWrapper>
              <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
            </StyleFormHelperWrapper>
          ) : null}
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { TextField };
