import { alpha, AppBar, Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledAppBar = styled(AppBar)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    backgroundColor: theme.palette.white.main,
    boxShadow:       `0px ${pxToRem(2)} ${pxToRem(10)} ${alpha(
      theme.palette.santasGray.main,
      0.12,
    )}`,
  };

  return styles;
});

const UserInfo = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:      'flex',
    alignItems:   'center',
    height:       pxToRem(28),
    padding:      theme.spacing(0, 1.5),
    background:   theme.palette.ghostWhite.main,
    border:       `${pxToRem(1)} solid ${theme.palette.catskillWhite.main}`,
    borderRadius: pxToRem(100),
  };

  return styles;
});

export { StyledAppBar, UserInfo };
