import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Stack, Typography } from '@mui/material';

import { formatWithSubscript } from '@utils';
import {
  formatDateToDatetimeStr,
  getBlockExplorerAccountUrl,
  getBlockExplorerTxUrl,
  getCompactAmount,
  getNetworkInfo,
  getShortBlockchainHash,
  getTrasactionTypeInfo,
} from '@helpers';

import { TransactionDirection } from '@enums';

import { Icon } from '@elements';

import {
  StyledContainer,
  StyledDataCell,
  StyledTxLink,
} from './swap-transaction-card.styles';

import { SwapTransactionCardProps } from './swap-transaction-card.types';

export const SwapTransactionCardComponent: React.FC<
SwapTransactionCardProps
> = ({ transaction, network }) => {
  const { t, i18n } = useTranslation();

  const transactionTypeInfo = getTrasactionTypeInfo(transaction.type);

  return (
    <StyledContainer spacing={1.5}>
      <Stack direction='row' justifyContent='space-between' paddingX={1.5}>
        <Stack>
          <Typography
            variant='body2'
            fontWeight={500}
            color={
              transactionTypeInfo.direction === TransactionDirection.In
                ? 'mediumSeaGreen'
                : 'lightCoral'
            }
          >
            {t(transactionTypeInfo.i18nLabelKey)}
          </Typography>

          <Link
            href={getBlockExplorerAccountUrl(
              network.name,
              transaction.from_address,
            )}
            target='_blank'
            rel='noreferrer noopener'
          >
            <Typography color='blue' variant='caption2' fontWeight={500}>
              {getShortBlockchainHash(transaction.from_address)}
            </Typography>
          </Link>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={1.5}>
          <Typography variant='body3' color='white50' fontWeight={500}>
            {formatDateToDatetimeStr(i18n.language, transaction.created_at)}
          </Typography>

          <StyledTxLink
            href={getBlockExplorerTxUrl(
              network.name,
              transaction.transaction_hash,
            )}
            target='_blank'
            rel='noreferrer noopener'
          >
            <Icon
              src={getNetworkInfo(network.name).blockExplorer.icon}
              width={20}
              height={20}
            />
          </StyledTxLink>
        </Stack>
      </Stack>

      <Stack spacing={0.5}>
        <Stack direction='row' spacing={0.5}>
          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.price')} $
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              ${formatWithSubscript(+transaction.price_usd)}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.total')} $
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              $
              {formatWithSubscript(
                parseFloat(transaction.price_usd) *
                  parseFloat(transaction.amount_out),
              )}
            </Typography>
          </StyledDataCell>
        </Stack>

        <Stack direction='row' spacing={0.5}>
          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.price')}{' '}
              {network.native_symbol}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {formatWithSubscript(+transaction.price)}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.amount')}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {getCompactAmount(i18n.language, transaction.amount_out)}
            </Typography>
          </StyledDataCell>

          <StyledDataCell spacing={0.5}>
            <Typography variant='body3' fontWeight={500} color='white50'>
              {t('tables.swapTransactions.columns.total')}{' '}
              {network.native_symbol}
            </Typography>
            <Typography variant='body2' fontWeight={500} color='white'>
              {formatWithSubscript(
                parseFloat(transaction.amount_out) *
                  parseFloat(transaction.price),
              )}
            </Typography>
          </StyledDataCell>
        </Stack>
      </Stack>
    </StyledContainer>
  );
};
