import { TFunction } from 'react-i18next';

import * as yup from 'yup';

export const getTradingMarketSellSchema = (t: TFunction): yup.BaseSchema =>
  yup.object().shape({
    amount: yup
      .number()
      .nullable()
      .required(t('forms.validation.required'))
      .positive(t('forms.validation.positive'))
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    anti_mev_enabled: yup.boolean(),
    anti_rug_enabled: yup.boolean(),
    min_gas_limit:    yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    max_gas_limit: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    extra_gas_size: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    slippage_percent: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
    bribe: yup
      .number()
      .nullable()
      .transform((value: string, originalValue) =>
        String(originalValue).trim() === '' ? null : value,
      ),
  });
