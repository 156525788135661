import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type MRT_ColumnDef as MRTColumnDef,
  MRT_SortingState as SortingState,
  MRT_Updater as Updater,
} from 'material-react-table';
import { unix } from 'moment/moment';

import { Box, IconButton, Link, Stack, Typography } from '@mui/material';

import { copyToClipboardUtil } from '@utils';
import {
  getBlockExplorerAccountUrl,
  getBlockExplorerTxUrl,
  getNetworkInfo,
  getOrderSideInfo,
  getOrderStatusInfo,
  getShortBlockchainHash,
  isDefined,
} from '@helpers';

import { OrderSide, OrderStatus, SortingOrderDirection } from '@enums';
import { Order } from '@types';

import { Icon, MaterialTable } from '@elements';

import alertIcon from '@images/alert-circle.svg';
import chevronRightIcon from '@images/chevron-right.svg';
import copyIcon from '@images/copy.svg';
import editIcon from '@images/edit.svg';
import deleteIcon from '@images/trash.svg';

import { OrdersTableProps } from './orders-table.types';

export const OrdersTable: FC<OrdersTableProps> = ({
  orders,
  network,
  sorting,
  onSortingChange,
}) => {
  const { t } = useTranslation();

  const columns = useMemo<MRTColumnDef<Order>[]>(
    () => [
      {
        accessorKey:   'created_at',
        id:            'created_at',
        enableSorting: true,
        header:        t('tables.orders.columns.date'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as number;

          return (
            <Stack>
              <Typography variant='body2' fontWeight='500' color='white'>
                {unix(value).format('MM.DD')}
              </Typography>
              <Typography variant='body2' fontWeight='500' color='white50'>
                {unix(value).format('hh:mm:ss')}
              </Typography>
            </Stack>
          );
        },
        size:           100,
        minSize:        100,
        maxSize:        100,
        enableResizing: false,
      },
      {
        accessorKey:   'token_info',
        id:            'token',
        enableSorting: false,
        header:        t('tables.orders.columns.token'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const { address, name } =
            renderedCellValue as unknown as Order['token_info'];

          return (
            <Stack gap={0.25}>
              <Box
                display='flex'
                alignItems='center'
                gap={0.5}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant='body2' fontWeight='500' color='white'>
                  {name}
                </Typography>
                <Icon
                  src={chevronRightIcon}
                  width={20}
                  height={20}
                  color='white'
                />
              </Box>

              <Stack
                direction='row'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
              >
                {isDefined(network) && (
                  <Typography
                    component={Link}
                    variant='body3'
                    color='blue'
                    fontWeight={500}
                    href={getBlockExplorerAccountUrl(network.name, address)}
                  >
                    {getShortBlockchainHash(address)}
                  </Typography>
                )}
                <IconButton color='blue'>
                  <Icon
                    src={copyIcon}
                    width={12}
                    height={12}
                    onClick={(): void => copyToClipboardUtil(address)}
                  />
                </IconButton>
              </Stack>
            </Stack>
          );
        },
        size:           140,
        minSize:        140,
        maxSize:        140,
        enableResizing: false,
      },
      {
        accessorKey:   'wallet.name',
        id:            'wallet',
        header:        t('tables.orders.columns.wallet'),
        enableSorting: true,
        Cell:          ({ renderedCellValue }): ReactNode => {
          return <Stack gap={1}>{renderedCellValue}</Stack>;
        },
        size:           120,
        minSize:        120,
        maxSize:        120,
        enableResizing: false,
      },
      {
        accessorKey:   'type',
        header:        t('tables.orders.columns.type'),
        enableSorting: false,
        Cell:          ({ renderedCellValue }): ReactNode => {
          return <Stack gap={1}>{renderedCellValue}</Stack>;
        },
        size:           100,
        minSize:        100,
        maxSize:        100,
        enableResizing: false,
      },
      {
        accessorKey:   'side',
        enableSorting: false,
        header:        t('tables.orders.columns.direction'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as OrderSide;
          const { i18nLabelKey } = getOrderSideInfo(value);

          return (
            <Typography
              color={value === OrderSide.Buy ? 'mediumSeaGreen' : 'lightcoral'}
              variant='body2'
              fontWeight={500}
            >
              {t(i18nLabelKey)}
            </Typography>
          );
        },
        size:           120,
        minSize:        120,
        maxSize:        120,
        enableResizing: false,
      },
      {
        accessorKey:   'amount',
        enableSorting: false,
        header:        t('tables.orders.columns.volume'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as string;

          return (
            <Typography variant='body2' whiteSpace='nowrap'>
              {value} {network?.native_symbol}
            </Typography>
          );
        },
        size:           140,
        minSize:        140,
        maxSize:        140,
        enableResizing: false,
      },
      {
        accessorKey:   'status',
        id:            'progress',
        enableSorting: false,
        header:        t('tables.orders.columns.progress'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as OrderStatus;

          return (
            <Stack gap={1}>
              {[
                OrderStatus.Cancelled,
                OrderStatus.Completed,
                OrderStatus.Failed,
              ].includes(value)
                ? 100
                : 0}
              %
            </Stack>
          );
        },
        size:           100,
        enableResizing: false,
      },
      {
        accessorKey:   'status',
        enableSorting: false,
        header:        t('tables.orders.columns.status'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as OrderStatus;
          const { color, i18nLabelKey } = getOrderStatusInfo(value);

          return (
            <Typography
              variant='body2'
              fontWeight={500}
              color={color}
              whiteSpace='nowrap'
            >
              {t(i18nLabelKey)}
            </Typography>
          );
        },
        size:           140,
        enableResizing: false,
      },
      {
        accessorKey:   'tool',
        id:            'tool',
        enableSorting: true,
        header:        t('tables.orders.columns.instrument'),
        Cell:          ({ renderedCellValue }): ReactNode => {
          const value = renderedCellValue as string;

          return value;
        },
        size:           140,
        enableResizing: false,
      },
    ],
    [t, network],
  );

  return (
    <MaterialTable<Order>
      columns={columns}
      data={orders ?? []}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          Header: t('tables.orders.columns.actions'),
          grow:   true,
          size:   150,
        },
      }}
      manualSorting
      state={{
        sorting: [
          {
            id:   sorting.field,
            desc: sorting.direction === SortingOrderDirection.Descending,
          },
        ],
      }}
      renderEmptyRowsFallback={(): React.ReactNode => (
        <Stack padding={10} gap={2} alignItems='center'>
          <Icon src={alertIcon} width={84} height={84} color='white' />
          <Typography variant='body2' color='white' fontWeight={500}>
            {t('tables.orders.noData')}
          </Typography>
        </Stack>
      )}
      enableRowSelection={false}
      enableSortingRemoval={false}
      onSortingChange={(updater: Updater<SortingState>): void => {
        if (typeof updater === 'function') {
          const [newSortingState] = updater([
            {
              id:   sorting.field,
              desc: sorting.direction === SortingOrderDirection.Descending,
            },
          ]);
          onSortingChange({
            field:     newSortingState.id,
            direction: newSortingState.desc
              ? SortingOrderDirection.Descending
              : SortingOrderDirection.Ascending,
          });
        }
      }}
      getRowId={(row): string => row.id}
      enableColumnResizing={false}
      renderRowActions={({ row }): ReactElement => {
        return (
          <Box display='flex' gap={2} alignItems='center'>
            {isDefined(network) && isDefined(row.original.transaction) && (
              <Link
                href={getBlockExplorerTxUrl(
                  network.name,
                  row.original.transaction.transaction_hash,
                )}
                target='_blank'
                rel='noreferrer noopener'
              >
                <IconButton color='white50' sx={{ flexShrink: 0 }}>
                  <Icon
                    src={getNetworkInfo(network.name).blockExplorer.icon}
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Link>
            )}
            <IconButton color='white50' sx={{ flexShrink: 0 }}>
              <Icon src={editIcon} width={18} height={18} />
            </IconButton>
            <IconButton color='white50' sx={{ flexShrink: 0 }}>
              <Icon src={deleteIcon} width={18} height={18} />
            </IconButton>
          </Box>
        );
      }}
    />
  );
};
