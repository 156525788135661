import { Box, BoxProps, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledAuthWrapper = styled(Box)<BoxProps>(() => {
  const styles: OverridesStyleRules = {
    maxWidth:      '100%',
    display:       'flex',
    flexDirection: 'column',
    height:        '100vh',
  };
  return styles;
});

const StyledMain = styled(Box)<BoxProps>(() => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    flexDirection: 'column',
    width:         '100%',
    height:        '100%',
  };

  return styles;
});

export { StyledAuthWrapper, StyledMain };
