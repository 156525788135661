import { useSelector } from 'react-redux';

import { getSnackbarsSelector } from '@store/snackbars/snackbars.selectors';

import { SnackbarComponent } from './snackbar.component';

/**
 * Snackbars component.
 *
 * @author Ihar Kazlouski
 * @function Snackbars
 * @category components
 * @return {JSX.Element} snackbars component.
 */
const Snackbars = (): JSX.Element => {
  const snackbars = useSelector(getSnackbarsSelector);

  return (
    <>
      {snackbars?.map((snackbar) => (
        <SnackbarComponent
          key={snackbar.snackbarId}
          snackbarId={snackbar.snackbarId}
        />
      ))}
    </>
  );
};

export { Snackbars };
