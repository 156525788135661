import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { StatisticService } from '@services';

import { ApiError, AxiosResponse, PayloadAction } from '@types';

import {
  FETCH_PAIRS_REQUEST,
  FETCH_TRANSACTIONS_REQUEST,
} from './statistic.action.types';
import { actions } from './statistic.actions';
import {
  FetchPairsRequestPayload,
  FetchTransactionsRequestPayload,
  PairResponse,
  TransactionsResponse,
} from './statistic.types';

/**
 * Get pairs saga worker.
 *
 * @author Ihar Kazlouski
 * @function getPairsSagaWorker
 * @category Sagas
 * @subcategory Statistic
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getPairsSagaWorker (
  action: PayloadAction<FetchPairsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<PairResponse>
  > {
  try {
    const { data } = yield call(StatisticService.apiPairsGet, {
      networkId: action.payload.networkId,
      limit:     action.payload.limit || 5,
      offset:    action.payload?.offset || undefined,
    });

    yield put(
      actions.fetchPairsSuccess({
        pairs: data,
      }),
    );
  } catch (error) {
    yield put(
      actions.fetchPairsFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * Get pairs saga.
 *
 * @author Ihar Kazlouski
 * @function getPairsSagaWatcher
 * @category Sagas
 * @subcategory Statistic
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getPairsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FETCH_PAIRS_REQUEST, getPairsSagaWorker)]);
}

/**
 * Get Transactions saga worker.
 *
 * @author Ihar Kazlouski
 * @function getTransactionsSagaWorker
 * @category Sagas
 * @subcategory Statistic
 * @return {Generator<PutEffect | CallEffect, void, ApiError & AxiosResponse<void>>} data.
 */
function* getTransactionsSagaWorker (
  action: PayloadAction<FetchTransactionsRequestPayload>,
): Generator<
  PutEffect | CallEffect,
  void,
  ApiError & AxiosResponse<TransactionsResponse>
  > {
  try {
    const { data } = yield call(StatisticService.apiTransactionsGet, {
      networkId: action.payload.networkId,
      limit:     5,
    });

    yield put(
      actions.fetchTransactionsSuccess({
        transactions: data,
      }),
    );
  } catch (error) {
    yield put(
      actions.fetchTransactionsFailure({
        error: error as ApiError,
      }),
    );
  }
}

/**
 * Get Transactions saga.
 *
 * @author Ihar Kazlouski
 * @function getTransactionsSagaWatcher
 * @category Sagas
 * @subcategory Statistic
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* getTransactionsSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(FETCH_TRANSACTIONS_REQUEST, getTransactionsSagaWorker),
  ]);
}

/**
 * Statistic saga.
 *
 * @author Ihar Kazlouski
 * @function statisticSaga
 * @category Sagas
 * @subcategory Statistic
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* statisticSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([fork(getPairsSagaWatcher), fork(getTransactionsSagaWatcher)]);
}
