import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import {
  getCurrentNetworkSelector,
  getNetworksSelector,
} from '@store/network/network.selectors';

import { NetworkService, TerminalService, WalletService } from '@services';

import { Networks } from '@enums';

import { Icon } from '@elements';

import {
  StyledButton,
  StyledChevronIcon,
  StyledMenu,
} from './network-menu.styles';

import chevronDownIcon from '@assets/images/chevron-down.svg';
import ethIcon from '@assets/images/currency/eth.svg';
import solanaIcon from '@assets/images/currency/solana.svg';

export const NetworkMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const networks = useSelector(getNetworksSelector);
  const currentNetwork = useSelector(getCurrentNetworkSelector);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback((networkId: string) => {
    setAnchorEl(null);
  }, []);

  const handleSelect = (networkId: string): void => {
    NetworkService.setCurrentNetwork(networkId);
    TerminalService.resetPair();
    WalletService.getWalletsList(networkId).catch((e) => {
      // ToDo: error handling
    });
    setAnchorEl(null);
  };

  const getIcon = (name?: string): string => {
    switch (name) {
      case Networks.Ethereum: {
        return ethIcon;
      }

      case Networks.Solana: {
        return solanaIcon;
      }

      default: {
        return ethIcon;
      }
    }
  };

  return (
    <div>
      <StyledButton variant='text' size='small' onClick={handleClick}>
        <Icon src={getIcon(currentNetwork?.name)} width={24} height={24} />
        <Typography variant='body2' fontWeight={500}>
          {currentNetwork?.native_symbol}
        </Typography>
        <StyledChevronIcon
          src={chevronDownIcon}
          open={open}
          width={20}
          height={20}
        />
      </StyledButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {networks?.map((network) => (
          <MenuItem
            onClick={(): void => handleSelect(network.id)}
            key={network.id}
            disabled={network.name === Networks.Solana}
          >
            <ListItemIcon>
              <Icon src={getIcon(network?.name)} width={24} height={24} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='body2' fontWeight={500}>
                {network.native_symbol}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
