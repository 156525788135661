import { produce } from 'immer';

import {
  CREATE_WALLET_FAILURE,
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_SUCCESS,
  DELETE_WALLET_FAILURE,
  DELETE_WALLET_REQUEST,
  DELETE_WALLET_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_WALLETS_REQUEST,
  FETCH_WALLETS_SUCCESS,
  UPDATE_WALLET_FAILURE,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_SUCCESS,
} from './wallet.action.types';
import { ActionsTypes, WalletsState } from './wallet.types';

const initialState: WalletsState = {
  count:   null,
  pending: false,
  wallets: [],
  error:   null,
};

/**
 * Wallet reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Wallet
 * @param {WalletsState} state state.
 * @param {ActionsTypes} action action.
 * @return {WalletsState} wallet state.
 */
export default (state = initialState, action: ActionsTypes): WalletsState => {
  switch (action.type) {
    case FETCH_WALLETS_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case FETCH_WALLETS_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.wallets = action.payload.wallets.results;
        draft.count = action.payload.wallets.count;
        draft.error = null;
      });
    }

    case FETCH_WALLETS_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.wallets = [];
        draft.count = null;
        draft.error = action.payload.error;
      });
    }

    case CREATE_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case CREATE_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.wallets.push({
          id:          action.payload.wallet.id,
          name:        action.payload.wallet.name,
          address:     action.payload.wallet.address,
          balance:     '0',
          balance_usd: '0',
          difference:  '0',
        });
        draft.error = null;
      });
    }

    case CREATE_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.error = action.payload.error;
      });
    }

    case DELETE_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case DELETE_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        const idx = draft.wallets.findIndex(
          (wallet) => wallet.id === action.payload.walletId,
        );

        if (idx !== -1) draft.wallets.splice(idx, 1);
        draft.error = null;
      });
    }

    case DELETE_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.error = action.payload.error;
      });
    }

    case UPDATE_WALLET_REQUEST: {
      return produce(state, (draft) => {
        draft.pending = true;
      });
    }

    case UPDATE_WALLET_SUCCESS: {
      return produce(state, (draft) => {
        draft.pending = false;
        const idx = draft.wallets.findIndex(
          (wallet) => wallet.id === action.payload.walletId,
        );

        if (idx !== -1) draft.wallets[idx].name = action.payload.name;
        draft.error = null;
      });
    }

    case UPDATE_WALLET_FAILURE: {
      return produce(state, (draft) => {
        draft.pending = false;
        draft.error = action.payload.error;
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
