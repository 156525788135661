import {
  TradingLimitBuyValues,
  TradingLimitSellValues,
  TradingMarketBuyValues,
  TradingMarketSellValues,
} from '@forms';

import { dispatch as storeDispatch } from '@store';
import {
  actions,
  FetchTerminalOrdersRequestPayload,
  FetchTerminalPairsRequestPayload,
  OrdersResponse,
  PairsResponse,
  TradeSettingsResponse,
} from '@store/terminal';

import { ApiService } from '@services';

import {
  OrderSide,
  OrderStatus,
  OrderType,
  SortingOrderDirection,
} from '@enums';
import { ApiError, AsyncDispatch, AxiosResponse, Pair } from '@types';

/**
 * Terminal controller.
 *
 * @author Ilya Khachikyan
 * @const NetworkServiceClass
 * @category Services
 * @subcategory Terminal
 */
class TerminalServiceClass {
  /**
   * Pairs Terminal get api function.
   *
   * @author Ilya Khachikyan
   * @return {Promise<ApiError | AxiosResponse<PairsResponse>>} promise.
   */
  async apiPairsGet ({
    networkId,
    limit = 5,
    offset,
    searchQuery,
  }: {
    networkId: string;
    limit?: number;
    offset?: number;
    searchQuery?: string;
  }): Promise<ApiError | AxiosResponse<PairsResponse>> {
    return ApiService.apiGet(`/public/networks/${networkId}/pairs`, {
      params: { limit, offset, search_query: searchQuery },
    });
  }

  /**
   * Orders Terminal get api function.
   *
   * @author Ilya Khachikyan
   * @return {Promise<ApiError | AxiosResponse<OrdersResponse>>} promise.
   */
  async apiOrdersGet ({
    networkId,
    pairId,
    statusList,
    limit = 5,
    offset,
    sortingOrder: sortingOrder,
  }: {
    networkId: string;
    statusList?: OrderStatus[];
    pairId?: string;
    limit?: number;
    offset?: number;
    sortingOrder?: {
      field: 'wallet' | 'tool' | 'created_at';
      direction: SortingOrderDirection;
    };
  }): Promise<ApiError | AxiosResponse<OrdersResponse>> {
    return ApiService.apiGet(`/private/networks/${networkId}/orders`, {
      params: {
        limit,
        offset,
        pair_id:         pairId,
        status_list:     statusList,
        order_field:     sortingOrder?.field,
        order_direction: sortingOrder?.direction,
      },
    });
  }

  /**
   * Creates terminal order.
   *
   * @author Ilya Khachikyan
   * @return {Promise<ApiError | AxiosResponse>} promise.
   */
  async apiOrderPost ({
    networkId,
    pairId,
    walletId,
    type,
    side,
    data,
  }: {
    networkId: string;
    pairId: string;
    walletId: string;
    type: OrderType;
    side: OrderSide;
    data:
    | TradingMarketBuyValues
    | TradingMarketSellValues
    | TradingLimitBuyValues
    | TradingLimitSellValues;
  }): Promise<ApiError | AxiosResponse> {
    return ApiService.apiPost(
      `/private/networks/${networkId}/pairs/${pairId}/orders`,
      {
        wallet_id: walletId,
        type,
        side,
        data,
      },
    );
  }

  /**
   * Creates terminal order.
   *
   * @author Ilya Khachikyan
   * @return {Promise<ApiError | AxiosResponse>} promise.
   */
  async apiTerminalSettingsPost ({
    networkId,
    data,
    // eslint-disable-next-line camelcase
    trade_type,
  }: {
    networkId: string;
    trade_type: string;
    data:
    | TradingMarketBuyValues
    | TradingMarketSellValues
    | TradingLimitBuyValues
    | TradingLimitSellValues;
  }): Promise<ApiError | AxiosResponse> {
    return ApiService.apiPost(`/private/networks/${networkId}/trade_settings`, {
      // eslint-disable-next-line camelcase
      type: trade_type,
      data,
    });
  }

  /**
   * Trade settings get api function.
   *
   * @author Ilya Khachikyan
   * @return {Promise<ApiError | AxiosResponse<PairsResponse>>} promise.
   */
  async apiTradeSettingsGet ({
    networkId,
  }: {
    networkId: string;
  }): Promise<ApiError | AxiosResponse<TradeSettingsResponse>> {
    return ApiService.apiGet(`/private/networks/${networkId}/trade_settings`);
  }

  /**
   * Get Pairs Terminal list.
   *
   * @author Ilya Khachikyan
   * @return {Promise<PairsResponse | ApiError>} Pairs response promise.
   */
  async getPairsList (
    payload: FetchTerminalPairsRequestPayload,
  ): Promise<PairsResponse | ApiError> {
    const dispatch: AsyncDispatch<PairsResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchPairsRequest(payload));
  }

  /**
   * Get terminal defualt pair.
   *
   * @author Ilya Khachikyan
   * @return {Promise<PairsResponse | ApiError>} Pairs response promise.
   */
  async getDefaultPair (
    payload: FetchTerminalPairsRequestPayload,
  ): Promise<PairsResponse | ApiError> {
    const dispatch: AsyncDispatch<PairsResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchDefaultPairRequest(payload));
  }

  /**
   * Reset Pairs Terminal list.
   *
   * @author Ilya Khachikyan
   * @return {void} void.
   */
  resetPairsList (): void {
    storeDispatch(actions.resetPairs());
  }

  /**
   * Set current pair.
   *
   * @author Ihar Kazlouski
   * @return {void} void.
   */
  selectPair (pair: Pair): void {
    storeDispatch(actions.selectPair({ pair }));
  }

  /**
   * Reset current pair.
   *
   * @author Ihar Kazlouski
   * @return {void} void.
   */
  resetPair (): void {
    storeDispatch(actions.resetPair());
  }

  /**
   * Get Orders Terminal list.
   *
   * @author Ilya Khachikyan
   * @return {Promise<OrdersResponse | ApiError>} Orders response promise.
   */
  async getOrdersList (
    payload: FetchTerminalOrdersRequestPayload,
  ): Promise<OrdersResponse | ApiError> {
    const dispatch: AsyncDispatch<OrdersResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchOrdersRequest(payload));
  }
}

const TerminalService = new TerminalServiceClass();

export { TerminalService };
