import { FC } from 'react';

import { Box, FormControl, Grid } from '@mui/material';

import {
  StyledFormHelperErrorText,
  StyledSwitch,
  StyledSwitchLabel,
  StyleFormHelperWrapper,
} from './switch-field.styles';

import { SwitchComponentProps } from './switch-field.types';

/**
 * Icon button component.
 *
 * @author Elena Shymanskaya
 * @function Switch
 * @category components
 * @return {JSX.Element} switch component.
 */

export const Switch: FC<SwitchComponentProps> = ({
  onChange,
  value,
  label,
  fullWidth,
  labelProps,
  fieldProps,
  containerProps,
  onFocus,
  name,
  checked,
  disabled,
  error,
}) => {
  const inputName = name && `${name}-switch`;

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <Box position='relative'>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          alignItems='center'
          {...containerProps}
        >
          {label && (
            <Grid item flex='1 1' {...labelProps}>
              <StyledSwitchLabel component='label' htmlFor={inputName}>
                {label}
              </StyledSwitchLabel>
            </Grid>
          )}
          <Grid item {...fieldProps}>
            <StyledSwitch
              onChange={onChange}
              value={Boolean(value)}
              id={inputName}
              checked={checked}
              onFocus={onFocus}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        {isError && (
          <StyleFormHelperWrapper>
            <StyledFormHelperErrorText id={literalProps.errorId}>
              {error}
            </StyledFormHelperErrorText>
          </StyleFormHelperWrapper>
        )}
      </Box>
    </FormControl>
  );
};
