import React, { FC } from 'react';

import { tradingSettingsHelpers } from '@popups/profile/profile.helpers';
import { ProfileHelperType } from '@popups/profile/profile.types';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';

import { Icon } from '@elements';

const TradingSettingsTab: FC<{
  handleClick: (tab: ProfileHelperType) => () => void;
}> = ({ handleClick }) => {
  return (
    <Stack pb={1}>
      <List>
        {tradingSettingsHelpers.map((item, index) => (
          <ListItemButton
            sx={{
              borderBottom: '1px solid #FFFFFF1A',
            }}
            key={item.id}
            onClick={handleClick(item)}
            disabled={item.disabled}
          >
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{
                fontSize:   '16px',
                fontWeight: '500',
                color:      '#FFF',
                lineHeight: '20px',
              }}
            />
            <ListItemIcon sx={{ minWidth: 'unset' }}>
              <Icon src={item.icon} width={18} height={18} />
            </ListItemIcon>
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
};

export { TradingSettingsTab };
