import React from 'react';

import { LazyPage } from '@hocs';

import { Routes } from '@enums';
import { AppRoutes } from '@types';

const PinPage = LazyPage('pin', 'PinPage');
const MnemonicPage = LazyPage('mnemonic', 'MnemonicPage');

const appAuthRoutes: AppRoutes[] = [
  {
    name:    Routes.Pin,
    path:    Routes.Pin,
    element: <PinPage />,
    index:   false,
  },
  {
    name:    Routes.Mnemonic,
    path:    Routes.Mnemonic,
    element: <MnemonicPage />,
    index:   false,
  },
];

export { appAuthRoutes };
