import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { Language } from '@enums';

import {
  StyledButton,
  StyledChevronIcon,
  StyledFlagIcon,
  StyledMenu,
} from './language-select.styles';

import chevronDownIcon from '@assets/images/chevron-down.svg';
import gbIcon from '@assets/images/flags/gb.svg';
import ruIcon from '@assets/images/flags/ru.svg';

export const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback((networkId: string) => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    async (language: string): Promise<void> => {
      setAnchorEl(null);
      await i18n.changeLanguage(language);
    },
    [i18n],
  );

  const getIcon = useCallback((language: string) => {
    switch (language) {
      case Language.En:
        return gbIcon;
      case Language.Ru:
      default:
        return ruIcon;
    }
  }, []);

  return (
    <div>
      <StyledButton variant='text' size='small' onClick={handleClick}>
        <StyledFlagIcon src={getIcon(i18n.language)} width={24} height={24} />
        <Typography variant='body2' fontWeight={500} textTransform='uppercase'>
          {i18n.language}
        </Typography>
        <StyledChevronIcon
          src={chevronDownIcon}
          open={open}
          width={20}
          height={20}
        />
      </StyledButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {['ru', 'en'].map((language) => (
          <MenuItem
            onClick={(): void => {
              void handleSelect(language);
            }}
            key={language}
          >
            <ListItemIcon>
              <StyledFlagIcon src={getIcon(language)} width={24} height={24} />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body2'
                fontWeight={500}
                textTransform='uppercase'
              >
                {language}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
