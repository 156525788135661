import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { ConstantsUtil } from '@utils';

import { PayloadAction } from '@types';

import {
  INIT_CLOSE_POPUP,
  INIT_POPUP_CLOSING_WITH_ANSWER,
  POPUP_INIT,
} from './popups.action-types';
import { actions } from './popups.actions';
import {
  PopupClosePayload,
  PopupCloseWithAnswerPayload,
  PopupPayload,
} from './popups.types';

/**
 * Open popup saga worker.
 *
 * @author Ihar Kazlouski
 * @function popupOpenSagaWorker
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<PutEffect>} action data.
 */
function* popupOpenSagaWorker (
  action: PayloadAction<PopupPayload>,
): Generator<PutEffect> {
  yield put(
    actions.setPopup({
      popupId: action.payload.popupId,
      isOpen:  action.payload.isOpen,
      data:    action.payload.data,
    }),
  );
}

/**
 * Set new popup to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function popupOpenSagaWatcher
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* popupOpenSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(POPUP_INIT, popupOpenSagaWorker)]);
}

/**
 * Close popup saga worker.
 *
 * @author Ihar Kazlouski
 * @function popupCloseSagaWorker
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<PutEffect>} action data.
 */
function* popupCloseSagaWorker (
  action: PayloadAction<PopupClosePayload>,
): Generator<PutEffect> {
  yield put(
    actions.popupClose({
      popupId: action.payload.popupId,
    }),
  );

  yield put({
    type:    `${POPUP_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    payload: {
      popupId: action.payload.popupId,
    },
  });
}

/**
 * Close popup.
 *
 * @author Ihar Kazlouski
 * @function popupCloseSagaWatcher
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* popupCloseSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_CLOSE_POPUP, popupCloseSagaWorker)]);
}

/**
 * Close popup with answer saga worker.
 *
 * @author Ihar Kazlouski
 * @function popupCloseWithAnswerSagaWorker
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<PutEffect>} action data.
 */
function* popupCloseWithAnswerSagaWorker (
  action: PayloadAction<PopupCloseWithAnswerPayload>,
): Generator<PutEffect> {
  yield put(
    actions.closeWithAnswer({
      popupId:  action.payload.popupId,
      accepted: action.payload.accepted,
      rejected: action.payload.rejected,
    }),
  );

  if (action.payload.accepted) {
    yield put({
      type:    `${POPUP_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: {
        popupId:  action.payload.popupId,
        accepted: action.payload.accepted,
        rejected: action.payload.rejected,
      },
    });
  } else {
    yield put({
      type:  `${POPUP_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: {
        popupId:  action.payload.popupId,
        accepted: action.payload.accepted,
        rejected: action.payload.rejected,
      },
    });
  }
}

/**
 * Close popup with answer.
 *
 * @author Ihar Kazlouski
 * @function popupCloseWithAnswerSagaWatcher
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* popupCloseWithAnswerSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(INIT_POPUP_CLOSING_WITH_ANSWER, popupCloseWithAnswerSagaWorker),
  ]);
}

/**
 * Popups saga.
 *
 * @author Ihar Kazlouski
 * @function popupsSaga
 * @category Sagas
 * @subcategory Popups
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* popupsSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(popupOpenSagaWatcher),
    fork(popupCloseSagaWatcher),
    fork(popupCloseWithAnswerSagaWatcher),
  ]);
}
