import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  TradingLimitBuyForm,
  TradingLimitBuyValues,
  TradingLimitSellForm,
  TradingLimitSellValues,
  TradingMarketBuyForm,
  TradingMarketBuyValues,
  TradingMarketSellForm,
  TradingMarketSellValues,
} from '@forms';
import { tradingSettingsTabs } from '@popups/profile/components/terminal-trading-settings/helper';

import { Box, Stack, Typography } from '@mui/material';

import { getCurrentNetworkSelector } from '@store/network/network.selectors';
import { TradeSettingsResponse } from '@store/terminal';

import { SnackbarsService, TerminalService } from '@services';
import { isDefined } from '@helpers';

import { Snackbars, TerminalTradingSettingsTabs } from '@enums';
import { AxiosResponse } from '@types';

import { StyledTab } from './terminal-trading-settings.styles';

const TerminalTradingSettingsTab: FC = () => {
  const currentNetwork = useSelector(getCurrentNetworkSelector);
  const [limitBuyInitial, setLimitBuyInitial] =
    useState<TradingLimitBuyValues | null>(null);
  const [limitSellInitial, setLimitSellInitial] =
    useState<TradingLimitSellValues | null>(null);
  const [marketBuyInitial, setMarketBuyInitial] =
    useState<TradingMarketBuyValues | null>(null);
  const [marketSellInitial, setMarketSellInitial] =
    useState<TradingMarketSellValues | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(
    tradingSettingsTabs[0].value,
  );

  useEffect(() => {
    TerminalService.apiTradeSettingsGet({ networkId: currentNetwork?.id || '' })
      .then((data) => {
        const resp = data as AxiosResponse<TradeSettingsResponse>;
        resp.data.results.forEach((result) => {
          if (result.type === TerminalTradingSettingsTabs.LIMIT_BUY) {
            setLimitBuyInitial(result.data as TradingLimitBuyValues);
          }

          if (result.type === TerminalTradingSettingsTabs.LIMIT_SELL) {
            setLimitSellInitial(result.data as TradingLimitSellValues);
          }

          if (result.type === TerminalTradingSettingsTabs.MARKET_BUY) {
            setMarketBuyInitial(result.data as TradingMarketBuyValues);
          }

          if (result.type === TerminalTradingSettingsTabs.MARKET_SELL) {
            setMarketSellInitial(result.data as TradingMarketSellValues);
          }
        });
      })
      .catch((error) => {
        // TODo: error handling
      });
  }, []);

  const handleSubmit = async (
    data:
    | TradingMarketBuyValues
    | TradingMarketSellValues
    | TradingLimitBuyValues
    | TradingLimitSellValues,
  ): Promise<void> => {
    try {
      if (!isDefined(currentNetwork)) {
        throw new Error('');
      }

      await TerminalService.apiTerminalSettingsPost({
        data,
        networkId:  currentNetwork.id,
        trade_type: selectedTab,
      });
      void SnackbarsService.open(Snackbars.Success, {
        title: 'Настройки сохранены',
      });
    } catch (error) {
      void SnackbarsService.open(Snackbars.Error, {
        title: 'Что-то пошло не так...',
      });
    }
  };

  return (
    <>
      <Box display='flex' justifyContent='center' flexWrap='wrap'>
        {tradingSettingsTabs.map((tab) => (
          <StyledTab
            key={tab.value}
            active={selectedTab === tab.value}
            disabled={tab.value === TerminalTradingSettingsTabs.APPROVE}
            onClick={(): void => {
              setSelectedTab(tab.value);
            }}
          >
            <Typography variant='body2' fontWeight={500} color='inherit'>
              {tab.label}
            </Typography>
          </StyledTab>
        ))}
      </Box>
      <Stack p={3}>
        {selectedTab === TerminalTradingSettingsTabs.MARKET_BUY && (
          <TradingMarketBuyForm
            onSubmit={handleSubmit}
            isSettings={true}
            initials={marketBuyInitial}
          />
        )}
        {selectedTab === TerminalTradingSettingsTabs.MARKET_SELL && (
          <TradingMarketSellForm
            onSubmit={handleSubmit}
            isSettings={true}
            initials={marketSellInitial}
          />
        )}
        {selectedTab === TerminalTradingSettingsTabs.LIMIT_BUY && (
          <TradingLimitBuyForm
            onSubmit={handleSubmit}
            isSettings={true}
            initials={limitBuyInitial}
          />
        )}
        {selectedTab === TerminalTradingSettingsTabs.LIMIT_SELL && (
          <TradingLimitSellForm
            onSubmit={handleSubmit}
            isSettings={true}
            initials={limitSellInitial}
          />
        )}
      </Stack>
    </>
  );
};

export { TerminalTradingSettingsTab };
