import { ReactElement, ReactNode } from 'react';
import * as React from 'react';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { Stack, Typography } from '@mui/material';

import { Icon } from '@elements';
import { MaterialTableProps } from '@elements/material-table/material-table.types';

import { colors } from '@themes';

import alertIcon from '@images/alert-circle.svg';
import arrowDown from '@images/arrow-down.image.svg';
import checkedIcon from '@images/check.svg';
import sorting from '@images/sorting.svg';
import uncheckedIcon from '@images/uncheked.svg';

function MaterialTable<
  T extends Record<string, unknown> = Record<string, unknown>,
> ({
  data,
  columns,
  displayColumnDefOptions,
  ...props
}: MaterialTableProps<T>): ReactElement {
  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode:              'grid',
    positionExpandColumn:    'last',
    positionActionsColumn:   'last',
    enableRowActions:        true,
    enableRowSelection:      (row) => row.depth === 0,
    enableSubRowSelection:   false,
    enableExpanding:         false,
    enableTopToolbar:        false,
    enableBottomToolbar:     false,
    enableExpandAll:         false,
    enableSelectAll:         false,
    enableColumnActions:     false,
    enableMultiRowSelection: true,
    muiTableHeadCellProps:   {
      sx: {
        borderBottom: `1px solid ${colors.white10}`,
        fontSize:     '12px',
        color:        colors.white50,
        lineHeight:   '17px',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'unset',
      },
    },
    renderEmptyRowsFallback: (): ReactNode => {
      return (
        <Stack padding={10} gap={2} alignItems='center'>
          <Icon src={alertIcon} width={84} height={84} color='white' />
          <Typography variant='body2' color='white' fontWeight={500}>
            Записи не найдены
          </Typography>
        </Stack>
      );
    },
    muiSelectCheckboxProps: ({ row }) => {
      return {
        icon:
          row.depth === 0 ? (
            <Icon src={uncheckedIcon} width={20} height={20} />
          ) : (
            <></>
          ),
        checkedIcon: <Icon src={checkedIcon} width={20} height={20} />,
      };
    },
    muiDetailPanelProps: ({ row }) => {
      return {
        sx: {
          backgroundColor: `${colors.white4}`,
          borderBottom:    row.getIsExpanded()
            ? `1px solid ${colors.white10}`
            : 'unset',
        },
      };
    },
    muiTableBodyCellProps: {
      sx: {
        color:        '#FFF',
        borderBottom: `1px solid ${colors.white10}`,
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      return {
        sx: {
          backgroundColor:  row.getIsExpanded() ? colors.white4 : `unset`,
          '&.Mui-selected': {
            backgroundColor: row.getIsExpanded() ? colors.white4 : `unset`,
            '&:hover':       {
              backgroundColor: `${colors.white4}`,
            },
            '& td::after': {
              backgroundColor: `unset`,
            },
          },
          '&:hover td::after': {
            backgroundColor: `${colors.white4}`,
          },
        },
      };
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        Header: '',
        grow:   true,
      },
      'mrt-row-select': {
        Header:  '',
        size:    46,
        maxSize: 46,
        grow:    false,
      },
      'mrt-row-expand': {
        Header:  '',
        size:    46,
        maxSize: 46,
        grow:    false,
      },
      ...displayColumnDefOptions,
    },

    icons: {
      ExpandMoreIcon: (props: {
        style: { transform: string };
      }): JSX.Element => {
        const showExpandIcon = props?.style?.transform !== 'rotate(90deg)';

        return showExpandIcon ? (
          <Icon src={arrowDown} width={18} height={18} {...props} />
        ) : (
          <></>
        );
      },
      SyncAltIcon: (props: object): JSX.Element => (
        <Icon
          src={sorting}
          width={20}
          height={20}
          {...props}
          style={{ transform: 'none' }}
        />
      ),
      ArrowDownwardIcon: (props: object): JSX.Element => (
        <Icon src={sorting} width={20} height={20} {...props} />
      ),
    },
    ...props,
  });

  return <MaterialReactTable table={table} />;
}

export { MaterialTable };
