export enum Routes {
  All = '*',
  Login = '/login',
  Pin = '/pin',
  Mnemonic = '/mnemonic',
  Main = '/',
  NewPairs = '/new-pairs',
  Trends = '/trends',
  Terminal = '/terminal',
  Strategies = '/strategies',
  Sniping = '/sniping',
  CopyTrading = '/copy-trading',
  Wallet = '/wallet',
  WalletActions = '/wallet/actions/:id',
  Futures = '/futures',
  Profile = '/profile',
  Export = '/export/:id',

  Error = '/error',
  NotFound = '/404',
}
