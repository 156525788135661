import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Box, ButtonBase, IconButton, Menu, menuClasses, styled } from '@mui/material';
import { pxToRem } from '@themes';
export var StyledContainer = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    borderTop: "1px solid ".concat(theme.palette.white10.main),
    background: theme.palette.mirage.main,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(1.5, 2.5, 2.5),
    height: theme.spacing(10)
  };
  return styles;
});
export var StyledNavLink = styled(ButtonBase, {
  shouldForwardProp: function shouldForwardProp(propName) {
    return propName !== 'active';
  }
})(function (_ref2) {
  var theme = _ref2.theme,
    active = _ref2.active;
  var styles = _defineProperty({
    width: '100%',
    color: active ? theme.palette.white.main : theme.palette.white50.main,
    padding: theme.spacing(0),
    gap: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none'
  }, '&.active', {
    color: theme.palette.white.main
  });
  return styles;
});
export var StyledMenu = styled(Menu)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = _defineProperty(_defineProperty({}, "& .".concat(menuClasses.paper), {
    background: theme.palette.mirage.main,
    color: theme.palette.white.main,
    border: "1px solid ".concat(theme.palette.white10.main),
    borderRadius: pxToRem(24),
    maxWidth: '340px',
    width: '100%',
    overflow: 'visible'
  }), "& .".concat(menuClasses.list), {
    padding: theme.spacing(0)
  });
  return styles;
});
export var StyledMenuNavLink = styled(ButtonBase)(function (_ref4) {
  var theme = _ref4.theme,
    disabled = _ref4.disabled;
  var styles = _objectSpread(_defineProperty(_defineProperty({
    display: 'flex',
    padding: theme.spacing(2, 2.5),
    width: '100%',
    justifyContent: 'space-between'
  }, '&:not(:last-of-type)', {
    borderBottom: "1px solid ".concat(theme.palette.white10.main)
  }), '&.active', {
    color: theme.palette.blue.main
  }), disabled ? {
    pointerEvents: 'none',
    color: theme.palette.white50.main
  } : {});
  return styles;
});
export var StyledMenuCloseButton = styled(IconButton)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    color: theme.palette.white.main,
    position: 'absolute',
    bottom: theme.spacing(-7),
    left: '50%',
    transform: 'translateX(-50%)'
  };
  return styles;
});