import { ApiService } from '@services';

import {
  ApiError,
  AxiosRequestConfig,
  AxiosResponse,
  RegisterResponse,
} from '@types';

/**
 * Private structures.
 *
 * @author Ihar Kazlouski
 * @class PrivateStructuresServiceClass
 * @category Services
 */
class PrivateStructuresServiceClass {
  /**
   * Private structures api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<UploadAllUsersResponse[]>} promise.
   */
  async apiPrivateStructuresGet (
    queryProps?: AxiosRequestConfig,
  ): Promise<ApiError | AxiosResponse<RegisterResponse>> {
    return ApiService.apiGet('/private/structures');
  }
}

const PrivateStructuresService = new PrivateStructuresServiceClass();

export { PrivateStructuresService };
