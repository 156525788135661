import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { CheckboxFieldProps } from './checkbox-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: CheckboxFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledCheckboxBase = styled(Checkbox, {
  shouldForwardProp: (prop: string | number | symbol): boolean => {
    return prop !== 'fullWidth';
  },
})<CheckboxFieldProps & { theme: DefaultTheme; type: string }>(
  ({ theme, color }) => {
    const styles: OverridesStyleRules = {
      color:   theme.palette.cornflowerBlue.main,
      padding: theme.spacing(1),

      ['&.Mui-checked .MuiSvgIcon-root']: {
        color:           theme.colors.white,
        backgroundColor: theme.colors.blue,
        borderRadius:    '100%',
      },
      ['& .MuiSvgIcon-root']: {
        height: pxToRem(20),
      },
    };

    return styles;
  },
);

const StyledInputLabel = styled(InputLabel)<{
  theme: DefaultTheme;
  $color: string;
}>(({ theme, $color }) => {
  const styles: OverridesStyleRules = {
    position:        'relative',
    transform:       'unset',
    transformOrigin: 'unset',
    maxWidth:        '100%',
    fontSize:        pxToRem(16),
    lineHeight:      pxToRem(18),
    fontWeight:      400,
    color:           theme.palette.tuna.main,
    display:         'block',
    paddingLeft:     theme.spacing(1),
    marginBottom:    theme.spacing(0.5),
  };

  return styles;
});

const StyleFromHelperWrapper = styled(Box)<{ relative?: boolean }>(() => {
  const styles: OverridesStyleRules = {
    position:   'absolute',
    left:       0,
    right:      0,
    top:        '100%',
    marginTop:  pxToRem(4),
    fontSize:   pxToRem(12),
    lineHeight: pxToRem(14),
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    '&&': {
      color:    theme.palette.frenchRose.main,
      display:  'block',
      padding:  theme.spacing(0, 1),
      margin:   'unset',
      maxWidth: '100%',
    },
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
  $color: string;
}>(({ theme, $color }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.tuna.main,
    display:  'block',
    padding:  theme.spacing(0, 1),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

const StyledCheckboxLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop: string | number | symbol): boolean => {
    return prop !== 'labelAlign';
  },
})<{
  theme: DefaultTheme;
  labelAlign: CheckboxFieldProps['labelAlign'];
}>(({ theme, labelAlign }) => {
  const styles: OverridesStyleRules = {
    marginLeft:  theme.spacing(-1),
    marginRight: '0',
  };

  if (labelAlign === 'top') {
    styles.alignItems = 'flex-start';
  }

  return styles;
});

export {
  StyledCheckboxBase,
  StyledCheckboxLabel,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyleFromHelperWrapper,
};
