import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  CLOSE_SNACKBAR,
  CLOSING_SNACKBAR_WITH_ANSWER,
  INIT_CLOSE_SNACKBAR,
  INIT_SNACKBAR_CLOSING_WITH_ANSWER,
  SET_SNACKBAR,
  SNACKBAR_INIT,
} from './snackbars.action-types';
import {
  SnackbarClosePayload,
  SnackbarCloseWithAnswerPayload,
  SnackbarPayload,
} from './snackbars.types';

export const actions = {
  /**
   * Init snackbar closing.
   *
   * @author Ihar Kazlouski
   * @function snackbarInitClosing
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarClosePayload} payload payload.
   */
  snackbarInitClosing: (payload: SnackbarClosePayload) =>
    ({
      type: INIT_CLOSE_SNACKBAR,
      payload,
    } as const),

  /**
   * Close snackbar.
   *
   * @author Ihar Kazlouski
   * @function snackbarClose
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarClosePayload} payload payload.
   */
  snackbarClose: (payload: SnackbarClosePayload) =>
    ({
      type: CLOSE_SNACKBAR,
      payload,
    } as const),

  /**
   * Init snackbar opening.
   *
   * @author Ihar Kazlouski
   * @function snackbarInit
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarPayload} payload payload.
   */
  snackbarInit: (payload: SnackbarPayload) =>
    ({
      type:              SNACKBAR_INIT,
      payload,
      [WAIT_FOR_ACTION]: `${SNACKBAR_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${SNACKBAR_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Set necessary snackbar.
   *
   * @author Ihar Kazlouski
   * @function setSnackbar
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarPayload} payload payload.
   */
  setSnackbar: (payload: SnackbarPayload) =>
    ({
      type: SET_SNACKBAR,
      payload,
    } as const),

  /**
   * Init closing with answer.
   *
   * @author Ihar Kazlouski
   * @function snackbarInitClosingWithAnswer
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarCloseWithAnswerPayload} payload payload.
   */
  snackbarInitClosingWithAnswer: (payload: SnackbarCloseWithAnswerPayload) =>
    ({
      type: INIT_SNACKBAR_CLOSING_WITH_ANSWER,
      payload,
    } as const),

  /**
   * Close snackbar with answer.
   *
   * @author Ihar Kazlouski
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Snackbars
   * @param {SnackbarCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: (payload: SnackbarCloseWithAnswerPayload) =>
    ({
      type: CLOSING_SNACKBAR_WITH_ANSWER,
      payload,
    } as const),
};
