import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Box, FormControl, FormHelperText, formHelperTextClasses, inputBaseClasses, InputLabel, inputLabelClasses, MenuItem, Select, selectClasses, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var theme = _ref.theme,
    fullWidth = _ref.fullWidth,
    disabled = _ref.disabled;
  var styles = _objectSpread({
    display: fullWidth && 'flex',
    maxWidth: '100%'
  }, disabled ? _defineProperty({
    opacity: '0.5',
    pointerEvents: 'none'
  }, "".concat(StyledInputLabel), {
    color: theme.palette.white50.main
  }) : {});
  return styles;
});
var StyledInputLabel = styled(InputLabel)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = _defineProperty(_defineProperty(_defineProperty({
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    color: theme.palette.white50.main,
    transform: 'translate(12px, 16px) scale(1)',
    pointerEvents: 'none'
  }, "&.".concat(inputLabelClasses.shrink), {
    transform: 'translate(12px, 7px) scale(0.85)'
  }), "&.".concat(inputLabelClasses.error), {
    color: theme.palette.white50.main
  }), "&.".concat(inputLabelClasses.focused), {
    color: theme.palette.white.main
  });
  return styles;
});
var StyledSelect = styled(Select)(function (_ref4) {
  var theme = _ref4.theme;
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
    background: theme.palette.white4.main,
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.white10.main),
    borderRadius: theme.typography.pxToRem(10),
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color'])
  }, '&:hover', {
    background: theme.palette.white4.main,
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.white.main)
  }), "&.".concat(inputBaseClasses.focused), _defineProperty({
    background: theme.palette.white4.main,
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.white.main)
  }, ".".concat(selectClasses.icon), {
    color: theme.palette.white.main
  })), "&.".concat(selectClasses.disabled), {
    WebkitTextFillColor: theme.palette.white.main,
    pointerEvents: 'none'
  }), "& .".concat(inputBaseClasses.input), _defineProperty({
    boxSizing: 'border-box',
    height: theme.typography.pxToRem(50),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    color: theme.palette.white.main,
    padding: theme.spacing(3, 1.5, 0.5),
    position: 'relative'
  }, '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button', {
    WebkitAppearance: 'none',
    margin: theme.spacing(0)
  })), "&.".concat(selectClasses.error), {
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.lightCoral.main)
  }), "& .".concat(selectClasses.icon), {
    right: theme.spacing(1.5),
    top: pxToRem(16),
    color: theme.palette.white50.main
  });
});
var StyledMenuItem = styled(MenuItem)(function (_ref6) {
  var theme = _ref6.theme;
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    '&:hover': {
      background: theme.palette.white8.main
    }
  };
});
var StyleFormHelperWrapper = styled(Box)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    marginTop: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex: 1
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = _defineProperty({
    color: theme.palette.lightCoral.main,
    display: 'block',
    padding: theme.spacing(0, 1),
    margin: 'unset',
    maxWidth: '100%'
  }, "&.".concat(formHelperTextClasses.error), {
    color: theme.palette.lightCoral.main
  });
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref9) {
  var theme = _ref9.theme;
  var styles = {
    color: theme.palette.secondary.main,
    display: 'block',
    padding: theme.spacing(0, 2),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
export { StyledFormControl, StyledFormHelperErrorText, StyledFormHelperText, StyledInputLabel, StyledMenuItem, StyledSelect, StyleFormHelperWrapper };