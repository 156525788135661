import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledRangeButtonsWrapper = styled(Box)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      marginBottom: theme.spacing(1.5),
    };

    return styles;
  },
);

export { StyledRangeButtonsWrapper };
