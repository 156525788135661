import React, { createElement, FC } from 'react';

import { StyledPopupContentContainer } from './popup-content.styled';
import { PopupContentProps } from './popup-content.types';

/**
 * PopupContentSlot component.
 *
 * @author Ihar Kazlouski
 * @function PopupContent
 * @category components
 * @param {PopupContentProps} content props.
 * @return {JSX.Element} PopupContentSlot component.
 */
const PopupContent: FC<PopupContentProps> = ({
  children,
  ...restProps
}): JSX.Element => {
  const content =
    typeof children === 'function'
      ? createElement(children, { ...restProps })
      : children;

  return <StyledPopupContentContainer>{content}</StyledPopupContentContainer>;
};

PopupContent.displayName = 'PopupContent';

export { PopupContent };
