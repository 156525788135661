import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Button } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledWalletContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding:         theme.spacing(2),
      backgroundColor: theme.colors.mirage,
      borderRadius:    pxToRem(16),
      position:        'relative',
      width:           '100%',
    };

    return styles;
  },
);

const IconRound = styled(Box)<{ theme?: DefaultTheme; selected?: boolean }>(
  ({ theme, selected }) => {
    const styles: OverridesStyleRules = {
      padding:         theme.spacing(0.5),
      borderRadius:    pxToRem(100),
      backgroundColor: selected
        ? theme.colors.cornflowerBlue
        : theme.colors.white8,
      color: selected ? theme.colors.white : theme.colors.white8,
    };

    return styles;
  },
);

const StyledButton = styled(Button)<{
  theme?: DefaultTheme;
  selected: boolean;
}>(({ theme, selected }) => {
  const styles: OverridesStyleRules = {
    width:           '100%',
    height:          '100%',
    backgroundColor: selected ? theme.colors.white20 : theme.colors.white8,
    color:           selected ? theme.colors.white : theme.colors.white50,
    textTransform:   'none',
    display:         'flex',
    alignItems:      'center',
    gap:             pxToRem(8),
    justifyContent:  'start',
    padding:         theme.spacing(1),
    whiteSpace:      'nowrap',

    '&:hover': {
      backgroundColor: theme.colors.white20,
      color:           theme.colors.white,
    },

    [`&:hover ${IconRound}`]: {
      backgroundColor: theme.colors.cornflowerBlue,
      color:           theme.colors.white,
    },
  };

  return styles;
});

const ExpandBlock = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:                        theme.spacing(1, 0),
    borderRadius:                   pxToRem(12),
    backgroundColor:                theme.colors.mirage,
    marginTop:                      theme.spacing(2),
    position:                       'absolute',
    left:                           '0',
    zIndex:                         '10',
    width:                          '100%',
    border:                         `1px solid ${theme.colors.white10}`,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 32px)',
    },
  };

  return styles;
});

const WalletItem = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:         theme.spacing(1),
    borderBottom:    `1px solid ${theme.colors.white10}`,
    '&:first-child': {
      paddingTop: 0,
    },
  };

  return styles;
});

export {
  ExpandBlock,
  IconRound,
  StyledButton,
  StyledWalletContainer,
  WalletItem,
};
