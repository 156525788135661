import { Box, Stack, styled } from '@mui/material';

export const StyledContainer = styled(Stack)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.white10.main}`,
  };
});

export const StyledStatusFilterItem = styled(Box)(({ theme }) => {
  return {
    padding:     theme.spacing(0.5, 1),
    borderRight: `1px solid ${theme.palette.white10.main}`,
  };
});
