import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  CREATE_WALLET_FAILURE,
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_SUCCESS,
  DELETE_WALLET_FAILURE,
  DELETE_WALLET_REQUEST,
  DELETE_WALLET_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_WALLETS_REQUEST,
  FETCH_WALLETS_SUCCESS,
  UPDATE_WALLET_FAILURE,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_SUCCESS,
} from './wallet.action.types';
import {
  CreateWalletFailurePayload,
  CreateWalletRequestPayload,
  CreateWalletSuccessPayload,
  DeleteWalletFailurePayload,
  DeleteWalletRequestPayload,
  DeleteWalletSuccessPayload,
  FetchWalletsFailurePayload,
  FetchWalletsSuccessPayload,
  UpdateWalletFailurePayload,
  UpdateWalletRequestPayload,
  UpdateWalletSuccessPayload,
} from './wallet.types';

export const actions = {
  /**
   * Fetch wallets request action.
   *
   * @author Ihar Kazlouski
   * @function fetchWalletsRequest
   * @category Actions
   * @subcategory Wallet
   * @param {string} networkId network id.
   * @return {fetchWalletsRequest} fetch wallet request.
   */
  fetchWalletsRequest: (networkId: string) =>
    ({
      type:              FETCH_WALLETS_REQUEST,
      payload:           { networkId },
      [WAIT_FOR_ACTION]: `${FETCH_WALLETS_REQUEST}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${FETCH_WALLETS_REQUEST}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Fetch wallet request success action.
   *
   * @author Ihar Kazlouski
   * @function fetchWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @param {FetchWalletsSuccessPayload} payload payload.
   * @return {fetchUserSuccess} fetch wallet success.
   */
  fetchWalletSuccess: (payload: FetchWalletsSuccessPayload) =>
    ({
      type: FETCH_WALLETS_SUCCESS,
      payload,
    } as const),

  /**
   * Fetch wallet request failure action.
   *
   * @author Ihar Kazlouski
   * @function fetchWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {FetchWalletsFailurePayload} payload payload.
   * @return {fetchUserFailure} fetch wallet request failure.
   */
  fetchWalletFailure: (payload: FetchWalletsFailurePayload) =>
    ({
      type: FETCH_WALLETS_FAILURE,
      payload,
    } as const),

  /**
   * create wallet request action.
   *
   * @author Ihar Kazlouski
   * @function createWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @param {string} networkId network id.
   * @return {createWalletRequest} create wallet request.
   */
  createWalletRequest: (payload: CreateWalletRequestPayload) =>
    ({
      type: CREATE_WALLET_REQUEST,
      payload,
    } as const),

  /**
   * create wallet success action.
   *
   * @author Ihar Kazlouski
   * @function createWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @param {CreateWalletSuccessPayload} payload payload.
   * @return {createWalletSuccess} create wallet success.
   */
  createWalletSuccess: (payload: CreateWalletSuccessPayload) =>
    ({
      type: CREATE_WALLET_SUCCESS,
      payload,
    } as const),

  /**
   * create wallet request failure action.
   *
   * @author Ihar Kazlouski
   * @function createWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {CreateWalletFailurePayload} payload payload.
   * @return {createWalletFailure} create wallet request failure.
   */
  createWalletFailure: (payload: CreateWalletFailurePayload) =>
    ({
      type: CREATE_WALLET_FAILURE,
      payload,
    } as const),

  /**
   * delete wallet request action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @param {DeleteWalletRequestPayload} payload.
   * @return {deleteWalletRequest} delete wallet request.
   */
  deleteWalletRequest: (payload: DeleteWalletRequestPayload) =>
    ({
      type: DELETE_WALLET_REQUEST,
      payload,
    } as const),

  /**
   * delete wallet success action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @param {DeleteWalletSuccessPayload} payload payload.
   * @return {deleteWalletSuccess} delete wallet success.
   */
  deleteWalletSuccess: (payload: DeleteWalletSuccessPayload) =>
    ({
      type: DELETE_WALLET_SUCCESS,
      payload,
    } as const),

  /**
   * delete wallet request failure action.
   *
   * @author Ihar Kazlouski
   * @function deleteWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {DeleteWalletFailurePayload} payload payload.
   * @return {deleteWalletFailure} delete wallet request failure.
   */
  deleteWalletFailure: (payload: DeleteWalletFailurePayload) =>
    ({
      type: DELETE_WALLET_FAILURE,
      payload,
    } as const),

  /**
   * update wallet request action.
   *
   * @author Ihar Kazlouski
   * @function updateWalletRequest
   * @category Actions
   * @subcategory Wallet
   * @param {UpdateWalletRequestPayload} payload.
   * @return {updateWalletRequest} update wallet request.
   */
  updateWalletRequest: (payload: UpdateWalletRequestPayload) =>
    ({
      type: UPDATE_WALLET_REQUEST,
      payload,
    } as const),

  /**
   * update wallet success action.
   *
   * @author Ihar Kazlouski
   * @function updateWalletSuccess
   * @category Actions
   * @subcategory Wallet
   * @param {UpdateWalletSuccessPayload} payload payload.
   * @return {updateWalletSuccess} update wallet success.
   */
  updateWalletSuccess: (payload: UpdateWalletSuccessPayload) =>
    ({
      type: UPDATE_WALLET_SUCCESS,
      payload,
    } as const),

  /**
   * update wallet request failure action.
   *
   * @author Ihar Kazlouski
   * @function updateWalletFailure
   * @category Actions
   * @subcategory Wallet
   * @param {UpdateWalletFailurePayload} payload payload.
   * @return {updateWalletFailure} update wallet request failure.
   */
  updateWalletFailure: (payload: UpdateWalletFailurePayload) =>
    ({
      type: UPDATE_WALLET_FAILURE,
      payload,
    } as const),
};
