import { Box, BoxProps, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledPublicWrapper = styled(Box)<BoxProps>(() => {
  const styles: OverridesStyleRules = {
    maxWidth:      '100%',
    display:       'flex',
    flexDirection: 'column',
  };
  return styles;
});

const StyledMain = styled(Box, {
  shouldForwardProp: (prop: string | number | symbol): boolean => {
    return prop !== 'isMobile';
  },
})<BoxProps & { isMobile: boolean }>(({ theme, isMobile }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    flexDirection: 'column',
    width:         '100%',
    height:        '100%',
    paddingBottom: theme.spacing(isMobile ? 10 : 0),

    [theme.breakpoints.up('desktop')]: {
      height: 'calc(100vh - 65px)',
    },
  };

  return styles;
});

export { StyledMain, StyledPublicWrapper };
