import { Box, IconButton, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledContainer = styled(Box)<{ theme: DefaultTheme }>(({ theme }) => ({
  textAlign: 'center',
}));

const StyledTitle = styled(Box)<{ theme: DefaultTheme }>(({ theme }) => ({
  ...theme.typography.h1,
  color:        theme.colors.white,
  marginBottom: theme.spacing(1.5),
}));

const StyledTitleContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      display:    'flex',
      alignItems: 'center',
      padding:    theme.spacing(3, 2),
      gap:        pxToRem(16),
      border:     `1px solid ${theme.colors.white10}`,

      [theme.breakpoints.down('tablet')]: {
        display:   'block',
        padding:   0,
        gap:       'unset',
        border:    'none',
        textAlign: 'center',

        '& h1': {
          fontSize: '16px',
        },
      },
    };
    return styles;
  },
);

const StyledButtonWrapper = styled(Box)<{
  theme: DefaultTheme;
  isMobile?: boolean;
}>(({ theme, isMobile }) => ({
  margin: `${isMobile ? pxToRem(30) : pxToRem(32)} auto 0`,
  width:  pxToRem(496),

  [theme.breakpoints.down('tablet')]: {
    width: pxToRem(343),
  },
}));

const StyledBackButton = styled(IconButton)<{ theme: DefaultTheme }>(
  ({ theme }) => ({
    width: pxToRem(52),
    // [theme.breakpoints.up('lg')]: {
    //   display: 'none',
    // },
  }),
);

export {
  StyledBackButton,
  StyledButtonWrapper,
  StyledContainer,
  StyledTitle,
  StyledTitleContainer,
};
