import { Alert, Snackbar, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledSnackbar = styled(Snackbar)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      marginTop:                          pxToRem(38),
      marginLeft:                         pxToRem(-8),
      [theme.breakpoints.down('tablet')]: {
        width:      `calc(100% - ${pxToRem(32)})`,
        marginTop:  pxToRem(28),
        marginLeft: pxToRem(-8),
        top:        pxToRem(24),
        left:       pxToRem(24),
        right:      'auto',
      },
    };

    return styles;
  },
);

const SnackbarAlert = styled(Alert)<{ theme?: DefaultTheme }>(
  ({ theme, severity }) => {
    const styles: OverridesStyleRules = {
      display:         'flex',
      alignItems:      'center',
      minWidth:        pxToRem(250),
      borderRadius:    pxToRem(12),
      fontWeight:      500,
      fontSize:        pxToRem(14),
      lineHeight:      pxToRem(18),
      backgroundColor: '#1B1B2A',
    };

    switch (severity) {
      case 'success': {
        styles.color = theme.colors.mediumSeaGreen;

        break;
      }

      case 'error': {
        styles.color = theme.palette.maroonFlush.main;

        break;
      }

      default: {
        break;
      }
    }

    return styles;
  },
);

export { SnackbarAlert, StyledSnackbar };
