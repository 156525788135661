import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';

import { StyledIconButtonWrapper } from './modal-title.styled';
import { ModalTitleProps } from './modal-title.types';

/**
 * ModalTitleSlot component.
 *
 * @author Ihar Kazlouski
 * @function ModalTitle
 * @category components
 * @param {ModalFooterProps} content props.
 * @return {JSX.Element} ModalTitleSlot component.
 */
const ModalTitle: FC<ModalTitleProps> = ({
  children,
  handleClose,
}): JSX.Element => {
  const titleContent =
    typeof children === 'string' ? (
      <Typography variant='h5' component='p'>
        {children}
      </Typography>
    ) : (
      children
    );

  return (
    <Grid
      container
      spacing={2}
      flexWrap='nowrap'
      alignItems='flex-start'
      justifyContent='space-between'
    >
      <Grid item xs={12}>
        {titleContent}
      </Grid>
      <Grid item xs='auto'>
        <StyledIconButtonWrapper>
          <IconButton color='secondary' onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </StyledIconButtonWrapper>
      </Grid>
    </Grid>
  );
};

ModalTitle.displayName = 'ModalTitle';

export { ModalTitle };
