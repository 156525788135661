import React from 'react';
import { useSelector } from 'react-redux';

import { getWalletsSelector } from '@store/wallet/wallet.selectors';

import { ProtectedContentProps } from './protected-content.types';

export const ProtectedContent: React.FC<ProtectedContentProps> = ({
  children,
  fallback,
}) => {
  const wallets = useSelector(getWalletsSelector);

  return <>{wallets.length ? children : fallback ?? null}</>;
};
