import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { OrderStatusFilterConfig } from '@helpers';

import { OrderStatusFilter } from '@enums';

import { CheckboxField } from '@elements';

import {
  StyledContainer,
  StyledStatusFilterItem,
} from './orders-list-actions.styles';

import { OrdersListActionsProps } from './orders-list-actions.types';

export const OrdersListActions: React.FC<OrdersListActionsProps> = ({
  selectedStatuses,
  onStatusesChange,
}) => {
  const { t } = useTranslation();

  const handleStatusFilterChange = (
    checked: boolean,
    status: OrderStatusFilter,
  ): void => {
    if (checked) {
      onStatusesChange([...selectedStatuses, status]);
    } else {
      onStatusesChange(selectedStatuses.filter((s) => s !== status));
    }
  };

  return (
    <StyledContainer direction='row' justifyContent='space-between'>
      <Stack direction='row'>
        {Object.entries(OrderStatusFilterConfig).map(
          ([status, stautsFilterConfig]) => (
            <StyledStatusFilterItem key={status}>
              <CheckboxField
                checked={selectedStatuses.includes(status as OrderStatusFilter)}
                onChange={(event, checked): void =>
                  handleStatusFilterChange(checked, status as OrderStatusFilter)
                }
                title={
                  <Typography
                    component='span'
                    variant='body2'
                    color='white'
                    fontWeight={500}
                  >
                    {t(stautsFilterConfig.i18nLabelKey)}
                  </Typography>
                }
              />
            </StyledStatusFilterItem>
          ),
        )}
      </Stack>
    </StyledContainer>
  );
};
