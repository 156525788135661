import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { Icon } from '@elements/icon';

import { StyledSearchBar } from './search-bar.styles';

import searchIcon from '@assets/images/search.svg';

import { SearchBarProps } from './search-bar.types';

export const SearchBar: React.FC<SearchBarProps> = ({
  startAdornment = <Icon src={searchIcon} width={18} height={18} />,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledSearchBar
      {...props}
      placeholder={t('components.searchBar.placeholder')}
      startAdornment={
        <InputAdornment position='start'>{startAdornment}</InputAdornment>
      }
    />
  );
};
