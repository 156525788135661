import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { Networks } from '@enums';
import { FETCH_NETWORKS_FAILURE, FETCH_NETWORKS_REQUEST, FETCH_NETWORKS_SUCCESS, SET_CURRENT_NETWORK } from './network.action.types';
var initialState = {
  count: null,
  pending: false,
  currentNetwork: null,
  networks: null,
  error: null
};

/**
 * Networks reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Network
 * @param {NetworksState} state state.
 * @param {ActionsTypes} action action.
 * @return {NetworksState} network state.
 */
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_NETWORKS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pending = true;
        });
      }
    case FETCH_NETWORKS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.networks = action.payload.networks.results;
          draft.currentNetwork = action.payload.networks.results.find(function (network) {
            return network.name === Networks.Ethereum;
          }) || null;
          draft.count = action.payload.networks.count;
          draft.error = null;
        });
      }
    case FETCH_NETWORKS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pending = false;
          draft.networks = null;
          draft.currentNetwork = null;
          draft.count = null;
          draft.error = action.payload.error;
        });
      }
    case SET_CURRENT_NETWORK:
      {
        return produce(state, function (draft) {
          var _state$networks;
          draft.currentNetwork = ((_state$networks = state.networks) === null || _state$networks === void 0 ? void 0 : _state$networks.find(function (network) {
            return network.id === action.payload.networkId;
          })) || null;
        });
      }
    default:
      {
        return _objectSpread({}, state);
      }
  }
});