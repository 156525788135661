import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  CLOSE_POPUP,
  CLOSING_POPUP_WITH_ANSWER,
  INIT_CLOSE_POPUP,
  INIT_POPUP_CLOSING_WITH_ANSWER,
  POPUP_INIT,
  SET_POPUP,
} from './popups.action-types';
import {
  PopupClosePayload,
  PopupCloseWithAnswerPayload,
  PopupPayload,
} from './popups.types';

export const actions = {
  /**
   * Init popup closing.
   *
   * @author Ihar Kazlouski
   * @function popupInitClosing
   * @category Actions
   * @subcategory Popups
   * @param {PopupClosePayload} payload payload.
   */
  popupInitClosing: (payload: PopupClosePayload) =>
    ({
      type: INIT_CLOSE_POPUP,
      payload,
    } as const),

  /**
   * Close popup.
   *
   * @author Ihar Kazlouski
   * @function popupClose
   * @category Actions
   * @subcategory Popups
   * @param {PopupClosePayload} payload payload.
   */
  popupClose: (payload: PopupClosePayload) =>
    ({
      type: CLOSE_POPUP,
      payload,
    } as const),

  /**
   * Init popup opening.
   *
   * @author Ihar Kazlouski
   * @function popupInit
   * @category Actions
   * @subcategory Popups
   * @param {PopupPayload} payload payload.
   */
  popupInit: (payload: PopupPayload) =>
    ({
      type:              POPUP_INIT,
      payload,
      [WAIT_FOR_ACTION]: `${POPUP_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${POPUP_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Set necessary popup.
   *
   * @author Ihar Kazlouski
   * @function setPopup
   * @category Actions
   * @subcategory Popups
   * @param {PopupPayload} payload payload.
   */
  setPopup: (payload: PopupPayload) =>
    ({
      type: SET_POPUP,
      payload,
    } as const),

  /**
   * Init closing with answer.
   *
   * @author Ihar Kazlouski
   * @function popupInitClosingWithAnswer
   * @category Actions
   * @subcategory Popups
   * @param {PopupCloseWithAnswerPayload} payload payload.
   */
  popupInitClosingWithAnswer: (payload: PopupCloseWithAnswerPayload) =>
    ({
      type: INIT_POPUP_CLOSING_WITH_ANSWER,
      payload,
    } as const),

  /**
   * Close popup with answer.
   *
   * @author Ihar Kazlouski
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Popups
   * @param {PopupCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: (payload: PopupCloseWithAnswerPayload) =>
    ({
      type: CLOSING_POPUP_WITH_ANSWER,
      payload,
    } as const),
};
