import { dispatch as storeDispatch } from '@store';
import {
  actions,
  CreateWalletRequestPayload,
  DeleteWalletRequestPayload,
  UpdateWalletRequestPayload,
  WalletAssetsResponse,
  WalletCreateResponse,
  WalletDetailsResponse,
  WalletResponse,
  WalletUpdateResponse,
} from '@store/wallet';

import { ApiService } from '@services';

import { ApiError, AsyncDispatch, AxiosResponse } from '@types';

/**
 * Wallet controller.
 *
 * @author Ihar Kazlouski
 * @const WalletServiceClass
 * @category Services
 * @subcategory Wallet
 */
class WalletServiceClass {
  /**
   * Wallets get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<WalletResponse>} promise.
   */
  async apiWalletsGet (
    networkId: string,
  ): Promise<ApiError | AxiosResponse<WalletResponse>> {
    return ApiService.apiGet(`/private/networks/${networkId}/wallets`);
  }

  /**
   * Private key get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<WalletDetailsResponse>} promise.
   */
  async apiWalletPrivateKeyGet (
    networkId: string,
    walletId: string,
  ): Promise<ApiError | AxiosResponse<WalletDetailsResponse>> {
    return ApiService.apiGet(
      `/private/networks/${networkId}/wallets/${walletId}`,
    );
  }

  /**
   * Wallet asset get api function.
   *
   * @author Ihar Kazlouski
   * @return {Promise<ApiError | AxiosResponse<WalletDetailsResponse>} promise.
   */
  async apiWalletAssetGet ({
    networkId,
    walletId,
    limit,
    offset,
  }: {
    networkId: string;
    walletId: string;
    limit: number;
    offset?: number;
  }): Promise<ApiError | AxiosResponse<WalletAssetsResponse>> {
    return ApiService.apiGet(
      `/private/networks/${networkId}/wallets/${walletId}/assets`,
      { params: { limit, offset } },
    );
  }

  /**
   * Get wallets list.
   *
   * @author Ihar Kazlouski
   * @return {Promise<WalletResponse | ApiError>} wallets response promise.
   */
  async getWalletsList (networkId: string): Promise<WalletResponse | ApiError> {
    const dispatch: AsyncDispatch<WalletResponse | ApiError> = storeDispatch;

    return dispatch(actions.fetchWalletsRequest(networkId));
  }

  async apiWalletCreatePost (
    payload: CreateWalletRequestPayload,
  ): Promise<ApiError | AxiosResponse<WalletCreateResponse>> {
    return ApiService.apiPost(
      `/private/networks/${payload.networkId}/wallets`,
      { name: payload.name },
    );
  }

  /**
   * Delete wallet.
   *
   * @author Ihar Kazlouski
   * @return {Promise<WalletCreateResponse | ApiError>} wallets response promise.
   */
  async createWallet (
    networkId: string,
    name: string,
  ): Promise<WalletCreateResponse | ApiError> {
    const dispatch: AsyncDispatch<WalletCreateResponse | ApiError> =
      storeDispatch;

    return dispatch(actions.createWalletRequest({ networkId, name }));
  }

  async apiWalletDelete (
    payload: DeleteWalletRequestPayload,
  ): Promise<ApiError | AxiosResponse<void>> {
    return ApiService.apiDelete(
      `/private/networks/${payload.networkId}/wallets/${payload.walletId}`,
    );
  }

  /**
   * Delete wallet.
   *
   * @author Ihar Kazlouski
   * @return {Promise<WalletCreateResponse | ApiError>} wallets response promise.
   */
  async deleteWallet (
    networkId: string,
    walletId: string,
  ): Promise<void | ApiError> {
    const dispatch: AsyncDispatch<void | ApiError> = storeDispatch;

    return dispatch(actions.deleteWalletRequest({ networkId, walletId }));
  }

  async apiWalletUpdatePatch (
    payload: UpdateWalletRequestPayload,
  ): Promise<ApiError | AxiosResponse<WalletUpdateResponse>> {
    return ApiService.apiPatch(
      `/private/networks/${payload.networkId}/wallets/${payload.walletId}`,
      { name: payload.name },
    );
  }

  /**
   * Update wallet.
   *
   * @author Ihar Kazlouski
   * @return {Promise<WalletUpdateResponse | ApiError>} wallets response promise.
   */
  async updateWallet (
    payload: UpdateWalletRequestPayload,
  ): Promise<WalletUpdateResponse | ApiError> {
    const dispatch: AsyncDispatch<WalletUpdateResponse | ApiError> =
      storeDispatch;

    return dispatch(
      actions.updateWalletRequest({
        networkId: payload.networkId,
        name:      payload.name,
        walletId:  payload.walletId,
      }),
    );
  }
}

const WalletService = new WalletServiceClass();

export { WalletService };
