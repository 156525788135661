import React, { useCallback, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { MutableState, Tools } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { AnimatePresence, motion } from 'framer-motion';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { OrderMarketCapSide } from '@enums';

import {
  Button,
  CheckboxField,
  Form,
  FormField,
  Icon,
  SelectField,
  Switch,
  TextField,
} from '@elements';

import chevronDownIcon from '@assets/images/chevron-down.svg';
import gearIcon from '@assets/images/gear.svg';
import plusIcon from '@assets/images/plus.svg';
import trashIcon from '@assets/images/trash.svg';

import { getTradingMarketBuySchema } from './trading-limit-buy-form.schema';
import {
  TradingLimitBuyFormProps,
  TradingLimitBuyValues,
} from './trading-limit-buy-form.types';

export const TradingLimitBuyForm: React.FC<TradingLimitBuyFormProps> = ({
  onSubmit,
  isSettings,
  initials,
}) => {
  const { t } = useTranslation();

  const initialValues: TradingLimitBuyValues = useMemo(
    () => ({
      amount:     '',
      conditions: {
        change_market_cup_percent:   '',
        change_market_cup_direction: null,
      },
      stop_loss: {
        enabled:              false,
        price_change_percent: '',
        quantity_percent:     '',
      },
      take_profit_list: [
        {
          enabled:              false,
          price_change_percent: '',
          quantity_percent:     '',
        },
      ],
      extra_settings: {
        anti_mev_enabled:        false,
        anti_rug_enabled:        false,
        auto_approve_enabled:    false,
        max_gas_limit:           '',
        extra_gas_size:          '',
        slippage_percent:        '',
        bribe:                   '',
        validity_period_enabled: false,
        expiration_time_minutes: '',
      },
    }),
    [],
  );

  const handleSubmitForm = useCallback(
    async (values: TradingLimitBuyValues): Promise<void> => {
      await onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Form<TradingLimitBuyValues>
      onSubmit={handleSubmitForm}
      subscription={{
        submitting: true,
        invalid:    true,
        values:     true,
      }}
      initialValues={initials || initialValues}
      mutators={{
        ...arrayMutators,
        toggleTakeProfitList: (
          [flag]: boolean[],
          state: MutableState<TradingLimitBuyValues>,
          { changeValue }: Tools<TradingLimitBuyValues>,
        ): void => {
          changeValue(
            state,
            'take_profit_list',
            (value: TradingLimitBuyValues['take_profit_list']) => {
              return value.map((takeProfitItem) => ({
                ...takeProfitItem,
                enabled: flag,
              }));
            },
          );
        },
      }}
      validateSchema={getTradingMarketBuySchema(t)}
      render={({
        form,
        submitting,
        invalid,
        values,
        handleSubmit,
      }: FormRenderProps<TradingLimitBuyValues>): JSX.Element => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <Stack component='form' spacing={2} onSubmit={handleSubmit}>
          <FormField
            type='number'
            name='amount'
            component={TextField}
            label={t('forms.trading.labels.amount')}
            fullWidth
          />

          <Stack spacing={1} direction='row'>
            <FormField
              name='conditions.change_market_cup_direction'
              component={SelectField}
              options={Object.values(OrderMarketCapSide).map((value) => ({
                label: t(`forms.trading.marketCapDirectionOptions.${value}`),
                value,
              }))}
              label={t('forms.trading.labels.changeMarketCapDirection')}
              fullWidth
            />

            <FormField
              type='number'
              name='conditions.change_market_cup_percent'
              component={TextField}
              label={t('forms.trading.labels.changeMarketCapPercent')}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <FormField
              fullWidth
              type='checkbox'
              component={Switch}
              name='stop_loss.enabled'
              label={t('forms.trading.labels.stopLoss')}
            />

            <AnimatePresence initial={false}>
              {values.stop_loss.enabled && (
                <Stack
                  direction='row'
                  spacing={1}
                  component={motion.div}
                  variants={{
                    hide: { opacity: 0, height: 0 },
                    show: { opacity: 1, height: 'auto' },
                  }}
                  initial='hide'
                  animate='show'
                  exit='hide'
                >
                  <FormField
                    type='number'
                    name='stop_loss.price_change_percent'
                    component={TextField}
                    label={t('forms.trading.labels.priceChangePercent')}
                    disabled={!values.stop_loss.enabled}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='stop_loss.quantity_percent'
                    component={TextField}
                    label={t('forms.trading.labels.quantityPercent')}
                    disabled={!values.stop_loss.enabled}
                    fullWidth
                  />
                </Stack>
              )}
            </AnimatePresence>
          </Stack>

          <Stack spacing={1}>
            <FormField
              fullWidth
              type='checkbox'
              component={Switch}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                form.mutators.toggleTakeProfitList(event.target.checked);
              }}
              name='take_profit_list.0.enabled'
              label={t('forms.trading.labels.takeProfit')}
            />

            <FieldArray name='take_profit_list'>
              {({ fields }): React.ReactNode => (
                <AnimatePresence initial={false}>
                  {values.take_profit_list[0].enabled && (
                    <Stack
                      spacing={2}
                      component={motion.div}
                      variants={{
                        hide: { opacity: 0, height: 0 },
                        show: { opacity: 1, height: 'auto' },
                      }}
                      initial='hide'
                      animate='show'
                      exit='hide'
                    >
                      <Stack spacing={1}>
                        <AnimatePresence initial={false}>
                          {fields.map((name, index) => (
                            <Stack
                              spacing={1}
                              key={name}
                              component={motion.div}
                              variants={{
                                show: { opacity: 1, height: 'auto' },
                                hide: { opacity: 0, height: 0 },
                              }}
                              initial='hide'
                              animate='show'
                              exit='hide'
                            >
                              <Stack direction='row' spacing={1}>
                                <FormField
                                  type='number'
                                  name={`${name}.price_change_percent`}
                                  component={TextField}
                                  label={t(
                                    'forms.trading.labels.priceChangePercent',
                                  )}
                                  disabled={!values.take_profit_list[0].enabled}
                                  fullWidth
                                />

                                <FormField
                                  type='number'
                                  name={`${name}.quantity_percent`}
                                  component={TextField}
                                  label={t(
                                    'forms.trading.labels.quantityPercent',
                                  )}
                                  disabled={!values.take_profit_list[0].enabled}
                                  fullWidth
                                />
                              </Stack>

                              {index > 0 && (
                                <Button
                                  onClick={(): void => {
                                    fields.remove(index);
                                  }}
                                  disabled={!values.take_profit_list[0].enabled}
                                  variant='contained'
                                  color='white8'
                                >
                                  <Stack
                                    direction='row'
                                    spacing={1.25}
                                    alignItems='center'
                                  >
                                    <Icon
                                      src={trashIcon}
                                      width={18}
                                      height={18}
                                    />
                                    <span>
                                      {t('components.buttons.remove')}
                                    </span>
                                  </Stack>
                                </Button>
                              )}
                            </Stack>
                          ))}
                        </AnimatePresence>
                      </Stack>
                      <Button
                        onClick={(): void => {
                          fields.push({
                            enabled:              true,
                            quantity_percent:     '',
                            price_change_percent: '',
                          });
                        }}
                        disabled={!values.take_profit_list[0].enabled}
                        variant='contained'
                        color='white8'
                      >
                        <Stack
                          direction='row'
                          spacing={1.25}
                          alignItems='center'
                        >
                          <Icon src={plusIcon} width={18} height={18} />
                          <span>{t('forms.trading.addTakeProfit')}</span>
                        </Stack>
                      </Button>
                    </Stack>
                  )}
                </AnimatePresence>
              )}
            </FieldArray>
          </Stack>

          <Accordion
            disableGutters
            sx={{
              padding:    0,
              background: 'transparent',
              '&:before': {
                display: 'none',
              },
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              disableRipple
              expandIcon={
                <Icon
                  src={chevronDownIcon}
                  width={20}
                  height={20}
                  color='white'
                />
              }
            >
              <Stack direction='row' spacing={1}>
                <Icon src={gearIcon} width={18} height={18} color='white' />
                <Typography variant='body2' color='white'>
                  {t('forms.trading.settings')}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='extra_settings.anti_mev_enabled'
                      title={
                        <Typography variant='body2' color='white'>
                          {t('forms.trading.labels.antiMev')}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='extra_settings.auto_approve_enabled'
                      title={
                        <Typography variant='body2' color='white'>
                          {t('forms.trading.labels.autoApprove')}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='extra_settings.anti_rug_enabled'
                      title={
                        <Typography variant='body2' color='white'>
                          {t('forms.trading.labels.antiRug')}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                <FormField
                  type='number'
                  name='extra_settings.max_gas_limit'
                  component={TextField}
                  label={t('forms.trading.labels.maxGasLimit')}
                  fullWidth
                />

                <FormField
                  type='number'
                  name='extra_settings.extra_gas_size'
                  component={TextField}
                  label={t('forms.trading.labels.extraGasSize')}
                  fullWidth
                />

                <Stack direction='row' spacing={1}>
                  <FormField
                    type='number'
                    name='extra_settings.bribe'
                    component={TextField}
                    label={t('forms.trading.labels.bribe')}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='extra_settings.slippage_percent'
                    component={TextField}
                    label={t('forms.trading.labels.slippagePercent')}
                    fullWidth
                  />
                </Stack>

                <Stack spacing={1}>
                  <FormField
                    fullWidth
                    type='checkbox'
                    component={Switch}
                    name='extra_settings.validity_period_enabled'
                    label={t('forms.trading.labels.validityPeriodEnabled')}
                  />

                  <FormField
                    type='number'
                    name='extra_settings.expiration_time_minutes'
                    component={TextField}
                    label={t('forms.trading.labels.expirationTimeMinutes')}
                    disabled={!values.extra_settings.validity_period_enabled}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          {isSettings ? (
            <>
              <Button
                type='submit'
                disabled={submitting || invalid}
                variant='contained'
                color='white'
              >
                {t('modals.buttons.save')}
              </Button>
              <Button
                variant='outlined'
                onClick={(): void => form.reset(initialValues)}
              >
                Сбросить
              </Button>
            </>
          ) : (
            <Button
              type='submit'
              disabled={submitting || invalid}
              variant='contained'
              color='mediumSeaGreen'
            >
              {t('pages.terminal.form.side.buy')}
            </Button>
          )}
        </Stack>
      )}
    />
  );
};
