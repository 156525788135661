import * as React from 'react';

import { SvgIcon } from '@mui/material';

import { IconComponentProps } from '@elements/icon/icon.types';

import { pxToRem } from '@themes';
import { StyledIcon } from './icon.styles';

/**
 * Icon component.
 *
 * @author Ihar Kazlouski
 * @function Icon
 * @category components
 * @return {FC} icon component.
 */
export const Icon = ({
  className,
  ...props
}: IconComponentProps): JSX.Element => {
  return (
    <SvgIcon
      component='i'
      inheritViewBox
      className={className}
      sx={{
        width:   props.width ? pxToRem(+props.width) : 'auto',
        height:  props.height ? pxToRem(+props.height) : 'auto',
        display: 'flex',
      }}
    >
      <StyledIcon {...props} />
    </SvgIcon>
  );
};
