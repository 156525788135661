import React, { cloneElement, FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';

import { getPopupSelector } from '@store/popups/popups.selectors';

import { PopupsService } from '@services';

import { popupVariants } from '@components/popup/popup.variants';
import { PopupProps } from '@elements/popup';

import { StyledBackDrop, StyledDialog, StyledPaper } from './popup.styles';

import { PopupComponentProps } from './popup.types';

/**
 * Popup component.
 *
 * @author Ihar Kazlouski
 * @function PopupComponent
 * @category components
 * @param {string} popupId popup id.
 * @return {FC} popup component.
 */
const PopupComponent: FC<PopupComponentProps> = ({ popupId }) => {
  const popUp = useSelector(getPopupSelector(popupId));
  const component: FC = popupVariants[popupId];

  /**
   * Reject popup.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const rejectPopup = useCallback((): void => {
    PopupsService.closeWithAnswer(popupId, { rejected: true });
  }, [popupId]);

  /**
   * Accept popup.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const acceptPopup = useCallback((): void => {
    PopupsService.closeWithAnswer(popupId, { accepted: true });
  }, [popupId]);

  /**
   * Close popup.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClose = useCallback((): void => {
    rejectPopup();
  }, [rejectPopup]);

  const initialChildProps = useMemo(
    () => ({
      handleClose,
      acceptPopup,
      rejectPopup,
      popupId,
      popUp,
    }),
    [acceptPopup, handleClose, popupId, rejectPopup, popUp],
  );

  const popupChild = component ? component(initialChildProps) : null;

  const children = useMemo(
    () => popupChild && cloneElement<PopupProps>(popupChild, initialChildProps),
    [initialChildProps, popupChild],
  );

  const popupProps = children?.props
    ? {
        maxWidth:  children.props.maxWidth,
        fullWidth: children.props.fullWidth,
      }
    : {};

  return (
    <>
      <StyledDialog
        aria-labelledby={popupId}
        open={popUp && popUp.isOpen ? popUp.isOpen : false}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={StyledBackDrop}
        BackdropProps={{
          timeout: 500,
        }}
        PaperComponent={StyledPaper}
        PaperProps={
          {
            elevation: 0,
            width:     children?.props?.width,
          } as PaperProps<typeof Paper, { width: number }>
        }
        {...popupProps}
      >
        {children}
      </StyledDialog>
    </>
  );
};

export { PopupComponent };
