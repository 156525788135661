import React, {
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Backdrop, DialogProps } from '@mui/material';

import {
  getModalDataSelector,
  getModalOpenStateSelector,
} from '@store/modals/modals.selectors';

import { ModalsService } from '@services';

import { modalVariants } from '@components/modal/modal.variants';
import { ModalProps } from '@elements/modal/';

import { StyledDialog, StyledPaper } from './modal.styles';

import { ModalComponentProps } from './modal.types';

/**
 * Modal component.
 *
 * @author Ihar Kazlouski
 * @function ModalComponent
 * @category components
 * @param {string} modalId modal id.
 * @return {FC} modal component.
 */
const ModalComponent: FC<ModalComponentProps> = ({ modalId, ...pr }) => {
  const isOpen = useSelector(getModalOpenStateSelector(modalId));
  const data = useSelector(getModalDataSelector(modalId));

  const component: FC = modalVariants[modalId];

  /**
   * Reject modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const rejectModal = useCallback((): void => {
    ModalsService.closeWithAnswer(modalId, { rejected: true });
  }, [modalId]);

  /**
   * Accept modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const acceptModal = useCallback((): void => {
    ModalsService.closeWithAnswer(modalId, { accepted: true });
  }, [modalId]);

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @return {void}
   */
  const handleClose = useCallback((): void => {
    rejectModal();
  }, [rejectModal]);

  const initialChildProps = useMemo(
    () => ({
      handleClose,
      acceptModal,
      rejectModal,
      modalId,
      data,
    }),
    [acceptModal, handleClose, modalId, rejectModal, data],
  );

  const modalChild = component ? component(initialChildProps) : null;

  const children = useMemo(
    () => modalChild && cloneElement<ModalProps>(modalChild, initialChildProps),
    [initialChildProps, modalChild],
  );

  const childrenElement =
    children?.props && (children.props.children as ReactElement);

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const maxWidth =
    childrenElement &&
    (childrenElement.props?.maxWidth as DialogProps['maxWidth']);
  const fullWidth =
    childrenElement &&
    (childrenElement.props?.fullWidth as DialogProps['fullScreen']);
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */

  const modalProps = children?.props
    ? {
        maxWidth:  children.props.maxWidth || maxWidth,
        fullWidth: children.props.fullWidth || fullWidth,
      }
    : {};

  return (
    <>
      <StyledDialog
        aria-labelledby={modalId}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        scroll='body'
        PaperComponent={StyledPaper}
        PaperProps={{
          elevation: 0,
        }}
        {...modalProps}
      >
        {children}
      </StyledDialog>
    </>
  );
};

export { ModalComponent };
