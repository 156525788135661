import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ConstantsUtil } from '@utils';
import { ApiResponseStatusText } from '@enums';
/**
 * get Error popup message helper.
 *
 * @author Ihar Kazlouski
 * @function getPopupErrorMessage
 * @category helpers
 * @param {ApiErrorObject} object error
 * @return {ApiErrorObject} new error.
 */
var getPopupErrorMessage = function getPopupErrorMessage(error) {
  var errorObject = _objectSpread({}, error);
  switch (error.name) {
    case ApiResponseStatusText.invalid_grant:
      errorObject.name = ConstantsUtil.errors.INVALID_GRANT;
      errorObject.message = ConstantsUtil.errors.INVALID_GRANT_MESSAGE;
      return errorObject;
    case ApiResponseStatusText.insufficient_scope:
      errorObject.name = ConstantsUtil.errors.INSUFFICIENT_SCOPE;
      errorObject.message = ConstantsUtil.errors.INSUFFICIENT_SCOPE_MESSAGE;
      return errorObject;
    case ApiResponseStatusText.invalid_request:
      errorObject.name = ConstantsUtil.errors.INVALID_REQUEST;
      errorObject.message = ConstantsUtil.errors.INVALID_REQUEST_MESSAGE;
      return errorObject;
    case ApiResponseStatusText.unauthorized:
    case ApiResponseStatusText.invalid_token:
      errorObject.name = ConstantsUtil.errors.INVALID_TOKEN;
      errorObject.message = ConstantsUtil.errors.INVALID_TOKEN_MESSAGE;
      return errorObject;
    case ApiResponseStatusText.server_error:
      errorObject.name = ConstantsUtil.errors.SERVER_ERROR;
      errorObject.message = ConstantsUtil.errors.SERVER_ERROR_MESSAGE;
      return errorObject;
    default:
      return errorObject;
  }
};
export { getPopupErrorMessage };