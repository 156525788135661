export enum Environment {
  Dev = 'dev',
  Test = 'test',
  Preprod = 'preprod',
  Prod = 'prod',
}

export enum Feature {
  ServiceWorker = 'serviceWorker',
  MockAPI = 'mockAPI',
}
