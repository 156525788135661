import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { FETCH_PAIRS_FAILURE, FETCH_PAIRS_REQUEST, FETCH_PAIRS_SUCCESS, FETCH_TRANSACTIONS_FAILURE, FETCH_TRANSACTIONS_REQUEST, FETCH_TRANSACTIONS_SUCCESS } from './statistic.action.types';
var initialState = {
  pairsCount: null,
  pairsPending: false,
  transactionsPending: false,
  pairs: null,
  error: null,
  transactions: null,
  transactionsCount: null
};

/**
 * Statistic reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Statistic
 * @param {NetworksState} state state.
 * @param {ActionsTypes} action action.
 * @return {NetworksState} Statistic state.
 */
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case FETCH_PAIRS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.pairsPending = true;
        });
      }
    case FETCH_PAIRS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.pairsPending = false;
          draft.pairs = action.payload.pairs.results;
          draft.pairsCount = action.payload.pairs.count;
          draft.error = null;
        });
      }
    case FETCH_PAIRS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.pairsPending = false;
          draft.pairs = null;
          draft.pairsCount = null;
          draft.error = action.payload.error;
        });
      }
    case FETCH_TRANSACTIONS_REQUEST:
      {
        return produce(state, function (draft) {
          draft.transactionsPending = true;
        });
      }
    case FETCH_TRANSACTIONS_SUCCESS:
      {
        return produce(state, function (draft) {
          draft.transactionsPending = false;
          draft.transactions = action.payload.transactions.results;
          draft.transactionsCount = action.payload.transactions.count;
          draft.error = null;
        });
      }
    case FETCH_TRANSACTIONS_FAILURE:
      {
        return produce(state, function (draft) {
          draft.transactionsPending = false;
          draft.transactions = null;
          draft.transactionsCount = null;
          draft.error = action.payload.error;
        });
      }
    default:
      {
        return _objectSpread({}, state);
      }
  }
});