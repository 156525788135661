import { Pagination, paginationItemClasses, styled } from '@mui/material';

export const StyledPagination = styled(Pagination)(({ theme }) => {
  return {
    padding:      theme.spacing(0, 0.5),
    background:   theme.palette.white8.main,
    borderRadius: theme.spacing(10),

    [`& .${paginationItemClasses.root}`]: {
      color: theme.palette.white.main,
    },
  };
});
