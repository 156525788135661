import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DefaultTab,
  TerminalTradingSettingsTab,
  TradingSettingsTab,
} from '@popups/profile/components';
import { ProfileHelperType } from '@popups/profile/profile.types';

import { Box, IconButton, Typography } from '@mui/material';

import { PopupsService } from '@services';

import { Popups, ProfileTabs } from '@enums';

import { Icon, Popup, PopupProps } from '@elements';

import arrow from '@images/back.svg';
import close from '@images/close.svg';

/**
 * Profile component.
 *
 * @author Ihar Kazlouski
 * @function ProfileComponent
 * @category components
 * @param {PopupProps} popup props.
 * @return {FC} popup component.
 */
const ProfileComponent: FC = ({ popUp }: PopupProps): JSX.Element => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(ProfileTabs.Default);
  const isDefault = currentTab === ProfileTabs.Default;

  useEffect(() => {
    if (popUp?.isOpen) {
      setCurrentTab(ProfileTabs.Default);
    }
  }, [popUp?.isOpen]);

  const handleClick = (tab: ProfileHelperType) => (): void => {
    if (tab.id === ProfileTabs.Logout) {
      localStorage.clear();
      sessionStorage.clear();
      PopupsService.close(Popups.Profile);
      navigate(0);
    } else {
      setCurrentTab(tab.id);
    }
  };
  const getTemplate = (): ReactNode => {
    switch (currentTab) {
      case ProfileTabs.TradeSettings: {
        return <TradingSettingsTab handleClick={handleClick} />;
      }

      case ProfileTabs.TerminalTradingSettings: {
        return <TerminalTradingSettingsTab />;
      }

      default: {
        return <DefaultTab handleClick={handleClick} />;
      }
    }
  };

  const getPrevTab = (): ProfileTabs => {
    switch (currentTab) {
      case ProfileTabs.TradeSettings: {
        return ProfileTabs.Default;
      }

      case ProfileTabs.TerminalTradingSettings: {
        return ProfileTabs.TradeSettings;
      }

      default: {
        return ProfileTabs.Default;
      }
    }
  };

  return (
    <Popup width={400} fullWidth popUp={popUp}>
      <Popup.Title>
        {isDefault ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            paddingX={4}
            width='100%'
          >
            <Box />
            <Box />
            <IconButton
              onClick={(): void => PopupsService.close(Popups.Profile)}
              color='white'
              size='small'
            >
              <Icon src={close} width={20} height={20} />
            </IconButton>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' width='100%' paddingX={1}>
            <IconButton onClick={(): void => setCurrentTab(getPrevTab())}>
              <Icon src={arrow} />
              <Typography variant='body1' color='white'>
                Назад
              </Typography>
            </IconButton>
            <Typography
              variant='body1'
              color='white'
              textAlign='center'
              maxWidth='170px'
              ml={2.5}
              mr={7.5}
            >
              {currentTab}
            </Typography>
            <IconButton
              onClick={(): void => PopupsService.close(Popups.Profile)}
              color='white'
              size='small'
            >
              <Icon src={close} width={20} height={20} />
            </IconButton>
          </Box>
        )}
      </Popup.Title>
      <Popup.Content>
        <>{getTemplate()}</>
      </Popup.Content>
    </Popup>
  );
};

export { ProfileComponent };
