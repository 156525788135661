import React, { useCallback, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import {
  Button,
  CheckboxField,
  Form,
  FormField,
  Icon,
  TextField,
} from '@elements';

import chevronDownIcon from '@assets/images/chevron-down.svg';
import gearIcon from '@assets/images/gear.svg';

import { getTradingMarketSellSchema } from './trading-market-sell-form.schema';
import {
  TradingMarketSellFormProps,
  TradingMarketSellValues,
} from './trading-market-sell-form.types';

export const TradingMarketSellForm: React.FC<TradingMarketSellFormProps> = ({
  onSubmit,
  isSettings,
  initials,
}) => {
  const { t } = useTranslation();

  const initialValues: TradingMarketSellValues = useMemo(
    () => ({
      amount:           '',
      anti_mev_enabled: false,
      anti_rug_enabled: false,
      min_gas_limit:    '',
      max_gas_limit:    '',
      extra_gas_size:   '',
      slippage_percent: '',
      bribe:            '',
    }),
    [],
  );

  const handleSubmitForm = useCallback(
    async (values: TradingMarketSellValues): Promise<void> => {
      await onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Form<TradingMarketSellValues>
      onSubmit={handleSubmitForm}
      subscription={{
        submitting: true,
        invalid:    true,
        values:     true,
      }}
      initialValues={initials || initialValues}
      validateSchema={getTradingMarketSellSchema(t)}
      render={({
        submitting,
        invalid,
        values,
        handleSubmit,
        form,
      }: FormRenderProps<TradingMarketSellValues>): JSX.Element => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <Stack component='form' spacing={2} onSubmit={handleSubmit}>
          <FormField
            type='number'
            name='amount'
            component={TextField}
            label={t('forms.trading.labels.amount')}
            fullWidth
          />

          <Accordion
            disableGutters
            sx={{
              padding:    0,
              background: 'transparent',
              '&:before': {
                display: 'none',
              },
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              disableRipple
              expandIcon={
                <Icon
                  src={chevronDownIcon}
                  width={20}
                  height={20}
                  color='white'
                />
              }
            >
              <Stack direction='row' spacing={1}>
                <Icon src={gearIcon} width={18} height={18} color='white' />
                <Typography variant='body2' color='white'>
                  {t('forms.trading.settings')}
                </Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='anti_mev_enabled'
                      title={
                        <Typography
                          component='span'
                          variant='body2'
                          color='white'
                        >
                          {t('forms.trading.labels.antiMev')}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormField
                      fullWidth
                      type='checkbox'
                      component={CheckboxField}
                      name='anti_rug_enabled'
                      title={
                        <Typography
                          component='span'
                          variant='body2'
                          color='white'
                        >
                          {t('forms.trading.labels.antiRug')}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                <FormField
                  type='number'
                  name='min_gas_limit'
                  component={TextField}
                  label={t('forms.trading.labels.minGasLimit')}
                  fullWidth
                />

                <FormField
                  type='number'
                  name='max_gas_limit'
                  component={TextField}
                  label={t('forms.trading.labels.maxGasLimit')}
                  fullWidth
                />

                <FormField
                  type='number'
                  name='extra_gas_size'
                  component={TextField}
                  label={t('forms.trading.labels.extraGasSize')}
                  fullWidth
                />

                <Stack direction='row' spacing={1}>
                  <FormField
                    type='number'
                    name='bribe'
                    component={TextField}
                    label={t('forms.trading.labels.bribe')}
                    fullWidth
                  />

                  <FormField
                    type='number'
                    name='slippage_percent'
                    component={TextField}
                    label={t('forms.trading.labels.slippagePercent')}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {isSettings ? (
            <>
              <Button
                type='submit'
                disabled={submitting || invalid}
                variant='contained'
                color='white'
              >
                {t('modals.buttons.save')}
              </Button>
              <Button
                variant='outlined'
                onClick={(): void => form.reset(initialValues)}
              >
                Сбросить
              </Button>
            </>
          ) : (
            <Button
              type='submit'
              disabled={submitting || invalid}
              variant='contained'
              color='lightCoral'
            >
              {t('pages.terminal.form.side.sell')}
            </Button>
          )}
        </Stack>
      )}
    />
  );
};
