import { TransactionDirection, TransactionType } from '@enums';

export const TransactionTypesConfig = {
  [TransactionType.Deposit]: {
    i18nLabelKey: 'common.transactionType.depoist',
    direction:    TransactionDirection.In,
  },
  [TransactionType.Withdraw]: {
    i18nLabelKey: 'common.transactionType.withdraw',
    direction:    TransactionDirection.Out,
  },
  [TransactionType.AddLiquidity]: {
    i18nLabelKey: 'common.transactionType.addLiquidity',
    direction:    TransactionDirection.In,
  },
  [TransactionType.RemoveLiquidity]: {
    i18nLabelKey: 'common.transactionType.removeLiquidity',
    direction:    TransactionDirection.Out,
  },
  [TransactionType.SwapIn]: {
    i18nLabelKey: 'common.transactionType.swapIn',
    direction:    TransactionDirection.In,
  },
  [TransactionType.SwapOut]: {
    i18nLabelKey: 'common.transactionType.swapOut',
    direction:    TransactionDirection.Out,
  },
};

export const getTrasactionTypeInfo = (
  transactionType: TransactionType,
): { i18nLabelKey: string; direction: TransactionDirection } => {
  return TransactionTypesConfig[transactionType];
};
