import { combineReducers } from 'redux';

import authReducer from './auth/auth.reducer';
import formReducer from './forms/forms.reducers';
import modalsReducer from './modals/modals.reducers';
import networkReducer from './network/network.reducer';
import popupsReducer from './popups/popups.reducers';
import snackbarsReducer from './snackbars/snackbars.reducers';
import statisticReducer from './statistic/statistic.reducer';
import terminalReducer from './terminal/terminal.reducer';
import themeReducer from './theme/theme.reducer';
import walletReducer from './wallet/wallet.reducer';

const rootReducer = combineReducers({
  theme:     themeReducer,
  modals:    modalsReducer,
  auth:      authReducer,
  forms:     formReducer,
  popups:    popupsReducer,
  snackbars: snackbarsReducer,
  wallet:    walletReducer,
  network:   networkReducer,
  statistic: statisticReducer,
  terminal:  terminalReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
