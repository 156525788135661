import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';

import { ConstantsUtil } from '@utils';

import {
  CLOSE_MODAL,
  CLOSING_MODAL_WITH_ANSWER,
  INIT_CLOSE_MODAL,
  INIT_MODAL_CLOSING_WITH_ANSWER,
  MODAL_INIT,
  SET_MODAL,
} from './modals.action-types';
import {
  ModalClosePayload,
  ModalCloseWithAnswerPayload,
  ModalPayload,
} from './modals.types';

export const actions = {
  /**
   * Init modal closing.
   *
   * @author Ihar Kazlouski
   * @function modalInitClosing
   * @category Actions
   * @subcategory Modals
   * @param {ModalClosePayload} payload payload.
   */
  modalInitClosing: (payload: ModalClosePayload) =>
    ({
      type: INIT_CLOSE_MODAL,
      payload,
    } as const),

  /**
   * Close modal.
   *
   * @author Ihar Kazlouski
   * @function modalClose
   * @category Actions
   * @subcategory Modals
   * @param {ModalClosePayload} payload payload.
   */
  modalClose: (payload: ModalClosePayload) =>
    ({
      type: CLOSE_MODAL,
      payload,
    } as const),

  /**
   * Init modal opening.
   *
   * @author Ihar Kazlouski
   * @function modalInit
   * @category Actions
   * @subcategory Modals
   * @param {ModalPayload} payload payload.
   */
  modalInit: (payload: ModalPayload) =>
    ({
      type:              MODAL_INIT,
      payload,
      [WAIT_FOR_ACTION]: `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      [ERROR_ACTION]:    `${MODAL_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}`,
    } as const),

  /**
   * Set necessary modal.
   *
   * @author Ihar Kazlouski
   * @function setModal
   * @category Actions
   * @subcategory Modals
   * @param {ModalPayload} payload payload.
   */
  setModal: (payload: ModalPayload) =>
    ({
      type: SET_MODAL,
      payload,
    } as const),

  /**
   * Init closing with answer.
   *
   * @author Ihar Kazlouski
   * @function modalInitClosingWithAnswer
   * @category Actions
   * @subcategory Modals
   * @param {ModalCloseWithAnswerPayload} payload payload.
   */
  modalInitClosingWithAnswer: (payload: ModalCloseWithAnswerPayload) =>
    ({
      type: INIT_MODAL_CLOSING_WITH_ANSWER,
      payload,
    } as const),

  /**
   * Close modal with answer.
   *
   * @author Ihar Kazlouski
   * @function closeWithAnswer
   * @category Actions
   * @subcategory Modals
   * @param {ModalCloseWithAnswerPayload} payload payload.
   */
  closeWithAnswer: (payload: ModalCloseWithAnswerPayload) =>
    ({
      type: CLOSING_MODAL_WITH_ANSWER,
      payload,
    } as const),
};
