import {
  buttonClasses,
  ButtonGroup as MuiButtonGroup,
  buttonGroupClasses,
  styled,
} from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { colors, pxToRem } from '@themes';

import { ButtonGroupComponentProps } from './button-group.types';

const StyledButtonGroup = styled(MuiButtonGroup)<ButtonGroupComponentProps>(
  ({ theme }) => {
    const firstButton: OverridesStyleRules = {
      borderTopLeftRadius:                pxToRem(100),
      borderBottomLeftRadius:             pxToRem(100),
      padding:                            `${pxToRem(12)} ${pxToRem(24)}`,
      backgroundColor:                    theme.colors.white8,
      border:                             'none',
      [theme.breakpoints.down('tablet')]: {
        maxWidth: pxToRem(108),
      },

      ':hover': {
        backgroundColor: colors.whiteSmoke,
        color:           colors.mirage,
      },
      ':focus': {
        backgroundColor: colors.white,
        color:           colors.mirage,
      },
      ':disabled': {
        backgroundColor: theme.colors.white8,
        color:           theme.colors.white50,
      },
    };

    const middleButton: OverridesStyleRules = {
      padding:                            `${pxToRem(12)} ${pxToRem(24)}`,
      backgroundColor:                    theme.colors.white8,
      border:                             'none',
      [theme.breakpoints.down('tablet')]: {
        maxWidth: pxToRem(108),
      },

      ':hover': {
        backgroundColor: colors.whiteSmoke,
        color:           colors.mirage,
      },
      ':focus': {
        backgroundColor: colors.white,
        color:           colors.mirage,
      },
      ':disabled': {
        backgroundColor: theme.colors.white8,
        color:           theme.colors.white50,
      },
    };

    const lastButton: OverridesStyleRules = {
      borderTopRightRadius:               pxToRem(100),
      borderBottomRightRadius:            pxToRem(100),
      padding:                            `${pxToRem(12)} ${pxToRem(24)}`,
      backgroundColor:                    theme.colors.white8,
      border:                             'none',
      [theme.breakpoints.down('tablet')]: {
        maxWidth: pxToRem(108),
      },

      ':hover': {
        backgroundColor: colors.whiteSmoke,
        color:           colors.mirage,
      },
      ':focus': {
        backgroundColor: colors.white,
        color:           colors.mirage,
      },
      ':disabled': {
        backgroundColor: theme.colors.white8,
        color:           theme.colors.white50,
      },
    };

    const rootStyles: OverridesStyleRules = {
      padding:    pxToRem(4),
      background: 'none',
      border:     'none',
      gap:        pxToRem(3),

      [`.${buttonGroupClasses.firstButton}`]: firstButton,

      [`.${buttonGroupClasses.middleButton}`]: middleButton,

      [`.${buttonGroupClasses.lastButton}`]: lastButton,

      [`.${buttonGroupClasses.grouped}.${buttonClasses.text}:hover`]: {
        color: theme.colors.tuna,
      },
    };

    return rootStyles;
  },
);

export { StyledButtonGroup };
