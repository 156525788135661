import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

const StyledHeader = styled(Box)(({ theme }: { theme: DefaultTheme }) => ({
  display:        'flex',
  justifyContent: 'center',
}));

const StyledContent = styled(Box)(({ theme }: { theme: DefaultTheme }) => ({
  display:       'flex',
  alignItems:    'center',
  paddingBottom: theme.spacing(4),
}));

export { StyledContent, StyledHeader };
