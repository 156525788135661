import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:                        theme.spacing(4),
    borderRadius:                   theme.typography.pxToRem(12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2),
    },
  };

  return styles;
});

export { StyledModalContainer };
