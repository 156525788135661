import {
  inputAdornmentClasses,
  InputBase,
  inputBaseClasses,
  styled,
} from '@mui/material';

import { pxToRem } from '@themes';

export const StyledSearchBar = styled(InputBase)(({ theme }) => {
  return {
    padding:      theme.spacing(1.25, 2),
    background:   theme.palette.white8.main,
    borderRadius: theme.spacing(10),
    color:        theme.palette.white.main,
    fontWeight:   500,
    fontSize:     pxToRem(14),
    lineHeight:   pxToRem(17),
    transition:   'all 0.3s ease-in-out',

    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.white10.main}`,
    },

    [`&.${inputBaseClasses.focused}`]: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.white.main}`,
    },

    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(0),
    },

    [`& .${inputBaseClasses.input}::placeholder`]: {
      color:   theme.palette.white.main,
      opacity: 1,
    },

    [`& .${inputAdornmentClasses.root}`]: {
      color: theme.palette.white.main,
    },
  };
});
