import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';

import { ConstantsUtil } from '@utils';

import { PayloadAction } from '@types';

import {
  INIT_CLOSE_SNACKBAR,
  INIT_SNACKBAR_CLOSING_WITH_ANSWER,
  SNACKBAR_INIT,
} from './snackbars.action-types';
import { actions } from './snackbars.actions';
import {
  SnackbarClosePayload,
  SnackbarCloseWithAnswerPayload,
  SnackbarPayload,
} from './snackbars.types';

/**
 * Open snackbar saga worker.
 *
 * @author Ihar Kazlouski
 * @function snackbarOpenSagaWorker
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<PutEffect>} action data.
 */
function* snackbarOpenSagaWorker (
  action: PayloadAction<SnackbarPayload>,
): Generator<PutEffect> {
  yield put(
    actions.setSnackbar({
      snackbarId: action.payload.snackbarId,
      isOpen:     action.payload.isOpen,
      data:       action.payload.data,
    }),
  );
}

/**
 * Set new snackbar to saga watcher.
 *
 * @author Ihar Kazlouski
 * @function snackbarOpenSagaWatcher
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* snackbarOpenSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(SNACKBAR_INIT, snackbarOpenSagaWorker)]);
}

/**
 * Close snackbar saga worker.
 *
 * @author Ihar Kazlouski
 * @function snackbarCloseSagaWorker
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<PutEffect>} action data.
 */
function* snackbarCloseSagaWorker (
  action: PayloadAction<SnackbarClosePayload>,
): Generator<PutEffect> {
  yield put(
    actions.snackbarClose({
      snackbarId: action.payload.snackbarId,
    }),
  );

  yield put({
    type:    `${SNACKBAR_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
    payload: {
      snackbarId: action.payload.snackbarId,
    },
  });
}

/**
 * Close snackbar.
 *
 * @author Ihar Kazlouski
 * @function snackbarCloseSagaWatcher
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* snackbarCloseSagaWatcher (): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(INIT_CLOSE_SNACKBAR, snackbarCloseSagaWorker)]);
}

/**
 * Close snackbar with answer saga worker.
 *
 * @author Ihar Kazlouski
 * @function snackbarCloseWithAnswerSagaWorker
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<PutEffect>} action data.
 */
function* snackbarCloseWithAnswerSagaWorker (
  action: PayloadAction<SnackbarCloseWithAnswerPayload>,
): Generator<PutEffect> {
  yield put(
    actions.closeWithAnswer({
      snackbarId: action.payload.snackbarId,
      accepted:   action.payload.accepted,
      rejected:   action.payload.rejected,
    }),
  );

  if (action.payload.accepted) {
    yield put({
      type:    `${SNACKBAR_INIT}/${ConstantsUtil.actions.ASYNC_SUCCESS}`,
      payload: {
        snackbarId: action.payload.snackbarId,
        accepted:   action.payload.accepted,
        rejected:   action.payload.rejected,
      },
    });
  } else {
    yield put({
      type:  `${SNACKBAR_INIT}/${ConstantsUtil.actions.ASYNC_FAILED}`,
      error: {
        snackbarId: action.payload.snackbarId,
        accepted:   action.payload.accepted,
        rejected:   action.payload.rejected,
      },
    });
  }
}

/**
 * Close snackbar with answer.
 *
 * @author Ihar Kazlouski
 * @function snackbarCloseWithAnswerSagaWatcher
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
function* snackbarCloseWithAnswerSagaWatcher (): Generator<
AllEffect<ForkEffect>
> {
  yield all([
    takeLatest(
      INIT_SNACKBAR_CLOSING_WITH_ANSWER,
      snackbarCloseWithAnswerSagaWorker,
    ),
  ]);
}

/**
 * Snackbars saga.
 *
 * @author Ihar Kazlouski
 * @function snackbarsSaga
 * @category Sagas
 * @subcategory Snackbars
 * @return {Generator<AllEffect<ForkEffect>>} data.
 */
export default function* snackbarsSaga (): Generator<AllEffect<ForkEffect>> {
  yield all([
    fork(snackbarOpenSagaWatcher),
    fork(snackbarCloseSagaWatcher),
    fork(snackbarCloseWithAnswerSagaWatcher),
  ]);
}
