import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { NavLink } from 'react-router-dom';
import { AppBar, Box, Divider, IconButton, styled, Toolbar } from '@mui/material';
import { Button } from '@elements';
import { pxToRem } from '@themes';
var StyledAppBar = styled(AppBar)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    padding: theme.spacing(1, 0),
    borderBottom: "1px solid ".concat(theme.palette.white10.main)
  };
  return styles;
});
var StyledToolbar = styled(Toolbar)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    display: 'flex',
    minHeight: theme.spacing(7),
    width: '100%',
    gap: theme.spacing(2.5),
    justifyContent: 'space-between'
  };
  return styles;
});
var StyledLogo = styled('img')(function () {
  var styles = {
    height: pxToRem(40),
    width: 'auto'
  };
  return styles;
});
var StyledNavBar = styled(Box)(function () {
  var styles = _defineProperty({
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  }, "&::-webkit-scrollbar", {
    display: 'none'
  });
  return styles;
});
var StyledNavLink = styled(NavLink)(function (_ref3) {
  var theme = _ref3.theme,
    disabled = _ref3.disabled;
  var styles = _objectSpread({
    textDecoration: 'none',
    color: theme.palette.white.main,
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1.25),
    borderRadius: theme.spacing(4),
    transition: 'all 0.3s ease-in-out',
    '&.active': {
      color: theme.palette.blue.main,
      background: theme.palette.white8.main
    }
  }, disabled ? {
    pointerEvents: 'none',
    color: theme.palette.white50.main
  } : {});
  return styles;
});
var StyledIconsToolbar = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(4),
    background: theme.palette.white8.main
  };
  return styles;
});
var StyledIconButton = styled(IconButton)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    padding: theme.spacing(0),
    flexShrink: 0,
    color: 'inherit'
  };
  return styles;
});
var StyledDivider = styled(Divider)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    borderColor: theme.palette.white10.main
  };
  return styles;
});
var StyledAccountButton = styled(IconButton)(function (_ref7) {
  var theme = _ref7.theme;
  var styles = {
    fontSize: pxToRem(48),
    padding: theme.spacing(0)
  };
  return styles;
});
var StyledConnectButton = styled(Button)(function (_ref8) {
  var theme = _ref8.theme;
  var styles = {
    whiteSpace: 'nowrap'
  };
  return styles;
});
export { StyledAccountButton, StyledAppBar, StyledConnectButton, StyledDivider, StyledIconButton, StyledIconsToolbar, StyledLogo, StyledNavBar, StyledNavLink, StyledToolbar };