import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { produce } from 'immer';
import { CREATE_FORM_STATE, DELETE_FORM_STATE, UPDATE_FORM_STATE } from './forms.action-types';
var initialState = [];
var initialFormState = {
  dirty: false,
  dirtyFields: {},
  dirtyFieldsSinceLastSubmit: {},
  dirtySinceLastSubmit: false,
  errors: {},
  hasSubmitErrors: false,
  hasValidationErrors: false,
  invalid: false,
  modified: {},
  modifiedSinceLastSubmit: false,
  pristine: true,
  submitFailed: false,
  submitSucceeded: false,
  submitting: false,
  touched: {},
  valid: true,
  validating: false,
  values: {},
  visited: {}
};
/**
 * forms reducer.
 *
 * @author Ihar Kazlouski
 * @category Reducers
 * @subcategory Forms
 * @param {FormState} state state.
 * @param {ActionsTypes} action action.
 * @return {FormState[]} forms state.
 */
var formsReducer = function formsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case CREATE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });
        if (idx < 0) {
          draft.push(_objectSpread({
            formId: action.payload.formId
          }, initialFormState));
        }
      });
    case UPDATE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });
        if (idx >= 0) {
          draft[idx] = action.payload;
        } else {
          draft.push(action.payload);
        }
      });
    case DELETE_FORM_STATE:
      return produce(state, function (draft) {
        var idx = state.findIndex(function (form) {
          return form.formId === action.payload.formId;
        });
        if (idx >= 0) {
          draft.splice(idx, 1);
        }
      });
    default:
      return state;
  }
};
export default formsReducer;