import { Menu, menuClasses, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Button, Icon } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const StyledButton = styled(Button)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      padding: theme.spacing(0),
      gap:     theme.spacing(0.5),
    };

    return styles;
  },
);

export const StyledMenu = styled(Menu)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      [`& .${menuClasses.paper}`]: {
        background: theme.palette.mirage.main,
        color:      theme.palette.white.main,
      },
    };

    return styles;
  },
);

export const StyledChevronIcon = styled(Icon)<{ open: boolean }>(({ open }) => {
  const styles: OverridesStyleRules = {
    transition: 'all 0.3s ease-in-out',
    transform:  open ? 'rotate(180deg)' : undefined,
  };

  return styles;
});

export const StyledFlagIcon = styled(Icon)(() => {
  return {
    borderRadius: '50%',
    overflow:     'hidden',
  };
});
