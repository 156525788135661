import React from 'react';

import { LazyPage } from '@hocs';

import { Routes } from '@enums';
import { AppRoutes } from '@types';

const TerminalPage = LazyPage('terminal', 'TerminalPage');
const MainPage = LazyPage('main', 'MainPage');
const WalletActions = LazyPage('wallet-actions', 'WalletActionsPage');
const WalletPage = LazyPage('wallet', 'WalletPage');
const ExportPage = LazyPage('export', 'ExportPage');
const NewPairsPage = LazyPage('new-pairs', 'NewPairsPage');

const appPublicRoutes: AppRoutes[] = [
  {
    name:    Routes.Main,
    path:    Routes.Main,
    element: <MainPage />,
    index:   false,
    inline:  true,
  },
  {
    name:    Routes.Terminal,
    path:    Routes.Terminal,
    element: <TerminalPage />,
    index:   false,
    inline:  true,
  },
  {
    name:    Routes.Wallet,
    path:    Routes.Wallet,
    element: <WalletPage />,
    index:   false,
    inline:  true,
  },
  {
    name:    Routes.WalletActions,
    path:    Routes.WalletActions,
    element: <WalletActions />,
    index:   false,
    inline:  true,
  },
  {
    name:    Routes.Export,
    path:    Routes.Export,
    element: <ExportPage />,
    index:   false,
    inline:  true,
  },
  {
    name:    Routes.NewPairs,
    path:    Routes.NewPairs,
    element: <NewPairsPage />,
    index:   false,
    inline:  true,
  },
];

export { appPublicRoutes };
