import { FC, ReactElement, ReactNode } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { Box, Checkbox, Grid, menuClasses, MenuItem } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyleFormHelperWrapper,
} from './select-field.styles';

import chevronIcon from '@assets/images/chevron-down.svg';

import { SelectFieldProps } from './select-field.types';

const MotionHelperWrapper = motion(StyleFormHelperWrapper);

/**
 * TextField component.
 *
 * @author Ihar Kazlouski
 * @function TextField
 * @category components
 * @return {JSX.Element} textfield component.
 */
const SelectField: FC<SelectFieldProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  inputRef,
  focused,
  labelProps,
  containerProps,
  fieldProps,
  options = [],
  multiple,
  value,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-select`;

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };
  const selectValue = value as string | string[];

  return (
    <StyledFormControl
      error={isError}
      fullWidth={fullWidth}
      focused={focused}
      disabled={restProps.disabled}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        {label && (
          <StyledInputLabel htmlFor={literalProps.htmlFor}>
            {label}
          </StyledInputLabel>
        )}
        <Grid item flex=' 1' {...fieldProps}>
          <Box position='relative'>
            <StyledSelect
              error={isError}
              ref={inputRef}
              id={literalProps.id}
              aria-describedby={inputName}
              name={name}
              fullWidth={fullWidth}
              color={color}
              value={selectValue}
              multiple={multiple}
              variant='filled'
              disableUnderline
              IconComponent={({ ...props }): React.ReactElement => (
                <Icon {...props} src={chevronIcon} width={18} height={18} />
              )}
              renderValue={(selected): ReactNode => {
                const select = selected as typeof selectValue;
                if (typeof select === 'string') {
                  return (
                    options.find((option) => option.value === select)?.label ??
                    select
                  );
                }
                const labelArray = select
                  .map((s) => {
                    const label = options.find((o) => o.value === s)?.label;
                    return label;
                  })
                  .sort((a, b) => (a && b ? (a > b ? 1 : a < b ? -1 : 0) : 0));

                return <Box whiteSpace='normal'>{labelArray.join(', ')}</Box>;
              }}
              MenuProps={{
                PaperProps: {
                  sx: (theme) => ({
                    color:           theme.palette.white.main,
                    background:      theme.palette.mirage.main,
                    border:          `1px solid ${theme.palette.white10.main}`,
                    borderRadius:    theme.spacing(1.5),
                    margin:          theme.spacing(0.5, 0, 0.5),
                    msOverflowStyle: 'none',
                    scrollbarWidth:  'none',
                    padding:         theme.spacing(0.5),

                    [`&::-webkit-scrollbar`]: {
                      display: 'none',
                    },

                    [`& .${menuClasses.list}`]: {
                      padding: 0,
                    },
                  }),
                },
              }}
              {...restProps}
            >
              <MenuItem key='none' value='' hidden></MenuItem>
              {options.map(
                (option): ReactElement => (
                  <StyledMenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    hidden={option.hidden}
                  >
                    {multiple && typeof selectValue !== 'string' && (
                      <Checkbox checked={selectValue.includes(option.value)} />
                    )}
                    {option.label}
                  </StyledMenuItem>
                ),
              )}
            </StyledSelect>
            <AnimatePresence>
              {isError && (
                <MotionHelperWrapper
                  variants={{
                    hide: { opacity: 0, height: '0rem', marginTop: '0rem' },
                    show: { opacity: 1, height: 'auto', marginTop: '0.25rem' },
                  }}
                  initial='hide'
                  animate='show'
                  exit='hide'
                >
                  <StyledFormHelperErrorText id={literalProps.errorId}>
                    {error}
                  </StyledFormHelperErrorText>
                </MotionHelperWrapper>
              )}
            </AnimatePresence>
            {bottomLabel && !isError ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { SelectField };
