import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { pxToRem } from '@themes';

export const StyledTable = styled(Table)(() => {
  return {};
});

export const StyledTableHead = styled(TableHead)(({ theme }) => {
  return {
    [StyledTableCell]: {
      color: theme.palette.white50.main,
    },
  };
});

export const StyledTableBody = styled(TableBody)(() => {
  return {};
});

export const StyledTableRow = styled(TableRow)(() => {
  return {};
});

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    fontSize:    pxToRem(12),
    lineHeight:  pxToRem(17),
    borderColor: theme.palette.white10.main,
    color:       theme.palette.white.main,
    padding:     theme.spacing(1, 3),
  };
});
